import { Component, OnInit, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { GPurchaseRequisitionViewModel } from 'src/app/viewmodels/g-purchase/GPurchaseRequisitionViewModel';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { GItemDefiningViewModel } from 'src/app/viewmodels/g-item/GItemDefiningViewModel';
import { ItemViewModel } from 'src/app/viewmodels/ItemViewModel';
import { G_PurchaseMasterViewModel } from 'src/app/viewmodels/g-purchase/G_PurchaseMasterViewModel';
import { DefineAccountviewModel } from 'src/app/viewmodels/g-customer/DefineAccountviewModel';
import { PurchaseDetailViewModel } from 'src/app/viewmodels/g-purchase/PurchaseDetailViewModel';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-purchase-requsition-form',
  templateUrl: './purchase-requsition-form.component.html',
  styleUrls: ['./purchase-requsition-form.component.scss']
})
export class PurchaseRequsitionFormComponent implements OnInit {

  @Input() public vm: G_PurchaseMasterViewModel;
  @Input() public modal_title: any;
  @Input() public bsmRef: BsModalRef;
  @Output() onSubmitted = new EventEmitter<any>();
  modalRef: BsModalRef;
  public vmD: DefineAccountviewModel = new DefineAccountviewModel()
  public component_Title = 'Purchase Requisition';
  public isPreview: boolean = false;
  public thumbnail: any;
  public ComName: any;
  public Address: any;
  public contect: any;

  public modalOptions = {
    keyboard: false,
    backdrop: true,
    ignoreBackdropClick: true
  };
  private submitted = false;

  public defaultDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'Id',
    textField: 'Name',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
  public accountDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'Id',
    textField: 'Acc_Titel',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 4,
    allowSearchFilter: true
  };
  // public wareHouseDropdownSettings: IDropdownSettings = {
  //   singleSelection: true,
  //   idField: 'Id',
  //   textField: 'Acc_Titel',
  //   enableCheckAll: false,
  //   selectAllText: 'Select All',
  //   unSelectAllText: 'UnSelect All',
  //   itemsShowLimit: 4,
  //   allowSearchFilter: true
  // };
  public itemsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'Id',
    textField: 'FK_G_Item_Id.Name',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };

  public sparePartVm: GItemDefiningViewModel = new GItemDefiningViewModel();
  public citemtype = 'itemtype';
  public itemTypes: any = [];

  public suppliers: any = [];
  public csuppliers = 'suppliers';
  public selectedSuppliers: any = [];
  public selectedSupplierEmail: any;

  public items: any = [];
  public citems = 'items';
  public selectedItems: any = [];

  public spareParts: any = [];
  public selectedSpareParts: any = [];
  public wareHouses:Array<any> =[];
  public selectspp : any;
  public selected:boolean = false;
  constructor(
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    private spinner: NgxSpinnerService
  ) { }

  name = 'CP_Email';
  id = 'Id';
  checked = true;
  disabled = false;
  label = 'Toggle On/Off';
  labelledby = 'Some Other Text';

  ngOnInit() {
    this.getimage();
    if (this.vm == undefined) {
      this.vm = new G_PurchaseMasterViewModel();
      this.vm.PurchaseDetail = [];
      this.vm.TransactionDate = new Date();
      // this.vm.RequiredDate = new Date();
      this.vm.PurchaseDetail = [];
      this.spareParts = [];
      this.vm.FK_Sup_Cradit_Acc_Email = new ItemViewModel(0,"");
      this._uow.purchaseRequisitionService.ValidatePurchsaeRefId().subscribe((res: any) => {
        console.log(res);
        this.vm.RefId = res.code;
      }, (error) => {
        console.log(error);
      });
    } else {
      let selectedSuplier : any = [];
      let selectedSaprePartsList:any = [];
      if (this.vm.FK_Sup_Cradit_Acc != undefined && this.vm.FK_Sup_Cradit_Acc != null) {
        let sCA = new DefineAccountviewModel();
      sCA.Id = this.vm.FK_Sup_Cradit_Acc.Id;
      sCA.Acc_Titel = this.vm.FK_Sup_Cradit_Acc.Name;
      selectedSuplier.push(sCA);
      this.selectedSuppliers = selectedSuplier;
      this.vm.FK_Sup_Cradit_Acc = new ItemViewModel(this.vm.FK_Sup_Cradit_Acc.Id,this.vm.FK_Sup_Cradit_Acc.Name);
      }
      console.log('purchase detail',this.vm.PurchaseDetail);
      this.vm.PurchaseDetail.forEach(x=> {
        let partssSelected = new GItemDefiningViewModel();
        partssSelected.Id = x.FK_G_Item_Id.Id;
        partssSelected.Name = x.FK_G_Item_Id.Name;
        selectedSaprePartsList.push(partssSelected);
  });
  this.selectedSpareParts = selectedSaprePartsList;
    }
    if (this.modal_title == undefined) {
      this.modal_title = 'Purchase Requsition';
    }

    this.sparePartVm = new GItemDefiningViewModel();
    this.sparePartVm.FK_G_ItemType_Id = new ItemViewModel(0, ' ');

    // this.getSpareParts();
    this.getItemTypes();
    this.getSuppliers();
    this.getWareHouses();
  }

  onChange(e) {
    debugger
    let item = this.selectspp;
    if(e== true){
      if (this.selectedSuppliers.length > 0 && this.suppliers != undefined) {
        let findSelected  = this.suppliers.find(x=> x.Id == this.selectedSuppliers[0].Id);
        let i = new G_PurchaseMasterViewModel();
        this.vm.FK_Sup_Cradit_Acc_Email = new ItemViewModel(findSelected.Id , findSelected.CP_Email);
        this.vm.IsEmailRequired = true;
      }
    }else{
      this.vm.FK_Sup_Cradit_Acc_Email = null;
      this.vm.IsEmailRequired = false;
    }
}

  getItemTypes() {
    this._uow.itemTypeService.GetList().subscribe((res: any) => {
      this.itemTypes = res;
      console.log(res);
    }, (err) => this._uow.logger.log(err));
  }

  getSupCashList() {
    this.selectedSuppliers = [];
    this.getSuppliers();
  }

  getSuppliers() {
    this._uow.supplierService.GetList().subscribe((res: any) => {
      this.suppliers = res.DtlList;
      this._uow.logger.log(this.suppliers);
    }, (err) => this._uow.logger.log(err));
  }
getWareHouses(){
  this._uow.warehouseService.GetListByUserId().subscribe((res:any)=>{
    console.log('warehouse',res);
    this.wareHouses = res;
  });
}
onSelectWareHouse(){
  console.log('changes');
  this.selected = true;
  this.getSpareParts(this.vm.FK_WareHouse_Id.Id);
}
  getimage()
  {
    debugger;
    this._uow.settingservice.GetByImage().subscribe((baseImage: any) =>{
      console.log(baseImage);
      if (baseImage != undefined &&  baseImage!==null) {
        if (baseImage.Attachment != undefined &&  baseImage.Attachment !==null) {

          this.thumbnail = `https://garagedesks.s3.eu-central-1.amazonaws.com/${baseImage.Attachment}`;
          this.ComName = baseImage.ComName;
          this.Address = baseImage.Address;
          this.contect = baseImage.contect;
        }else{
          this.thumbnail = baseImage.ComName;
          this.ComName = baseImage.ComName;
          this.Address = baseImage.Address;
          this.contect = baseImage.contect;
        }
      }

    },(err)=>{
      console.log(err);
    })
  }

  getSparePartsForVendor(e) {
  
    let id = null;
    let selectedSparePartsss : any = []
    if (this.selectedSuppliers.length > 0) {
      id = this.selectedSuppliers[0].Id;
    }
    let idd = this.vm.FK_WareHouse_Id.Id;
    console.log()
    // this.selectedSuppliers.length = 0;
    this._uow.itemService.GetG_SSItemDefiningBySupplier(id,idd).subscribe((res: any) => {
      console.log('fetched list',res);
      res.forEach(item => {
        // let item = this.spareParts.find(x => x.Id == el.Id);
        // console.log(item);
        if (item !== undefined) {
          let i = new PurchaseDetailViewModel();
          i.Quantity = 1;
          i.FK_G_Item_Id = new ItemViewModel(item.Id, item.Name);
          i.MaxQuantity = item.MaxStock;
          i.MaxStock = item.MaxStock;
          i.MinStock =item.MinStock;
          i.CurrentStock = item.CurrentStock;
          i.Quantity = item.MaxStock - item.CurrentStock;
          i.Item_Code = item.Code;
          i.Item_Name = item.Name;
          i.PurchasePrice = item.PurchasePrice;
          i.SalePrice = item.SalePrice;
          i.Item_Type = item.FK_G_ItemType_Id.Name;
          this.vm.PurchaseDetail.push(i);

          let fsp = this.selectedSpareParts.find(x => x.Id == item.Id);
          if (fsp == undefined) {
              selectedSparePartsss.push(new ItemViewModel(item.Id, item.Name));
          }
          this.selectedSpareParts = selectedSparePartsss;
        }
      });
      console.log(res);
    }, (error) => {
      console.log(error);
    });
  }

  onSelectSupplier(e) {
    debugger
    let item = this.suppliers.find(x => x.Id === e.Id);

   console.log(item);
   if (item !== undefined) {
     let i = new G_PurchaseMasterViewModel();
     this.vm.FK_Sup_Cradit_Acc = new ItemViewModel(item.Id , item.Acc_Titel);
     this.selectspp = item;
     //this.vm.FK_Sup_Cradit_Acc.Id = item.Id;
     //this.vm.FK_Sup_Cradit_Acc.Name = item.Acc_Titel;
     }
 }

  getSpareParts(id) {
    //'Spare-Parts'
    this._uow.wareHouseItemService.GetListItemByWare(id).subscribe((res: any) => {
      this.spareParts = res;
      console.log('list',res)

    }, (err) => {
      this._uow.logger.log(err);
      this.spareParts = [];
    });
  }

  // spare parts
  onSelectSparePart(e) {
   
    console.log(e);
    let item = this.spareParts.find(x => x.Id === e.Id);
    console.log(item);
    if (item !== undefined) {
      let i = new PurchaseDetailViewModel();

      i.Quantity = 1;
      i.FK_G_Item_Id = new ItemViewModel(item.Id, item.Name);
      i.MaxQuantity = item.MaxStock;
      i.MaxStock = item.MaxStock;
      i.MinStock = item.MinStock;
      i.CurrentStock = item.CurrentStock;
      i.AvalibelQuantity = item.CurrentStock;
      i.Quantity = item.MaxStock - item.CurrentStock;
      if(item.CurrentStock>item.MaxStock){
        i.Quantity = 0;
      }
      i.Item_Code = item.Code;
      i.Item_Name = item.Name;
      i.PurchasePrice = item.PurchasePrice;
      i.SalePrice = item.SalePrice;
      i.Item_Type = item.FK_G_ItemType_Id.Name;

      this.vm.PurchaseDetail.push(i);
      let fsp = this.selectedSpareParts.find(x => x.Id == item.Id);
          if (fsp == undefined) {
            this.selectedSpareParts.push(new ItemViewModel(item.Id, item.Name));
          }
    }

    console.log(this.selectedSpareParts);
  }

  onDeSelectSparePart(e) {
    debugger
    let i = this.vm.PurchaseDetail.find(x => x.FK_G_Item_Id.Id === e.Id);

    if (i !== undefined) {
      this.vm.PurchaseDetail.splice(this.vm.PurchaseDetail.indexOf(i), 1);
    }

  }

  onRemoveFromDetailList(e) {
    console.log(e);
    let i = this.vm.PurchaseDetail.find(x => x.FK_G_Item_Id.Id === e.FK_G_Item_Id.Id);
    if (i !== undefined) {
      this.vm.PurchaseDetail.splice(this.vm.PurchaseDetail.indexOf(i), 1);
    }
    let s = this.selectedSpareParts.find(x => x.Id === e.Id);
    if (s !== undefined) {
      this.selectedSpareParts.splice(this.selectedSpareParts.indexOf(s),1);
    }
  }

  openModalLg(template: TemplateRef<any>, itemtype?) {
    this.sparePartVm = new GItemDefiningViewModel();
    if (itemtype == 1) {
      let i = this.itemTypes.find(x => x.Name == 'Spare-Parts');
      console.log(i)
      this.sparePartVm.FK_G_ItemType_Id = new ItemViewModel(i.Id, i.Name);
    }
    if (itemtype == 2) {
      let i = this.itemTypes.find(x => x.Name == 'Services');
      this.sparePartVm.FK_G_ItemType_Id = new ItemViewModel(i.Id, i.Name);
    }
    this.openModal(template);
    this.modalRef.setClass('modal-lg');
  }

  // exteral coponents
  openModal(template: TemplateRef<any>, lvl: number = 1) {
    if (lvl == 1) {
      this.modalRef = this._uow.modalServiceEx.show(template);//this._modalService.show(template, this.modalOptions);
    }
    // if (lvl == 2) {
    //   this.modalRefLv2 = this._modalService.show(template);
    // }
  }
  compareFn(optionOne, optionTwo): boolean {
    if (optionOne !== undefined && optionTwo !== undefined) {
      return optionOne.Id === optionTwo.Id;
    }
  }

  public itemType = new ItemViewModel();
  openSaveOrUpdateModel(template: TemplateRef<any>, model?) {

    if (model !== undefined) {
      this.sparePartVm = model;
    } else {
      this.sparePartVm = undefined;
    }
    this.openModal(template, 1);
    this.modalRef.setClass('modal-xl');
  }
  openSaveOrUpdateModelForItemType(template: TemplateRef<any>, model?, itemType?) {
    this.itemType = this.itemTypes[itemType];
    this.sparePartVm.FK_G_ItemType_Id = new ItemViewModel(this.itemType.Id, this.itemType.Name);
    // this.sparePartVm.FK_G_ItemType_Id = new ItemViewModel(this.itemType.Id, this.itemType.Name);
    console.log(this.itemTypes);
    this.openSaveOrUpdateModel(template, model);
  }

  saveOrUpdate(event:any) {
    event.preventDefault();
    debugger
    if (this.selectedSuppliers.length > 0) {
      this.vm.FK_Sup_Cradit_Acc = new ItemViewModel(this.selectedSuppliers[0].Id , this.selectedSuppliers[0].Acc_Titel) ;
    }
    this.onChange(true);
    // this._jq.showSpinner(true);
    this.spinner.show();
    this._uow.purchaseRequisitionService
      .Post(this.vm)
      .toPromise().then((res: any) => {
        console.log(res);
        this.submitted = true;
        this.bsmRef.hide();
        this.vm.IsEmailRequired=true;
        // this._uow.dataService.setValue(true);
        this.modalRef.hide();
        this.onSubmitted.emit(this.submitted);
        this.spinner.hide();
        Swal.fire(
          'Created!',
          'Purchase Created/Updated!',
          'success'
        );
      })
      .catch((error) => {
        this.spinner.hide();
        console.log(error);
        // Swal.fire(
        //   'Failure!',
        //   'Purchase Not Updated!',
        //   'error'
        // )
        // console.log(`Promise rejected with ${JSON.stringify(error)}`);
        // this.onSubmitted.emit(this.submitted);
        
        // this._jq.showSpinner();
      });
  }

  navigateClick(){
    this.isPreview = false;
    this.modalRef.hide()
  }

  // external components
  onItemSubmitted(e, mf) {
    this._uow.logger.log(e);
    if (e === true) {

    }
  }
  openPreview(content) {
    debugger
    this.modalRef = this._uow.modalServiceEx.show(content);
    this.modalRef.setClass('modal-lg');
    this.isPreview = true;
  }
  getwareHouseItemList(id) {
    
    this._uow.wareHouseItemService.GetListItem(id).subscribe((res: Array<any>) => {
    
      // res.forEach(element =>{
      //   element.FK_WareHouse_Id = new ItemViewModel(this.FK_WareHouse_Id.Id,this.FK_WareHouse_Id.Name);
      // });
      this.vm.PurchaseDetail = res;
      console.log('list item',res);
    
    }, (err) => {
      console.log(err);
      // this.spinner.hide();
    });
  }
}
