export enum E_G_EPSStatusType {
    Purchase_Requisition = 2,
    Purchase_Order = 3,
    Purchase_Slip = 4,
    SaleQout = 5,
    SaleWIP = 6,
        SaleReady = 7,
        SaleInvoice= 8,
        SaleCompleted = 9
}
