import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { GSalesReportByDate } from 'src/app/viewmodels/g-settings/GSalesReportByDate';
import { JqHelper } from "src/app/common/jq-helper";
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-sales-reports-by-date',
  templateUrl: './sales-reports-by-date.component.html',
  styleUrls: ['./sales-reports-by-date.component.scss']
})
export class SalesReportsByDateComponent implements OnInit {
  public component_Title = 'Sales Report';
  public customerDetail: any = [];
  public selectedcustomerDetail: any = [];
  public SalesReportByDate = new GSalesReportByDate();

  public CustomerDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: "Id",
    textField: "Acc_Titel",
    enableCheckAll: false,
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 3,
    allowSearchFilter: true,
    limitSelection: 1,
  };

  constructor(  private _jq: JqHelper, private _uow: UnitOfWorkService, private spinner : NgxSpinnerService,
  ) {
    // this.SalesReportByDate.StartDate = new Date();
    // this.SalesReportByDate.EndDate = new Date();
   }

  ngOnInit() {
    this.getCustomerName();
  }

  downloadFile(fileUrl: string) {
    // Create a temporary anchor element
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = 'SalesReport.xlsx'; // Specify the file name

    // Append the anchor to the body
    document.body.appendChild(link);

    // Trigger the download by simulating a click
    link.click();

    // Remove the anchor from the body
    document.body.removeChild(link);
  }

  getCustomers() {
  
    let Customer = "both";
    // if (this.vm.PaymentType === 2) {
    //   Customer = "Cash";
    // }
    // if (this.vm.PaymentType === 3) {
    //   Customer = "Office";
    // }
  
    console.log(this.selectedcustomerDetail);
    this._uow.customerService.GetListSuplier(Customer).subscribe(
      (res: any) => {

        this.customerDetail = res.DtlList;
        if(this.customerDetail.length > 0){
          // // debugger;
          this.customerDetail.forEach(x => {
            x.Acc_Titel = x.Acc_Code + '-' + x.Acc_Titel;
          });
        }
        

      },
      (error) => {
        this._uow.logger.log(error);
        this.customerDetail = [];
      }
    );
  }

  getCustomerName() {
    this._uow.customerService.GetList().subscribe(
      (res: any) => {
        debugger
        this.customerDetail = res.DtlList;
        console.log(this.customerDetail)
       
        if(this.customerDetail.length > 0){
          // // debugger;
          this.customerDetail.forEach(x => {
            x.Acc_Titel = x.Acc_Code + '-' + x.Acc_Titel;
          });
        }
        // this.itemVm.RegistraionNumber = res;
        this._jq.showSpinner();
        // this.itemVm.RegistraionNumber = res.code;
      },
      (error) => {
        this._uow.logger.log(error);
        this._jq.showSpinner();
      }
    );
  }

  getDates(){
    if(this.SalesReportByDate.StartDate > this.SalesReportByDate.EndDate){
      console.log("date error");
    }else{
      this.spinner.show();
      this._uow.salesreportservice.GetSalesReport(this.SalesReportByDate).subscribe((res: any) => {
        this.spinner.hide();
        console.log("fetch list", res);
      if (res) {
        window.open(res.Name,'_blank');
      }
      }, err => {
        this.spinner.hide();
      })
    }
  }

}
