import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { G_TagsViewModel } from 'src/app/viewmodels/g-settings/G_TagsViewModel';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';

@Component({
  selector: 'app-image-upload-form',
  templateUrl: './image-upload-form.component.html',
  styleUrls: ['./image-upload-form.component.scss']
})
export class ImageUploadFormComponent implements OnInit {

  @Input() public vm: G_TagsViewModel;
  @Input() public modal_title: any;
  @Input() public bsmRef: BsModalRef;
  @Output() onSubmitted = new EventEmitter<any>();
  private submitted = false;

  public tagTypes: any = [];
  modalRef: BsModalRef;

  constructor(
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
  ) { }

  ngOnInit() {
    if (this.vm == undefined) {
      this.vm = new G_TagsViewModel();
    }
    if (this.modal_title == undefined) {
      this.modal_title = 'Image Upload';
    }
    console.log(this.vm)
    this.getTagTypes();
  }
  openModal(template: TemplateRef<any>, lvl: number = 1) {
    if (lvl == 1) {
      this.modalRef = this._modalService.show(template);
    }
  }
  getTagTypes() {
    this._uow.tagsTypeService.GetList().subscribe((res) => { this.tagTypes = res; }, (err) => console.log(err));
  }
  compareFn(optionOne, optionTwo): boolean {
    if (optionOne !== undefined && optionTwo !== undefined) {
      return optionOne.Id === optionTwo.Id;
    }
  }
  openTagTypeModel(template: TemplateRef<any>) {
    this.openModal(template);
  }

  saveOrUpdate() {
    this._jq.showSpinner(true);
    this._uow.tagsService
      .Post(this.vm)
      .toPromise().then((res: any) => {
        console.log(res);
        this.submitted = true;
        this.bsmRef.hide();
        this.onSubmitted.emit(this.submitted);
      })
      .catch((error) => {
        console.log(`Promise rejected with ${JSON.stringify(error)}`);
        this.onSubmitted.emit(this.submitted);
      });
  }

  onTagTypeSubmitted(e) {
    if (e == true) {
      this.modalRef.hide();
      this.getTagTypes();
    }
  }
}