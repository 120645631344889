import { ItemViewModel } from '../ItemViewModel';

export class G_Installment_View_Model {

        public Id : number;
        public Amount : number;
        public InvoiceNumber : number;
        public InvoiceAmount : number;
        public RemainingAmount : number;
        public PaidAmount : number;
        public G_FK_Purchase_Id : ItemViewModel;
        public G_FK_Sale_Id : ItemViewModel;
        public G_Fk_CreditAccount_Id : ItemViewModel;
        public G_Fk_DabitAccount_Id : ItemViewModel;
        public FK_G_BulkPayment_Id : ItemViewModel;
        public FK_Client_Id : ItemViewModel;
        public IsPurchase : boolean;
        public IsBulkPayments : boolean;
        public CreatedDate: Date;
        public TransactionDate : Date;
        public Remarks : string;
        public file: any;
        public Attachement: string;
        public IsDisable: boolean = false;
}
