import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { DefineAccountviewModel } from 'src/app/viewmodels/g-customer/DefineAccountviewModel';
import { GPackagesViewModel } from 'src/app/viewmodels/g-packages/GPackagesViewModel';
import { G_SaleMasterViewModel } from 'src/app/viewmodels/g-sale/G_SaleMasterViewModel';
import { GVechileViewModel } from 'src/app/viewmodels/g-vehicle/GVechileViewModel';
import { GItemDefiningViewModel } from 'src/app/viewmodels/g-item/GItemDefiningViewModel';
import { ActivatedRoute, Router } from '@angular/router';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { GPackageItemViewModel } from 'src/app/viewmodels/g-packages/GPackageItemViewModel';
import { ItemViewModel } from 'src/app/viewmodels/ItemViewModel';
import { G_TagsViewModel } from 'src/app/viewmodels/g-sale/G_TagsViewModel';
import { G_Installment_View_Model } from 'src/app/viewmodels/g-purchase/G_Installment_View_Model';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-payment-status-detail-view',
  templateUrl: './payment-status-detail-view.component.html',
  styleUrls: ['./payment-status-detail-view.component.scss']
})
export class PaymentStatusDetailViewComponent implements OnInit {
  modalRefLv1: BsModalRef;
  modalRefLv2: BsModalRef;
  modalRefLv3: BsModalRef;
  modalRef: BsModalRef;
  public vmListItems: any = [];
  @Input() public modal_title: any;
  @Input() public vmP: DefineAccountviewModel;
  @Input() public vmp: GPackagesViewModel;
  public vm: G_SaleMasterViewModel = new G_SaleMasterViewModel();
  public vmI: G_Installment_View_Model = new G_Installment_View_Model();
  public vmDetail: number = 0;
  private submitted = false;
  public component_Title = 'Payment Status Detail';
  public customerDetail: any = [];
  public selectedcustomerDetail: any = [];
  public ccustomerDetail = 'customerDetail';
  public address : any;
  public files: File[] = [];
  public vehicleDetail: any = [];
  public selectedTagsList: any = [];
  public cvehicleDetail = 'vehicleDetail';
  public selectedvehicleDetail: any = [];
  public itemVm: GVechileViewModel = new GVechileViewModel();
  bsmRef: any;
  @Output() onSubmitted = new EventEmitter<any>();
  public tags: any = [];
  public selectedTagList: any = [];
  public spareParts: any = [];
  public selectedSpareParts: any = [];

  public itemTypes = [];
  public ssItemVm = new GItemDefiningViewModel();
  public SelectedItemServices: any = [];
  public citems: any = 'items';
  public selectedDate: any;
  public DisableSave: boolean = false;
  public EnableNext: boolean = false;
  public calingUrl: string;
  public urlId: number;

  constructor(
    private route: ActivatedRoute,
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    public _router: Router,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    let selectedSaprePartsList : any = [];
    let selectedTagLists: any = [];
    let selectedVehicle: any = [];
    let selectedCustomer: any = [];
    let date = new Date();
    document.getElementById("date").innerHTML = date.toLocaleDateString();
    this.calingUrl = this._router.url;

    this.route.queryParams.subscribe(params => {
      this.urlId = params['id'];


    });
    // this._jq.showSpinner(true);
    this.spinner.show();
    debugger
    this._uow.saleService.GetById(this.urlId).subscribe((res: any)=>{
      this.vm = res;
      console.log(res);
      this.spinner.hide();
      debugger
      let veh = new GVechileViewModel();
      veh.Id = this.vm.Fk_G_Vechile_Id.Id;
      veh.Name = this.vm.Fk_G_Vechile_Id.Name;
      selectedVehicle.push(veh);
      this.selectedvehicleDetail = selectedVehicle;

      let cust = new DefineAccountviewModel();
      cust.Id = this.vm.Fk_G_Customer_Id.Id;
      cust.Acc_Titel = this.vm.Fk_G_Customer_Id.Name;
      selectedCustomer.push(cust);
      this.selectedcustomerDetail = selectedCustomer;

      this.vm.SpareParts.forEach(x => {

        let ab =  new GPackageItemViewModel();
          ab.Quantity = x.Quantity;
          ab.Fk_G_SSItemDefining_Id = new ItemViewModel(x.FK_G_Item_Id.Id, x.FK_G_Item_Id.Name);
          ab.DescountPercentage = x.Discount;
          ab.SalePrice = 0;
          ab.SalePrice = x.SalePrice;
          ab.Amount = 0;
          ab.Amount = x.TotalAmount;
          ab.DiscountAmount =  0;
          ab.DiscountAmount = x.DiscountAmount;
          this.vmp.SpareParts.push(ab);
          let partssSelected = new GItemDefiningViewModel();
          partssSelected.Id = x.FK_G_Item_Id.Id;
          partssSelected.Name = x.FK_G_Item_Id.Name;
          selectedSaprePartsList.push(partssSelected);
          //this.InventoryCheck(x.FK_G_Item_Id.Id);
          this.discountChange(ab, 0);
        });
      this.selectedSpareParts = selectedSaprePartsList;
      this.vm.SalesTagsList.forEach(x =>{
        let ab = new G_TagsViewModel();
        ab.Id = x.Id;
        ab.FK_Tag_Id = x.FK_Tag_Id;
        ab.Name = x.Name;
        let selectedTag = new G_TagsViewModel();
        selectedTag.Id = x.Id;
        selectedTag.FK_Tag_Id = x.FK_Tag_Id;
        selectedTag.Name = x.Name;
        selectedTagLists.push(selectedTag);
      });
      this.selectedTagsList = selectedTagLists;
    });

    if (this.itemVm.FK_Tag_List !== undefined && this.itemVm.FK_Tag_List.length > 0) {
      this.itemVm.FK_Tag_List.forEach(x => {
        this.selectedTagList.push(new ItemViewModel(x.Id, x.Name));
      });
    }
    if (this.itemVm == undefined) {
      this.itemVm = new GVechileViewModel();
      this.vehicleDetail = [];
      this.selectedvehicleDetail = [];
    } else {

    }
    if (this.vmP == undefined) {
      this.vmP = new DefineAccountviewModel();
      this.customerDetail = [];
      this.selectedcustomerDetail = [];
    } else {

    }
    if (this.modal_title == undefined) {
      this.modal_title = 'Sale';
    }
    if (this.vmp == undefined) {
      this.vmp = new GPackagesViewModel();
      this.vm = new G_SaleMasterViewModel();
      this.vmp.SpareParts = [];
      this.vm.PaymentType = 1;
      this.vm.Fk_G_StatusType_Id = new ItemViewModel(8,"SaleInvoice");
    } else {
      if(this.vm.SalesTagsList !== undefined && this.vm.SalesTagsList.length > 0){
        this.vm.SalesTagsList.forEach(x => {
          this.selectedTagsList.push(new ItemViewModel(x.Id, x.Name));
        });
      }

      if (this.vmp.SpareParts !== undefined && this.vmp.SpareParts.length > 0) {
        this.vmp.SpareParts.forEach(x => {
          this.selectedSpareParts.push(new ItemViewModel(x.Fk_G_SSItemDefining_Id.Id, x.Fk_G_SSItemDefining_Id.Name));
        });
      }
    }

    this.getRegNo();
    this.getCustomers();
    this.getRequisitions();
    this.getTags();
    this.getSpareParts();

  }

  getRegNo() {
    this._jq.showSpinner(true);
    this._uow.vehicleService.GetList().subscribe((res: any) => {
      this.vehicleDetail = res;
      // this.itemVm.RegistraionNumber = res;
      console.log(res);
      this._jq.showSpinner();
      // this.itemVm.RegistraionNumber = res.code;
    }, (error) => {
      this._uow.logger.log(error);
      this._jq.showSpinner();
    });
  }

  getCustomers() {
    let Customer = "Customer";
    if (this.vm.PaymentType === 2) {
      Customer = "Cash";
    }
    console.log(this.selectedcustomerDetail);
    this._jq.showSpinner(true);
    this._uow.customerService.GetListSuplier(Customer).subscribe((res: any) => {
      // console.log(res);
      this._jq.showSpinner();
      this.customerDetail = res.DtlList;
    }, (error) => {
      this._uow.logger.log(error);
      this.customerDetail = [];
      this._jq.showSpinner();
    });
  }

  getRequisitions() {
     this._uow.vehicleService.GetList().subscribe((res: any) => {
       this.vehicleDetail = res;
     }, (error) => {
       this._uow.logger.log(error);
       this.vehicleDetail = [];
     });
   }

   getTags() {
    this._uow.tagsService.GetList().subscribe((res: any) => { this.tags = res; }, (err) => console.log(err));
  }

  getSpareParts() {
    this._uow.itemService.GetSSItemDefiningByItemType('Spare-Parts').subscribe((res: any) => {
      this.spareParts = res;
      console.log(res);

    }, (err) => {
      this._uow.logger.log(err);
      this.spareParts = [];
    });
  }

  discountChange(e, i?) {
    debugger
    let discountAMmount = ((e.DescountPercentage * e.SalePrice)) / 100 ;
    e.DescountAmmount = discountAMmount;
    e.DiscountAmount = e.SalePrice-e.DescountAmmount;
    e.Amount = e.DiscountAmount * e.Quantity;
    let saledtl = this.vm.SaleDetails.find(x => x.FK_G_Item_Id.Id === e.Fk_G_SSItemDefining_Id.Id);
   if (saledtl != undefined) {
    saledtl.DiscountAmount = e.DescountAmmount;
    saledtl.Discount = e.DescountPercentage;
    saledtl.DiscountedRate = e.DiscountAmount;
    saledtl.TotalAmount = e.Amount;
   }
    this.vatChanged();
  }

  vatChanged() {
    if (this.vmDetail > -1) {
      if (this.vm.SaleDetails !== undefined && this.vm.SaleDetails.length > 0) {
        this.vm.SaleDetails.forEach(x => {
          x.VAT_Value = this.vmDetail;
          x.VatAmount = ((x.TotalAmount / 100) * this.vmDetail);
        });
      }
      if (this.vmp.SpareParts !== undefined && this.vmp.SpareParts.length > 0) {
        this.vmp.SpareParts.forEach(x => {
          x.VatAmount = ((x.SalePrice / 100) * this.vmDetail);
          x.TotalAmount = x.SalePrice + x.VatAmount;
        });
      }
      this.calculateTotals();
    }
  }

    public DiscountTotal = 0;
    public partsAmount = 0;
    public serviceAmount = 0;
    public TotalPayable = 0;
    public TotalVat = 0;
    public TotalPayableAfterVat = 0;
    calculateTotals() {
      debugger
      this.TotalPayableAfterVat = 0;
      this.TotalVat = 0;
      this.DiscountTotal = 0;
      this.TotalPayable = 0;
      this.partsAmount = 0;
      this.serviceAmount = 0;
      this.vmp.ServiceAmount = 0;
      this.vmp.DiscountAmount = 0;
      this.vmp.PartsAmount = 0;
    if (this.vm.SaleDetails !== undefined && this.vm.SaleDetails.length > 0) {
      this.vm.SpareParts.forEach(x => {
        // this.vmp.PartsAmount += x.Amount;
        this.TotalVat += x.VatAmount;
        if (x.DiscountAmount >= 0) {
          // this.vmp.DiscountAmount += (x.DiscountAmount * x.Quantity);
          this.DiscountTotal += (x.DiscountAmount * x.Quantity);

        }
      });
      this.vm.Services.forEach(x => {
        // this.vmp.ServiceAmount += x.Amount;
        this.TotalVat += x.VatAmount;
        this.serviceAmount += x.TotalAmount;
        if (x.DiscountAmount >= 0) {
          this.DiscountTotal += (x.DiscountAmount * x.Quantity);
          // this.vmp.DiscountAmount += (x.DiscountAmount * x.Quantity);
        }
      });
    }
      this.vmp.PackageAmount = this.vmp.PartsAmount + this.TotalVat;
    }

    onItemSubmitted(e) {
      if (e == true) {
        this.getList();
        this.getTags();
        this._jq.showSpinner(false);
      }
    }

    getList() {
      this._jq.showSpinner(true);
      this._uow.vehicleService.GetList().subscribe((res: any) => {
        this.vmListItems = res.DtlList;
        console.log(res);
        this._jq.showSpinner();
      }, (err) => {
        console.log(err);
        this._jq.showSpinner();
      });
    }
    openSaleModal(template: TemplateRef<any>, lvl: number = 1) {
      if (lvl == 1) {
        this.modalRef = this._uow.modalServiceEx.show(template);
      }
      this.modalRef.setClass('modal-xl');
      // if (lvl == 2) {
      //   this.modalRefLv2 = this._modalService.show(template);
      // }
    }

    openSaveOrUpdateModel(template: TemplateRef<any>, model?) {
      this.openSaleModal(template, 1);
      if (model !== undefined) {
        this.vm = model;
      } else {
        this.vm = new G_SaleMasterViewModel();
      }
    }

}
