import { Injectable } from '@angular/core';
import { IHttpService } from '../../common/IHttpService';
import { GItemDefiningViewModel, GItemDefinintionAttachments } from 'src/app/viewmodels/g-item/GItemDefiningViewModel';
import { EndpointConfig } from '../../common/EndpointConfig';
import { HttpClient } from '@angular/common/http';
import { Paginator } from 'src/app/viewmodels/paginator';
import { StockAdjustmentViewModel } from 'src/app/viewmodels/g-item/StockAdjustmentViewModel';
import { ExportExcel } from 'src/app/viewmodels/ExportExcel';

@Injectable({
  providedIn: 'root'
})
export class ItemService implements IHttpService<GItemDefiningViewModel> {
  private _ep: EndpointConfig;
  constructor(private _client: HttpClient) {
    this._ep = new EndpointConfig('GSSItem');
  }

  ValidateItemQRCode() {
    return this._client.post(this._ep.getEndpointMethod('ValidateItemQRCode'), { Code: '-1' });
  }

  checkDublication(code) {
    return this._client.post(this._ep.getEndpointMethod('CheckG_ItemDublication'), { Code: code });
  }

  GetList() {
    return this._client.post(this._ep.getEndpointMethod('GetG_SSItemDefining'), new Paginator(0, 50, null, 0));
  }


  GetItemReport() {
    return this._client.post(this._ep.getEndpointMethod('GetG_ItemStockReport'), new Paginator(0, 50, null, 0));
  }
  GetWarehouseItemReport(id) {
    return this._client.post(this._ep.getEndpointMethod('GetG_ItemStockReportByWareHouse'), {Id:id});
  }
  GetStockItemReport() {
    return this._client.post(this._ep.getEndpointMethod('GetG_ItemStockAdjustement'), new Paginator(0, 50, null, 0));
  }

  GetStockByWarehouse(id: any) {
    return this._client.post(this._ep.getEndpointMethod('GetG_ItemStockAdjustementByWareHouse'), { Id: id });
  }

  GetStockById(id: any) {
    return this._client.post(this._ep.getEndpointMethod('GetG_StockAdjustementById'), { Id: id });
  }

  GetStockAdjustedItemReport() {
    return this._client.post(this._ep.getEndpointMethod('GetG_StockAdjustement'), new Paginator(0, 50, null, 0));
  }

  PostStockAdjustment(obj: StockAdjustmentViewModel) {
    return this._client.post(this._ep.getEndpointMethod('SaveG_StockAdjustement'), obj);
  }

  GetStockPagedResult(start = 0, pagesize = 20, flag = '', sortIndex = 0,SortBy) {
    return this._client.post(this._ep.getEndpointMethod('GetG_StockAdjustement'), new Paginator(start, pagesize, flag, sortIndex,SortBy));
  }

  GetStockPagedBySearch(searchTerm, flag?) {
    let pager = new Paginator(0, 50, flag, 0);
    pager.Search = searchTerm;
    return this._client.post(this._ep.getEndpointMethod('GetG_StockAdjustement'), pager);
  }


  GetPagedResult(start = 0, pagesize = 20, flag = '', sortIndex = 0,searchTerm?) {

    return this._client.post(this._ep.getEndpointMethod('GetG_SSItemDefining'), new Paginator(start, pagesize, flag, sortIndex,searchTerm));
  }
  GetPagedResultBySort(start = 0, pagesize = 20, flag = '', sortIndex, SortBy) {
    debugger
    let pager = new Paginator();
    pager.PageSize = pagesize;
    pager.Skip = start;
    pager.Flag = flag;
    pager.SortIndex = sortIndex;
    pager.SortBy = SortBy;
    return this._client.post(this._ep.getEndpointMethod('GetG_SSItemDefining'), pager);
  }
  GetPagedBySearch(searchTerm, flag?) {
    debugger;
    let pager = new Paginator();
    pager.Skip =0;
    pager.Flag =flag;
    pager.SortIndex =0;
    // pager.PageSize =20;
    pager.Search = searchTerm;
    return this._client.post(this._ep.getEndpointMethod('GetG_SSItemDefining'), pager);
  }

  GetSSItemDefiningByItemType(code,id?) {
    return this._client.post(this._ep.getEndpointMethod('GetG_SSItemDefiningByItemType'), { Code: code,Id:id });
  }

  GetSSItemDefiningByItemTypeByModel(modelid: any, Idd?: any) {
    debugger
    return this._client.post(this._ep.getEndpointMethod('GetG_SSItemDefiningByTypeAndModelId'), { Id: modelid , Idd : Idd});
  }


  GetG_SSItemDefiningBySupplier(id: number,idd) {
    return this._client.post(this._ep.getEndpointMethod('GetG_SSItemDefiningBySupplier'), { Id: id, Idd:idd });
  }
  GetById(id: any) {
    return this._client.post(this._ep.getEndpointMethod('GetG_SSItemDefiningById'), { Id: id });
  }
  Post(obj: GItemDefiningViewModel) {
    return this._client.post(this._ep.getEndpointMethod('SaveG_SSItemDefining'), obj);
  }

  Put(obj: GItemDefiningViewModel) {
    return this._client.post(this._ep.getEndpointMethod('SaveG_SSItemDefining'), obj);
  }

  Delete(obj: GItemDefiningViewModel) {
    return this._client.post(this._ep.getEndpointMethod('DeleteG_SSItemDefining'), obj);
  }
  GetCode() {
    return this._client.post(this._ep.getEndpointMethod('ValidateItemCode'), { Code: '-1' });
  }

/////////////////////////Inventory Stock/////////////////////////////
GetG_TransferInventoryListByWareHouse(raw){
  return this._client.post(this._ep.getEndpointMethod('GetG_TransferInventoryListByWareHouse'),raw);
}
SaveG_TransferInventory(model){
  return this._client.post(this._ep.getEndpointMethod('SaveG_TransferInventory'),model);
}
GetG_TransferInventoryList(){
  return this._client.post(this._ep.getEndpointMethod('GetG_TransferInventoryList'),{});
}
SaveG_SSItemDefiningByExcel(obj){
  const formData = new FormData();
  formData.append("file", obj.file);
  formData.append("Obj", JSON.stringify(obj));
  return this._client.post(this._ep.getEndpointMethod('SaveG_SSItemDefiningByExcel'),formData);
}
GetG_InventoryFlowReport(raw){
  return this._client.post(this._ep.getEndpointMethod('GetG_InventoryFlowReport'),{Id:raw.itemId,Idd:raw.wareHouseId});
}

ExportSSItem(obj: ExportExcel) {
  let fd = new FormData();
  fd.append('Obj',JSON.stringify(obj));
  return this._client.post(this._ep.getEndpointMethod('SSItem_Export'), fd, {responseType: 'blob'});
}

ExportStockReport(obj: ExportExcel) {
  let fd = new FormData();
  fd.append('Obj',JSON.stringify(obj));
  return this._client.post(this._ep.getEndpointMethod('ItemStockReport_Export'), fd, {responseType: 'blob'});
}

SaveG_SSItemImages(obj: GItemDefinintionAttachments) {
  console.log(obj);
  //obj.Fk_G_StatusType_Id = new ItemViewModel(E_G_EPSStatusType.SaleQout);

  let fd = new FormData();
  debugger
  if(obj.ItemFiles!=undefined){
    for (var i = 0; i < obj.ItemFiles.length; i++) {
    fd.append('ItemFiles', obj.ItemFiles[i]);
    }
  }
  if(obj.CategoryFile!=undefined){
    for (var i = 0; i < obj.CategoryFile.length; i++) {
    fd.append('CategoryFile', obj.CategoryFile[i]);
    }
  }
  fd.append('Obj',JSON.stringify(obj));
  return this._client.post(this._ep.getEndpointMethod('SaveG_SSItemDefining_Attachments'), fd);
}

GetG_SSItemImages(id: any) {
  return this._client.post(this._ep.getEndpointMethod('GetG_SSItemDefining_AttachmentsById'), { Id: id });
}
DeleteG_SSItemImages(id: any) {
  return this._client.post(this._ep.getEndpointMethod('DeleteG_SSItemDefining_AttachmentsById'), { Id: id });
}
}
