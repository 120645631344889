import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { RouteConsts } from 'src/app/common/route-consts';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-dash-header',
  templateUrl: './dash-header.component.html',
  styleUrls: ['./dash-header.component.scss']
})
export class DashHeaderComponent implements OnInit {

  public _routeConsts = new RouteConsts();
  breadcrumbs: Array<{ label: string, url: string }> = [];
  constructor(public authService: AuthenticationService, private router: Router,private activatedRoute: ActivatedRoute) {

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.buildBreadcrumb(this.activatedRoute.root);
    });
   }

  ngOnInit() {
  }

  private buildBreadcrumb(route: ActivatedRoute, url: string = '', breadcrumbs: Array<any> = []): Array<any> {
    // Get the current route URL part
    const routeURL: string = route.snapshot.url.map(segment => segment.path).join('/');
    
    // Append the current part to the full URL
    if (routeURL) {
      url += `/${routeURL}`;
    }

    // Get the breadcrumb label from route data or fallback to the URL part
    const label = route.snapshot.data['breadcrumb'] || this.capitalizeFirstLetter(routeURL);

    // Only add the breadcrumb if a label is available
    if (label) {
      breadcrumbs.push({ label, url });
    }

    // Check for children routes and recursively call buildBreadcrumb on each child
    for (const child of route.children) {
      return this.buildBreadcrumb(child, url, breadcrumbs);
    }

    // If no more children, return the complete breadcrumb array
    this.breadcrumbs = breadcrumbs;
    return breadcrumbs;
}

  private capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
