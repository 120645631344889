export class VmhReportModel {
    public Id?:number; 
    public ToDate:Date = null;
    public FromDate:Date = null;
}

export class VMHReportVM
    {
         public Id: number;
         public Thumbnail: string;
         public CompName: string;
         public Address: string;
         public Contact: string
         public Customer: string
         public VehicleModel: string
         public VehicleCode: string

         public VmhReportData: VmhReportData[];


    }


    export class VmhReportData
    {
        public SaleId?: number;
        public InvoiceDate?: Date;
        public InvoiceNumber?: number;
        public InvoiceAmount?: number;
        public Odometer?: number;
        public DriverName:string;
        public DriverPhone:string;
        public lstParts: VmhReportPartAndService[];
        public lstServices: VmhReportPartAndService[];
        public Tags: string;
        public IsPaid: boolean;
        public PartsAmount?: number;
        public ServiceAmount?: number;
        public TotalVat?: number;
        public Total?: number;
        public TotalWithVat?: number;
    }

    export class VmhReportPartAndService
    {
        public Name: string
        public Quantity?: number;
        public Rate?: number;
        public Discount?: number;
        public VatAmount?:number;
        public TotalAmount?:number;
        public SalePrice?:number;
    }