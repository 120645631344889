import { ItemStockReportViewModel } from './ItemStockReportViewModel';

export class StockReportTotalViewModel{
  public TotalitemPrice : number;
  public TotalInQuantity : number;
  public TotalAssignQuantity : number;
  public TotalQuantity : number;
  public TotalAmount : number;
  public StockReportList : ItemStockReportViewModel[];
}
