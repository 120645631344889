export class Raw {

        public  Code:string;
        public Type:string;
        public Id?:number; 
        public Cid:number;
        public Client_Id?:number;
        public returnQty: number;
        public Date:Date;
        public ToDate:Date;
        public FromDate:Date;
        public BrandId:number;
        public BrandIds:any = []; 
}
