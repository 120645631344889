import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import * as $ from './../../../assets/js/jquery.min.js';
import { RouteConsts } from 'src/app/common/route-consts.js';
import { G_SaleMasterViewModel } from 'src/app/viewmodels/g-sale/G_SaleMasterViewModel.js';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service.js';
import { DasboardCountViewModel } from 'src/app/viewmodels/g-settings/DasboardCountViewModel.js';
declare var $: any;


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public dashboardVM: DasboardCountViewModel;
  public _routeConsts = new RouteConsts();
  @Input() public vm: G_SaleMasterViewModel;
  constructor(
    private _uow: UnitOfWorkService
    ) {

   }

  ngOnInit() {
    this.dashInit();
  }

  ngAfterViewInit() {

    // $('.datatable').DataTable();
  }

  dashInit() {
    this._uow.reportingservice.getCountsForDashboard().subscribe((res: any) => {
      this.dashboardVM = res;
      console.log(res);
    }, (err) => {
      console.log(err);
    });
  }
}
