import { Injectable } from '@angular/core';
import { Nav } from 'src/app/viewmodels/Nav';
import { LocalStorageService } from '../common/local-storage.service';
import { Router } from '@angular/router';
import { CookieService } from '../common/cookie.service';
import { HttpClient } from '@angular/common/http';
import { EndpointConfig } from '../common/EndpointConfig';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  constructor(private router: Router,
    private _localStorageService: LocalStorageService,
    private _cookieService: CookieService,
    private http: HttpClient) { }

  public getUser() {
    return this._localStorageService.get('ussr');
  }

  public getCom() {
    return this._localStorageService.get('com');
  }

  public isUserStampValid(user) {
    return this.http.post(`${EndpointConfig.getMvcUrl('Account', 'ValidateUserStamp')}`, { User: user.Id, Stamp: user.Stamp });
  }

  // export class SecurityStampValidationModel{
  //   public User : string;
  //   public Stamp : string;
  //   }

  public setUser(_: any): boolean {
    let user = JSON.parse(_.user);
    let com = JSON.parse(_.com);
    let nav = _.nav;
    let token = _.access_token;
    if (token !== '') {
      console.log('gonna clear the cookie if exists');
      this._cookieService.deleteAndSetCookie('token', token);

      this._localStorageService.set('ussr', user);
      this._localStorageService.set('ussrNvd', nav);
      this._localStorageService.set('com', com);
      return true;
    } else {
      // console.log('logout');
      this.logout();
      return false;
    }
  }

  public getToken(): string {
    var token = this._cookieService.getCookie('token');
    return token;
  }

  // public getNav() {
  //   console.log('getting the nav');

  //   let realNav: Nav[] = [];
  //   let serverNav: any = this._localStorageService.get('ussrNvd');
  //   let parsedNav: any[] = JSON.parse(serverNav);
  //   if (parsedNav !== undefined && parsedNav.length > 0) {

  //     parsedNav.forEach(n => {
  //       let nav: Nav = new Nav();
  //       nav.name = n.Name;
  //       nav.url = '/' + n.Url;
  //       nav.icon = n.Icon;
  //       nav.children = n.children.length > 0 ? this.getchildNav(n.children) : null;
  //       realNav.push(nav);
  //     });
  //   }
  //   return realNav;
  // }

  // getchildNav(n: any) {
  //   let childNav: Nav[] = [];
  //   n.forEach(c => {
  //     let cn: Nav = new Nav();
  //     cn.name = c.Name;
  //     cn.url = '/' + c.Url;
  //     cn.icon = c.Icon;
  //     cn.children = c.children.length > 0 ? this.getchildNav(c.children) : null;
  //     childNav.push(cn);
  //   });
  //   return childNav;
  // }

  login(user) {
    return this.http.post(`${EndpointConfig.getMvcUrl('Account', 'Token')}`, { username: user.username, password: user.password });
  }

  logout() {
    this._cookieService.deleteCookie('token');
    this._cookieService.deleteCookie('ussrNvd');
    this._localStorageService.remove('ussr');
    this._localStorageService.remove('ussrNvd');
    this._localStorageService.remove('com');
    this.router.navigate(['/login']);
  }

}
