import { Injectable } from '@angular/core';
import { EndpointConfig } from '../../common/EndpointConfig';
import { IHttpService } from '../../common/IHttpService';
import { G_SSCategoryViewModel } from 'src/app/viewmodels/g-settings/G_SSCategoryViewModel';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SsCategoryService  implements IHttpService<G_SSCategoryViewModel>  {
  private _ep: EndpointConfig;
  constructor(private _client: HttpClient) {
    this._ep = new EndpointConfig('GSettings');
  }

  GetList() {
    return this._client.get(this._ep.getEndpointMethod('GetG_SSCategoryList'));
  }

  checkDublication(code) {
    return this._client.post(this._ep.getEndpointMethod('CheckG_SSCategoryDublication'), { Code: code });
  }

  GetById(id: any) {
    return this._client.post(this._ep.getEndpointMethod('GetG_SSCategoryById'), { Id: id });
  }
  Post(obj: G_SSCategoryViewModel) {
    return this._client.post(this._ep.getEndpointMethod('SaveG_SSCategory'), obj);
  }

  Put(obj: G_SSCategoryViewModel) {
    return this._client.post(this._ep.getEndpointMethod('SaveG_SSCategory'), obj);
  }

  Delete(obj: G_SSCategoryViewModel) {
    return this._client.post(this._ep.getEndpointMethod('DeleteG_SSCategory'), obj);
  }
}
