import { Injectable } from '@angular/core';
import { IHttpService } from '../../common/IHttpService';
import { G_CountryViewModel } from 'src/app/viewmodels/g-settings/G_CountryViewModel';
import { EndpointConfig } from '../../common/EndpointConfig';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CountryService implements IHttpService<G_CountryViewModel> {
  private _ep: EndpointConfig;
  constructor(private _client: HttpClient) {
    this._ep = new EndpointConfig('GSettings');
  }

  GetList() {
    return this._client.get(this._ep.getEndpointMethod('GetG_CountryList'));
  }

  checkDublication(code) {
    return this._client.post(this._ep.getEndpointMethod('CheckCountryDublication'), { Code: code });
  }

  GetById(id: any) {
    return this._client.post(this._ep.getEndpointMethod('GetG_CountryById'), { Id: id });
  }
  Post(obj: G_CountryViewModel) {
    let fd = new FormData();
    fd.append('Obj',JSON.stringify(obj));
    return this._client.post(this._ep.getEndpointMethod('SaveG_Country'), fd);
  }

  Put(obj: G_CountryViewModel) {
    return this._client.post(this._ep.getEndpointMethod('SaveG_Country'), obj);
  }

  Delete(obj: G_CountryViewModel) {
    return this._client.post(this._ep.getEndpointMethod('DeleteG_Country'), obj);
  }
}
