import { Injectable } from '@angular/core';
import { EndpointConfig } from '../../common/EndpointConfig';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../../auth/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {
  private _ep: EndpointConfig;
  constructor(private _client: HttpClient, private _authenticationService: AuthenticationService) {
    this._ep = new EndpointConfig('Accounts');
  }

  GetCode() {
    return this._client.post(this._ep.getEndpointMethod('ValidateAccCode'), { Code: '-1' });
  }

  public getAccountBySearch(x: string, event: string) {
    return this._client.post(this._ep.getEndpointMethod('GetAccountBySearch'), { Code: x, Type: event });
  }
}
