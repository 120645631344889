import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { DashHeaderComponent } from './components/_layout/dash-header/dash-header.component';
import { DashLayoutComponent } from './components/_layout/dash-layout/dash-layout.component';
import { DashFooterComponent } from './components/_layout/dash-footer/dash-footer.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { FuleTypeComponent } from './components/g-settings/fule-type/fule-type.component';
import { BrandComponent } from './components/g-settings/brand/brand.component';
import { ModelComponent } from './components/g-settings/model/model.component';
import { GarageSettingsComponent } from './components/g-settings/garage-settings/garage-settings.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ModalModule, BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { BsngxModelComponent } from './components/_layout/bsngx-model/bsngx-model.component';
import { ItemTypeComponent } from './components/g-settings/item-type/item-type.component';
import { ManufacturerComponent } from './components/g-settings/manufacturer/manufacturer.component';
import { SsCategoryComponent } from './components/g-settings/ss-category/ss-category.component';
import { StatusTypeComponent } from './components/g-settings/status-type/status-type.component';
import { TagsTypeComponent } from './components/g-settings/tags-type/tags-type.component';
import { TagsComponent } from './components/g-settings/tags/tags.component';
import { CustomerComponent } from './components/g-customer/customer/customer.component';
import { SupplierComponent } from './components/g-supplier/supplier/supplier.component';
import { VehicleComponent } from './components/g-vehicle/vehicle/vehicle.component';
import { ItemComponent } from './components/g-settings/item/item.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SsCategoryFormComponent } from './components/g-settings/ss-category/ss-category-form/ss-category-form.component';
import { ItemTypeFormComponent } from './components/g-settings/item-type/item-type-form/item-type-form.component';
import { ItemFormComponent } from './components/g-settings/item/item-form/item-form.component';
import { FuleTypeFormComponent } from './components/g-settings/fule-type/fule-type-form/fule-type-form.component';
import { BrandFormComponent } from './components/g-settings/brand/brand-form/brand-form.component';
import { ModelFormComponent } from './components/g-settings/model/model-form/model-form.component';
import { StatusTypeFormComponent } from './components/g-settings/status-type/status-type-form/status-type-form.component';
import { TagsFormComponent } from './components/g-settings/tags/tags-form/tags-form.component';
import { TagsTypeFormComponent } from './components/g-settings/tags-type/tags-type-form/tags-type-form.component';
import { SupplierFormComponent } from './components/g-supplier/supplier-form/supplier-form.component';
import { ManufacturerFormComponent } from './components/g-settings/manufacturer/manufacturer-form/manufacturer-form.component';
import { CustomerFormComponent } from './components/g-customer/customer-form/customer-form.component';
import { VehicleFormComponent } from './components/g-vehicle/vehicle-form/vehicle-form.component';
import { QRCodeModule } from 'angular2-qrcode';
import { PackageComponent } from './components/g-packages/package/package.component';
import { PackageFormComponent } from './components/g-packages/package-form/package-form.component';
import { PackageTypeComponent } from './components/g-settings/package-type/package-type.component';
import { PackageTypeFormComponent } from './components/g-settings/package-type/package-type-form/package-type-form.component';
import { PurchaseRequsitionComponent } from './components/g-purchase/purchase-requsition/purchase-requsition.component';
import { PurchaseOrderComponent } from './components/g-purchase/purchase-order/purchase-order.component';
import { PurchaseOrderFormComponent } from './components/g-purchase/purchase-order/purchase-order-form/purchase-order-form.component';
import { PurchaseRequsitionFormComponent } from './components/g-purchase/purchase-requsition/purchase-requsition-form/purchase-requsition-form.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { PurchaseComponent } from './components/g-purchase/purchase/purchase.component';
import { PurchaseFormComponent } from './components/g-purchase/purchase/purchase-form/purchase-form.component';
import { CountryComponent } from './components/g-settings/country/country.component';
import { CountryFormComponent } from './components/g-settings/country/country-form/country-form.component';
import { UnitComponent } from './components/g-settings/unit/unit.component';
import { UnitFormComponent } from './components/g-settings/unit/unit-form/unit-form.component';
import { ColorsComponent } from './components/g-settings/colors/colors.component';
import { ColorsFormComponent } from './components/g-settings/colors/colors-form/colors-form.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { ComplainsAndSuggestionsComponent } from './components/g-settings/complains-and-suggestions/complains-and-suggestions.component';
import { ComplainsAndSuggestionsFormComponent } from './components/g-settings/complains-and-suggestions/complains-and-suggestions-form/complains-and-suggestions-form.component';
import { SaleCompComponent } from './components/g-sale/sale-comp/sale-comp.component';
import { RepairOrderComponent } from './components/g-sale/repair-order/repair-order.component';
import { PrepareInvoiceComponent } from './components/g-sale/prepare-invoice/prepare-invoice.component';
import { ImageUploadFormComponent } from './components/g-settings/image-upload/image-upload-form/image-upload-form.component';
import { ImageUploadComponent } from './components/g-settings/image-upload/image-upload.component';
import { RepairOrderListComponent } from './components/g-sale/repair-order/repair-order-list/repair-order-list.component';
import { RepairInvoiceComponent } from './components/g-sale/repair-invoice/repair-invoice.component';
import { RepairOrderPdfComponent } from './components/g-sale/repair-order/repair-order-pdf/repair-order-pdf.component';
import { WorkInProgessFormComponent } from './components/g-sale/repair-order/work-in-progess-form/work-in-progess-form.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { DirectSaleComponent } from './components/g-sale/direct-sale/direct-sale.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { WipRepairOrdersComponent } from './components/g-sale/repair-order/wip-repair-orders/wip-repair-orders.component';
import { UpdatedInvoiceComponent } from './components/g-sale/repair-invoice/updated-invoice/updated-invoice.component';
import { PaymentStatusComponent } from './components/g-sale/payment-status/payment-status.component';
import { ReadyListComponent } from './components/g-sale/repair-order/ready-list/ready-list.component';
import { DirectSaleInvoiceComponent } from './components/g-sale/direct-sale/direct-sale-invoice/direct-sale-invoice.component';
import { DirectSaleListComponent } from './components/g-sale/direct-sale/direct-sale-list/direct-sale-list.component';
import { PurchasePdfInvoiceComponent } from './components/g-purchase/purchase/purchase-pdf-invoice/purchase-pdf-invoice.component';
import { EditRepairOrderComponent } from './components/g-sale/repair-order/edit-repair-order/edit-repair-order.component';
import { EditDirectSaleComponent } from './components/g-sale/direct-sale/edit-direct-sale/edit-direct-sale.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { DataTablesModule } from 'angular-datatables';
import { ItemstockreportComponent } from './components/Reporting/stockReport/itemstockreport/itemstockreport.component';
import { PurchaseDtlViewComponent } from './components/g-purchase/purchase/purchase-dtl-view/purchase-dtl-view.component';
import { DirectSaleDetailViewComponent } from './components/g-sale/direct-sale/direct-sale-detail-view/direct-sale-detail-view.component';
import { PurchaseInstallmentComponent } from './components/g-purchase/purchase/purchase-installment/purchase-installment.component';
import { PaymentStatusDetailViewComponent } from './components/g-sale/payment-status/payment-status-detail-view/payment-status-detail-view.component';
import { StockAdjustmentComponent } from './components/Reporting/stockReport/stock-adjustment/stock-adjustment.component';
import { StockAdjustmentListComponent } from './components/Reporting/stockReport/stock-adjustment-list/stock-adjustment-list.component';
import { BulkPaymentsComponent } from './components/g-sale/bulk-payments/bulk-payments.component';
import { BulkPaymentsListComponent } from './components/Reporting/bulk-payments-list/bulk-payments-list.component';
import { AggingReportComponent } from './components/Reporting/agging-report/agging-report.component';
import { SlideToggleModule } from 'ngx-slide-toggle';
import { SaleCompletedComponent } from './components/g-sale/sale-completed/sale-completed.component';
import { NgxSpinnerModule } from "ngx-spinner";

import { AllSalesListComponent } from './components/g-sale/all-sales-list/all-sales-list.component';
import { CustomerSalesInvoicesReportComponent } from './components/g-sale/customer-sales-invoices-report/customer-sales-invoices-report.component';
import { WarehouseComponent } from './components/g-settings/warehouse/warehouse.component';
import { WarehouseFormComponent } from './components/g-settings/warehouse/warehouse-form/warehouse-form.component';
import { WarehouseItemsFormComponent } from './components/g-settings/warehouse/warehouse-items-form/warehouse-items-form.component';
import { WarehouseItemComponent } from './components/g-settings/item/warehouse-item/warehouse-item.component';
import { StockListComponent } from './components/g-inventory/stock-list/stock-list.component';
import { StockFormComponent } from './components/g-inventory/stock-form/stock-form.component';
import { WarehousestockreportComponent } from './compnents/Reporting/stockReport/warehousestockreport/warehousestockreport.component';
import { InventoryFlowReportComponent } from './components/Reporting/inventory-flow-report/inventory-flow-report.component';
import { BulkStockAdjustmentComponent } from './components/Reporting/stockReport/bulk-stock-adjustment/bulk-stock-adjustment.component';
import { SalesReportsByDateComponent } from './components/Reporting/sales-reports-by-date/sales-reports-by-date.component';
import { VmhReportComponent } from './components/Reporting/vmh-report/vmh-report.component';


@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ModalModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    QRCodeModule,
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule,
    ColorPickerModule,
    SweetAlert2Module.forRoot(),
    NgxDropzoneModule,
    NgxPaginationModule,
    DataTablesModule,
    SlideToggleModule,
    NgxSpinnerModule
  ],
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    DashHeaderComponent,
    DashLayoutComponent,
    DashFooterComponent,
    FuleTypeComponent,
    BrandComponent,
    ModelComponent,
    GarageSettingsComponent,
    BsngxModelComponent,
    ItemTypeComponent,
    ManufacturerComponent,
    SsCategoryComponent,
    StatusTypeComponent,
    TagsTypeComponent,
    TagsComponent,
    CustomerComponent,
    SupplierComponent,
    VehicleComponent,
    ItemComponent,
    SsCategoryFormComponent,
    ItemTypeFormComponent,
    ItemFormComponent,
    FuleTypeFormComponent,
    BrandFormComponent,
    ModelFormComponent,
    StatusTypeFormComponent,
    TagsFormComponent,
    TagsTypeFormComponent,
    SupplierFormComponent,
    ManufacturerFormComponent,
    CustomerFormComponent,
    VehicleFormComponent,
    PackageComponent,
    PackageFormComponent,
    PackageTypeComponent,
    PackageTypeFormComponent,
    PurchaseRequsitionComponent,
    PurchaseOrderComponent,
    PurchaseOrderFormComponent,
    PurchaseRequsitionFormComponent,
    PurchaseComponent,
    PurchaseFormComponent,
    CountryComponent,
    CountryFormComponent,
    UnitComponent,
    UnitFormComponent,
    ColorsComponent,
    ColorsFormComponent,
    ComplainsAndSuggestionsComponent,
    ComplainsAndSuggestionsFormComponent,
    SaleCompComponent,
    RepairOrderComponent,
    PrepareInvoiceComponent,
    ImageUploadFormComponent,
    ImageUploadComponent,
    RepairOrderListComponent,
    RepairInvoiceComponent,
    RepairOrderPdfComponent,
    WorkInProgessFormComponent,
    DirectSaleComponent,
    WipRepairOrdersComponent,
    UpdatedInvoiceComponent,
    PaymentStatusComponent,
    ReadyListComponent,
    DirectSaleInvoiceComponent,
    DirectSaleListComponent,
    PurchasePdfInvoiceComponent,
    EditRepairOrderComponent,
    EditDirectSaleComponent,
    ItemstockreportComponent,
    PurchaseDtlViewComponent,
    DirectSaleDetailViewComponent,
    PurchaseInstallmentComponent,
    PaymentStatusDetailViewComponent,
    StockAdjustmentComponent,
    StockAdjustmentListComponent,
    BulkPaymentsComponent,
    BulkPaymentsListComponent,
    AggingReportComponent,
    SaleCompletedComponent,
    AllSalesListComponent,
    CustomerSalesInvoicesReportComponent,
    WarehouseComponent,
    WarehouseFormComponent,
    WarehouseItemsFormComponent,
    WarehouseItemComponent,
    StockListComponent,
    StockFormComponent,
    WarehousestockreportComponent,
    InventoryFlowReportComponent,
    BulkStockAdjustmentComponent,
    SalesReportsByDateComponent,
    VmhReportComponent
  ],
  providers: [
    DatePipe,
    BsModalService,
    BsModalRef,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA ],
})
export class AppModule { }
