import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { G_StatusTypeViewModel } from 'src/app/viewmodels/g-settings/G_StatusTypeViewModel';
import { BsModalRef } from 'ngx-bootstrap/modal/public_api';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-status-type-form',
  templateUrl: './status-type-form.component.html',
  styleUrls: ['./status-type-form.component.scss']
})
export class StatusTypeFormComponent implements OnInit {
  @Input() public vm: G_StatusTypeViewModel;
  @Input() public modal_title: any;
  @Input() public bsmRef: BsModalRef;
  @Output() onSubmitted = new EventEmitter<any>();
  private submitted = false;
  constructor(
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
  ) { }

  ngOnInit() {
    if (this.vm == undefined) {
      this.vm = new G_StatusTypeViewModel();
    }
    if (this.modal_title == undefined) {
      this.modal_title = "Status Type";
    }
  }

  saveOrUpdate() {
    this._jq.showSpinner(true);
    this._uow.statusTypeService
      .Post(this.vm)
      .toPromise().then((res: any) => {
        console.log(res);
        this.submitted = true;
        this.bsmRef.hide();
        this.onSubmitted.emit(this.submitted);
        Swal.fire(
          'Created!',
          'Status-Type Created/Updated!',
          'success'
        )
      })
      .catch((error) => {
        Swal.fire(
          'Failure!',
          'Status-Type Not Updated!',
          'error'
        )
        console.log(`Promise rejected with ${JSON.stringify(error)}`);
        this.onSubmitted.emit(this.submitted);
      });
  }

}
