import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { ItemStockReportViewModel } from 'src/app/viewmodels/g-item/ItemStockReportViewModel';
import { StockAdjustmentViewModel } from 'src/app/viewmodels/g-item/StockAdjustmentViewModel';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { StockAdjustmentDetailViewModel } from 'src/app/viewmodels/g-item/StockAdjustmentDetailViewModel';
import { ItemViewModel } from 'src/app/viewmodels/ItemViewModel';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-stock-adjustment-list',
  templateUrl: './stock-adjustment-list.component.html',
  styleUrls: ['./stock-adjustment-list.component.scss']
})
export class StockAdjustmentListComponent implements OnInit {
  modalRefLv1: BsModalRef;
  modalRefLv2: BsModalRef;
  @Input() public modal_title: any;
  @Input() public bsmRef: BsModalRef;
  public modalRef: BsModalRef;
  public itemVm: ItemStockReportViewModel = new ItemStockReportViewModel();
  public vmS: StockAdjustmentViewModel = new StockAdjustmentViewModel();
  public vmD: StockAdjustmentDetailViewModel = new StockAdjustmentDetailViewModel();
  public vmListItems: any = [];
  p: number = 1;
  dataTable: any;
  @Input('dataTable') table;
  public stockbyid: any = [];
  dtOption: any = {};
  public component_Title = 'Stock Adjustment List';
  public SortBy:string = "asc";
  private submitted= false;
  public IsDisable= false;
  public isSave= false;
  public isfetch= false;
  public nowDate: Date;
  public calingUrl: string;
  public urlId: number;
  constructor(
    private route: ActivatedRoute,
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    private http: HttpClient,
    public _router: Router,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.getStockList();
    this.dtOption = {
      "paging":   false,
      "searching": false,
      "info": false,
  };
  $(() =>{
    $('table.display').DataTable(this.dtOption);
  })
  }

  getStockList(){
    this.spinner.show();
    this.vmS = new StockAdjustmentViewModel();
    this._jq.showSpinner(true);
    this._uow.itemService.GetStockAdjustedItemReport().subscribe((res: any) => {
      this.vmListItems = res.DtlList;
      this.vmD = res.DtlList;
      this.TotalStockAmount();
      console.log(res);
      // this._jq.showSpinner();
      this.spinner.hide();
    }, (err) => {
      console.log(err);
      // this._jq.showSpinner();
      this.spinner.hide();

    });
  }

  public searchTerm = '';
  getBySearchTerm(e) {
    if (this.searchTerm !== ' ') {
      // this._jq.showSpinner(true);
      this.spinner.show();
      this._uow.itemService.GetStockPagedBySearch(this.searchTerm).subscribe((res: any) => {
        this.p = 1;
        this.vmListItems = res.DtlList;
        // this._jq.showSpinner();
        this.spinner.hide();
      }, (error) => {
        console.log(error);
        // this._jq.showSpinner();
        this.spinner.hide();
      });
    }
  }

  openSaveOrUpdateModel(template: TemplateRef<any>, model?) {
    debugger
      this.openModal(template, 1);
      this.modalRef.setClass('modal-xl');
      }
      openSaveBulkAdjustmentModel(template: TemplateRef<any>, model?) {
        debugger
          this.openModal(template, 1);
          this.modalRef.setClass('modal-xl');
          }

      openViewModel(template: TemplateRef<any>,b, model?) {
        debugger
          this.openModal(template, 1);
          this.modalRef.setClass('modal-xl');
          this.calingUrl = this._router.url;
      this._uow.itemService.GetStockById(b.Id).subscribe((res: any) => {
        this.vmS = res;
        this.vmS.TransactionDate = new Date(res.TransactionDate);
        this.isSave == true;
        this.isfetch == true;
          console.log(this.vmS);
        }, (err) => {
          console.log(err);
         });
          }


  openModal(template: TemplateRef<any>, lvl: number) {
    if (lvl == 1) {
      this.modalRef = this._modalService.show(template,{
        backdrop:true,
        ignoreBackdropClick:true
      });
    }
    if (lvl == 2) {
      this.modalRef = this._modalService.show(template,{
        backdrop:true,
        ignoreBackdropClick:true
      });
    }
  }

  onItemSubmitted(e) {
    console.log(e);
    if (e === true) {
      this.vmS = new StockAdjustmentViewModel();
      this.getStockList();
    }
  }

  getSortIndexList(sIndex) {
    debugger
    this._jq.showSpinner(true);
    this.SortBy = this.SortBy == "asc" ? "desc" : "asc";
    this._uow.itemService.GetStockPagedResult(0, 50, null, sIndex, this.SortBy).subscribe((res: any) => {
      debugger
      this._jq.reinitDataTable(res);
      this.vmListItems = res.DtlList;;
      console.log(res);
      this._jq.showSpinner();
    }, (err) => {
      console.log(err);
    });
  }

  StockChange(e){
    debugger
    if(e.NewQuantity != e.OldQuantity){
      let diff = 0;
      let NewAmount = e.CostofGoodSold * e.NewQuantity;
      if(NewAmount > e.NewAmount){
        diff = NewAmount - e.NewAmount;
        this.vmS.Amount += diff;
      }else{
        diff = e.NewAmount - NewAmount;
        this.vmS.Amount -= diff;
      }
      e.NewAmount = e.CostofGoodSold * e.NewQuantity;
      if(this.vmS.StockAdjustmentDetail == undefined){
        this.vmS.StockAdjustmentDetail = [];
      }
      let stockDetail = this.vmS.StockAdjustmentDetail.find(x=> x.FK_G_Item_Id.Id === e.FK_G_Item_Id.Id);
      if(stockDetail == undefined){
        let stockadjustmentDetail = new StockAdjustmentDetailViewModel();
        stockadjustmentDetail.Id = 0;
        stockadjustmentDetail.FK_G_Item_Id = new ItemViewModel(e.FK_G_Item_Id.Id, e.FK_G_Item_Id.Name);
        stockadjustmentDetail.NewQuantity = e.NewQuantity;
        stockadjustmentDetail.OldQuantity = e.OldQuantity;
        stockadjustmentDetail.CostofGoodSold = e.CostofGoodSold;
        stockadjustmentDetail.NewAmount = e.NewAmount;
        stockadjustmentDetail.TotalAmount = e.TotalAmount;
        this.vmS.StockAdjustmentDetail.push(stockadjustmentDetail);
      }else {
        let sD = this.vmS.StockAdjustmentDetail.indexOf(stockDetail);
        this.vmS.StockAdjustmentDetail[sD].NewQuantity = e.NewQuantity;
        this.vmS.StockAdjustmentDetail[sD].NewAmount = e.NewAmount;
      }
    }else{
      if(this.vmS.StockAdjustmentDetail != undefined){
        let stockDetail = this.vmS.StockAdjustmentDetail.find(x=> x.FK_G_Item_Id.Id === e.FK_G_Item_Id.Id);
        if(stockDetail != undefined){
          let diff = 0;
          let NewAmount = e.CostofGoodSold * e.NewQuantity;
          if(NewAmount > e.NewAmount){
            diff = NewAmount - e.NewAmount;
            this.vmS.Amount += diff;
          }else{
            diff = e.NewAmount - NewAmount;
            this.vmS.Amount -= diff;
          }
          e.NewAmount = e.CostofGoodSold * e.NewQuantity;
          let sD = this.vmS.StockAdjustmentDetail.indexOf(stockDetail);
          this.vmS.StockAdjustmentDetail[sD].NewQuantity = e.NewQuantity;
          this.vmS.StockAdjustmentDetail[sD].NewAmount = e.NewAmount;
        }
      }
    }
  }

  TotalStockAmount(){
    this.vmListItems.forEach(e => {
        this.vmS.Amount += e.NewAmount;
        this.vmS.LastAmount += e.TotalAmount;
    });
  }

  openModel(template: TemplateRef<any>) {
    this.modalRef = this._uow.modalServiceEx.show(template);
      this.modalRef.setClass('modal-xl');
  }

  openView(template: TemplateRef<any>,b, model?) {
    this.openModel(template);
    debugger
    this._uow.itemService.GetStockById(b.Id).subscribe((res: any) => {
      debugger
      this.stockbyid = res;
      console.log(this.stockbyid);
    }, (err) => {
      console.log(err);
     });
  }

}
