import { ItemViewModel } from '../ItemViewModel';
import { StockAdjustmentDetailViewModel } from './StockAdjustmentDetailViewModel';

export class StockAdjustmentViewModel {

  public Id : number;
  public RefId : string;
  public Amount : number;
  public Fk_G_DabitAccount_Id : ItemViewModel;
  public Fk_G_WareHouse_Id : ItemViewModel;
  public Fk_G_CreditAccount_Id : ItemViewModel;
  public FK_ClientId : ItemViewModel;
  public IsExpense : boolean;
  public IsRevenue : boolean;
  public IsActive : boolean;
  public TransactionDate : Date;
  public CreatedBy : string;
  public Remarks: string;
  public LastAmount: number;
  public NewAmount: number;
  public StockAdjustmentDetail : StockAdjustmentDetailViewModel[];
}
