import { ItemViewModel } from '../ItemViewModel';

export class DefineAccountviewModel {
    public Id: number;
    public Status: boolean;
    public Acc_Titel: string;
    public Acc_Display_Titel: string;
    public Acc_Code: string;
    public Name: string;
    public Fk_PreHead: ItemViewModel;
    public Fk_ClientVM: ItemViewModel;
    public Fk_Head1: ItemViewModel;
    public Fk_Head2: ItemViewModel;
    public Fk_Natuer: ItemViewModel;
    public Division: string;
    public Balance: number;
    public Address: string;
    public City: string;
    public CP_Contect_Person: string;
    public CP_Designation: string;
    public CP_Cell: string;
    public CP_Fax: string;
    public CP_Phone: string;
    public CP_Email: string;
    public Acc_GST: string;
    public Acc_NTN: string;
    public Acc_CNIC: string;
    public Acc_Bank_Info: string;
    public Acc_Bank_AccNo: string;
    public Acc_Limit: string;
    public Acc_Term_Payment: string;
    public Mon_Amount: number;
    public FK_Type: number;
    public FK_Task_Type: number;
    public Assoc_Acc: number;
    public Salary: number;
    public Dep_PA: string;
    public CreatedBy: string;
    public CreationDate: Date;
    public UpdatedBy: string;
    public UpdationDate: Date;
    public RecordStatus: boolean;
    public FK_TagId: number;
    public G_SC: string;
    public Country_Code: any;
    public DiscountValue: number;
}
