import { ItemViewModel } from '../ItemViewModel';

export class GItemModelViewModel {
    public Id: number;
    public Fk_G_Brands: ItemViewModel;
    public Fk_G_Models: ItemViewModel;
    // public Fk_G_SSItemDefining_Id: ItemViewModel;
    public IsService: boolean;
    // public IsActive: boolean;
}
