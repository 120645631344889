import { ItemViewModel } from '../ItemViewModel';

export class InventryFlowReport {
    public FK_Item :ItemViewModel;
    public RefId :string;
    public quantity :number;
    public Oldquantity:number;
    public TansactionType: string;
    public Type :string;
    public TrnasactionDate: Date;
}