import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DefineAccountviewModel } from 'src/app/viewmodels/g-customer/DefineAccountviewModel';
import { G_TagsTypeViewModel } from 'src/app/viewmodels/g-settings/G_TagsTypeViewModel';
import { G_TagsViewModel } from 'src/app/viewmodels/g-settings/G_TagsViewModel';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { ItemViewModel } from 'src/app/viewmodels/ItemViewModel';
import { AppConsts } from 'src/app/common/app-consts';
import { GVechileViewModel } from 'src/app/viewmodels/g-vehicle/GVechileViewModel';
import { G_BrandsViewModel } from 'src/app/viewmodels/g-settings/G_BrandsViewModel';
import { G_ModelsViewModel } from 'src/app/viewmodels/g-settings/G_ModelsViewModel';
import { FuleTypeComponent } from '../../g-settings/fule-type/fule-type.component';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ExportExcel } from 'src/app/viewmodels/ExportExcel';

@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.scss']
})
export class VehicleComponent implements OnInit {
  modalRefLv1: BsModalRef;
  modalRefLv2: BsModalRef;
  modalRefLv3: BsModalRef;
  public component_Title = 'Manage Vechicle';
  public vmListItems: any = [];
  public SortBy:string = "asc";
  p: number = 1;
  public itemVm: GVechileViewModel = new GVechileViewModel();
  dataTable: any;
  dtOption: any = {};

  public customerDetail: any = null;
  public selectedcustomerDetail: any = null;
  public exportFormat: string = 'csv';
  startDate: any = null;
  endDate : any = null;
  public CustomerDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'id',  // The field to use for the selected ID
    textField: 'name',  // The field to use for displaying the customer name
    allowSearchFilter: true,
    closeDropDownOnSelection: true,
  };
  constructor(
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.getList();
    this.dtOption = {
      "paging":   false,
      "searching": false,
      "info": false,
  };
  $(() =>{
    $('table.display').DataTable(this.dtOption);
  })
  }

  openModal(template: TemplateRef<any>, lvl: number) {
    if (lvl == 1) {
      this.modalRefLv1 = this._modalService.show(template);
      this.modalRefLv1.setClass('modal-lg');
    }
    if (lvl == 2) {
      this.modalRefLv2 = this._modalService.show(template);
    }
    if (lvl == 3) {
      this.modalRefLv3 = this._modalService.show(template);
    }
  }

  ngAfterViewInit() {
    // this._jq.showSpinner();
  }

  getList() {
    // this._jq.showSpinner(true);
    this.spinner.show();
    this._uow.vehicleService.GetPagedResult().subscribe((res: any) => {
      this.vmListItems = res.DtlList;
      console.log(res);
      // this._jq.showSpinner();
      this.spinner.hide();
    }, (err) => {
      console.log(err);
      this.spinner.hide();
      Swal.fire(
        'Failure!',
        'Network Issue!',
        'error'
      )
    });
  }

  getSortIndexList(sIndex) {
    // this._jq.showSpinner(true);
    this.spinner.show();
    this.SortBy = this.SortBy == "asc" ? "desc" : "asc";
    this._uow.vehicleService.GetPagedResultBySort(0, 50, AppConsts.VEHICLE, sIndex, this.SortBy).subscribe((res: any) => {
      debugger
      this._jq.reinitDataTable(res);
      this.vmListItems = res.DtlList;;
      console.log(res);
      this.spinner.hide();
      // this._jq.showSpinner();
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    });
  }

  public searchTerm = '';
  getBySearchTerm(e) {
    if (this.searchTerm !== ' ') {
      // this._jq.showSpinner(true);
      this.spinner.show();
      this._uow.vehicleService.GetPagedBySearch(this.searchTerm).subscribe((res: any) => {
        this.p = 1;
      this.vmListItems = res.DtlList;
        // this._jq.showSpinner();
      this.spinner.hide();
      }, (error) => {
        console.log(error);
        // this._jq.showSpinner();
      this.spinner.hide();

      });
    }
  }

  openSaveOrUpdateModel(template: TemplateRef<any>, model?) {
    this.openModal(template, 1);
    if (model !== undefined) {
      this.itemVm = model;
    } else {
      this.itemVm = new GVechileViewModel();
    }
  }
  // saveOrUpdate() {
  //   this._jq.showSpinner(true);
  //   this._uow.vehicleService.Post(this.vm).subscribe((res: any) => {
  //     this.getList();
  //     this.modalRefLv1.hide();
  //   }, (err) => {
  //     this._jq.showSpinner();
  //   });
  // }
  openDeleteModel(template: TemplateRef<any>, model) {
    this.openModal(template, 1);
    if (model !== undefined) {
      this.itemVm = model;
    }
  }

  conformDelete() {
    this.spinner.show();
    this._uow.vehicleService.Delete(this.itemVm).subscribe((res: any) => {
      this.getList();
      this.modalRefLv1.hide();
      this.spinner.hide();
    }, (err) => {
      // this._jq.showSpinner();
      this.spinner.hide();
    });
  }

  // external components
  onItemSubmitted(e) {
    if (e == true) {
      this.getList();
      this._jq.showSpinner(false);
    }
  }


  openExportModel(template: TemplateRef<any>) {
    this.openModal(template, 1);
    this.getCustomerName();
  }

  getCustomerName() {
    debugger;
    this._uow.customerService.GetList().subscribe(
      (res: any) => {
        debugger;
        this.customerDetail = res.DtlList.map(x => {
          return {
            id: x.Id, // or the actual ID field
            name: x.Acc_Code + '-' + x.Acc_Titel // concatenated name and code
          };
        });
       
        // this.itemVm.RegistraionNumber = res;
        console.log(res);
        this._jq.showSpinner();
        // this.itemVm.RegistraionNumber = res.code;
      },
      (error) => {
        this._uow.logger.log(error);
        this._jq.showSpinner();
      }
    );
  }

  exportToExcel(){
    debugger;
    this.spinner.show();
        var obj = new ExportExcel();
        obj={
          Customer_Id: this.selectedcustomerDetail && this.selectedcustomerDetail.length > 0 
          ? this.selectedcustomerDetail[0].id 
          : null,  // Assign null if selectedcustomerDetail is null or empty
          StartDate: this.startDate,
          EndDate: this.endDate,
          Format: this.exportFormat
        }
    this._uow.vehicleService.ExportVehicle(obj).subscribe((res:any)=>{
    debugger;
    
    const blob = new Blob([res], { type: res.type });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `VehicleData_${new Date().toISOString().split('T')[0]}.${this.exportFormat.toLowerCase()}`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
    
            this.spinner.hide();
            this.modalRefLv1.hide();
    },
    (error)=>{
      this._uow.logger.log(error);
      this.spinner.hide();
      Swal.fire(
        'Failure!',
        'Network Issue!',
        'error'
      )
    });
      }
}
