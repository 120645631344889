import { ItemViewModel } from '../ItemViewModel';
import {StringItemViewModel} from '../ItemViewModel';
export class G_Warehouse_Viewmodel{
  public Id:number;
  public Name:string;
}
export class G_WareHouseViewModel{
  public  Id :number;
  public  FK_WareHouse_Id :ItemViewModel
  public  FK_User_Id : StringItemViewModel
}