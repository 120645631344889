import { Component, OnInit } from '@angular/core';
import { RouteConsts } from 'src/app/common/route-consts';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';

@Component({
  selector: 'app-garage-settings',
  templateUrl: './garage-settings.component.html',
  styleUrls: ['./garage-settings.component.scss']
})
export class GarageSettingsComponent implements OnInit {
  public _routeConsts= new RouteConsts();
  constructor(
    private _jq: JqHelper,
    private _uow: UnitOfWorkService
  ) { }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this._jq.initDataTable();

  }

}
