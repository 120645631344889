import { ItemViewModel } from '../ItemViewModel';

export class GItemQRViewModel {
    public Id: number;
    public Code: string;
    public Fk_G_SSItemDefining_Id: ItemViewModel;
    public FK_ClientId: ItemViewModel;
    public IsService: boolean;
    public IsActive: boolean;
}
