import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { ItemStockReportViewModel } from 'src/app/viewmodels/g-item/ItemStockReportViewModel';
import { StockAdjustmentDetailViewModel } from 'src/app/viewmodels/g-item/StockAdjustmentDetailViewModel';
import { StockAdjustmentViewModel } from 'src/app/viewmodels/g-item/StockAdjustmentViewModel';
import { G_Warehouse_Viewmodel } from 'src/app/viewmodels/g-settings/G_Warehouse_Viewmodel';
import { ItemViewModel } from 'src/app/viewmodels/ItemViewModel';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-bulk-stock-adjustment',
  templateUrl: './bulk-stock-adjustment.component.html',
  styleUrls: ['./bulk-stock-adjustment.component.scss']
})
export class BulkStockAdjustmentComponent implements OnInit {
  modalRefLv1: BsModalRef;
  modalRefLv2: BsModalRef;
  @Input() public modal_title: any;
  @Input() public bsmRef: BsModalRef;
  public modalRef: BsModalRef;
  public itemVm: ItemStockReportViewModel = new ItemStockReportViewModel();
  public vmS: StockAdjustmentViewModel = new StockAdjustmentViewModel();
  public vmD: StockAdjustmentDetailViewModel = new StockAdjustmentDetailViewModel();
  public vmListItems: any = [];
  public wareH: G_Warehouse_Viewmodel = new G_Warehouse_Viewmodel();
  p: number = 1;
  public component_Title = 'Bulk Stock Adjustment';
  private submitted= false;
  public IsDisable= false;
  public isSave= false;
  public isfetch= false;
  @Output() onSubmitted = new EventEmitter<any>();
  public nowDate: Date;
  public calingUrl: string;
  public urlId: number;
  public warehouseList: any = [];
  public selectedWarehouse: any = [];
  selectedSpareParts:Array<any> =[];
  public warehouseDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'Id',
    textField: 'FK_G_Item_Id',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
  public itemsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'Id',
    textField: 'Name',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
  constructor(
    private route: ActivatedRoute,
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    private http: HttpClient,
    public _router: Router,
    private spinner: NgxSpinnerService
  ) {
    this.vmS.StockAdjustmentDetail =[];
   }

  ngOnInit() {
    this.vmS.Amount = 0;
    this.vmS.LastAmount = 0;
    this.selectedWarehouse = [];
    if (this.vmS == undefined) {
      this.nowDate = new Date();
    this.vmS = new StockAdjustmentViewModel();
    this.vmS.Id =0;
    this.vmS.Amount = 0;
    this.vmS.LastAmount = 0;
    }
    this.vmS.TransactionDate = new Date();
    this.getWarehouses();
  }
  getWarehouses() {
    this._uow.warehouseService.GetListByUserId().subscribe((res: any) => {
      this.warehouseList = res;
      console.log(res);
    }, (error) => {
      console.log(error);
    });
  }
  onSelectWarehouse(e){
    this.isfetch = true;
    // debugger
    // let f = this.warehouseList.find(x => x.Id == e.Id);
    // this.wareH = new G_Warehouse_Viewmodel();
    this.wareH.Id = this.vmS.Fk_G_WareHouse_Id.Id;
    this.wareH.Name = this.vmS.Fk_G_WareHouse_Id.Name;
    // this.selectedWarehouse.push(this.wareH);
    this.getStockList(this.wareH.Id);
    
  }
  onDeselectWarehouse(e){
    this.selectedWarehouse = [];
  }
  onSelectSparePart(e){
    let item = this.vmListItems.find(x => x.Id === e.Id);
    if(item !== undefined){
      let i =new StockAdjustmentDetailViewModel();
      i.CostofGoodSold =item.CostofGoodSold;
      i.FK_G_Item_Id = new ItemViewModel(item.Id, item.Name);
      i.FK_G_StockAdjustment_Id =item.FK_G_StockAdjustment_Id
      i.Fk_G_WareHouse_Id =item.Fk_G_WareHouse_Id
      i.NewAmount =item.NewAmount
      i.NewQuantity =item.NewQuantity
      i.OldQuantity =item.OldQuantity
      i.Quantity =item.Quantity
      i.TotalAmount =item.TotalAmount
      i.IsLock =item.IsLock;
      i.TransactionDate =item.TransactionDate
      this.vmS.StockAdjustmentDetail.push(i);
      let fsp = this.selectedSpareParts.find(x => x.Id == item.Id);
      if (fsp == undefined) {
        this.selectedSpareParts.push(new ItemViewModel(item.Id, item.Name));
      }
      this.TotalStockAmount();

    }
console.log('selected item',e);
  }
  onDeSelectSparePart(event){
    console.log(event);
    const index = this.vmS.StockAdjustmentDetail.findIndex(x =>x.FK_G_Item_Id.Id === event.Id);
    console.log('finded index',index);
    this.vmS.StockAdjustmentDetail.splice(index,1);
    this.TotalStockAmount();
  }
  getStockList(id){
    this._jq.showSpinner(true);
    this.vmD = new StockAdjustmentDetailViewModel();
    debugger
   
    this._uow.itemService.GetStockByWarehouse(id).subscribe((res: any) => {
      this.vmListItems = res;
      this.vmD = res.DtlList;
      // this.TotalStockAmount();
      console.log(res);
    this._jq.showSpinner();
    }, (err) => {
      console.log(err);
      this._jq.showSpinner();

    });
  }
  saveStockAdjustment(){
    debugger
    // this._jq.showSpinner(true);
    this.spinner.show();
    this.vmS.Fk_G_CreditAccount_Id = new ItemViewModel(0,'');
    this.vmS.Fk_G_DabitAccount_Id = new ItemViewModel(0,'');
    this.vmS.FK_ClientId = new ItemViewModel(0,'');
    this.vmS.Fk_G_WareHouse_Id = new ItemViewModel(this.wareH.Id, this.wareH.Name);
    //this.vmS = new StockAdjustmentViewModel();
    this._uow.itemService.PostStockAdjustment(this.vmS).
    toPromise().then((res: any) =>{
      console.log(res);
      this._jq.showSpinner();
      this.submitted = true;
      this.onSubmitted.emit(this.submitted);
      this.getStockList(this.wareH.Id);
      this.spinner.hide();
      Swal.fire(
        'Created!',
        'Stock Adjustment Added/Updated!',
        'success'
      )
      this.bsmRef.hide();
    })
    .catch((error) => {
      Swal.fire(
        'Failure!',
        'Stock Adjustment Not Added/Updated!',
        'error'
      )
      console.log(`Promise rejected with ${JSON.stringify(error)}`);
      this.onSubmitted.emit(false);
      console.log(error);
      this.spinner.hide();
    });
  }
  public searchTerm = '';
  getBySearchTerm(e) {
    if (this.searchTerm !== ' ') {
      this._jq.showSpinner(true);
      this._uow.itemService.GetPagedBySearch(this.searchTerm).subscribe((res: any) => {
        this.vmListItems = res.DtlList;
        this._jq.showSpinner();
      }, (error) => {
        console.log(error);
        this._jq.showSpinner();
      });
    }
  }
  openSaveOrUpdateModel(template: TemplateRef<any>, model?) {
    debugger
    this.openModal(template, 1);
    this.modalRefLv1.setClass('modal-lg');
  }
  openModal(template: TemplateRef<any>, lvl: number) {
    if (lvl == 1) {
      this.modalRefLv1 = this._modalService.show(template);
    }
    if (lvl == 2) {
      this.modalRefLv2 = this._modalService.show(template);
    }
  }
  StockChange(e){

    if(e.NewQuantity != e.OldQuantity){
      let diff = 0;
      let NewAmount = e.CostofGoodSold * e.NewQuantity;
      if(NewAmount > e.NewAmount){
        diff = NewAmount - e.NewAmount;
        this.vmS.Amount += diff;
      }else{
        diff = e.NewAmount - NewAmount;
        this.vmS.Amount -= diff;
      }
      e.NewAmount = e.CostofGoodSold * e.NewQuantity;
      if(this.vmS.StockAdjustmentDetail == undefined){
        this.vmS.StockAdjustmentDetail = [];
      }
      let stockDetail = this.vmS.StockAdjustmentDetail.find(x=> x.FK_G_Item_Id.Id === e.FK_G_Item_Id.Id);
      if(stockDetail == undefined){
        let stockadjustmentDetail = new StockAdjustmentDetailViewModel();
        stockadjustmentDetail.Id = 0;
        stockadjustmentDetail.FK_G_Item_Id = new ItemViewModel(e.FK_G_Item_Id.Id, e.FK_G_Item_Id.Name);
        stockadjustmentDetail.NewQuantity = e.NewQuantity;
        stockadjustmentDetail.Quantity = e.Quantity;
        stockadjustmentDetail.OldQuantity = e.OldQuantity;
        stockadjustmentDetail.CostofGoodSold = e.CostofGoodSold;
        stockadjustmentDetail.NewAmount = e.NewAmount;
        stockadjustmentDetail.TotalAmount = e.TotalAmount;
        this.vmS.StockAdjustmentDetail.push(stockadjustmentDetail);
      }else {
        let sD = this.vmS.StockAdjustmentDetail.indexOf(stockDetail);
        this.vmS.StockAdjustmentDetail[sD].NewQuantity = e.NewQuantity;
        this.vmS.StockAdjustmentDetail[sD].NewAmount = e.NewAmount;
      }
    }
    else{
      if(this.vmS.StockAdjustmentDetail != undefined){
        let stockDetail = this.vmS.StockAdjustmentDetail.find(x=> x.FK_G_Item_Id.Id === e.FK_G_Item_Id.Id);
        if(stockDetail != undefined){
          let diff = 0;
          let NewAmount = e.CostofGoodSold * e.NewQuantity;
          if(NewAmount > e.NewAmount){
            diff = NewAmount - e.NewAmount;
            this.vmS.Amount += diff;
          }else{
            diff = e.NewAmount - NewAmount;
            this.vmS.Amount -= diff;
          }
          e.NewAmount = e.CostofGoodSold * e.NewQuantity;
          let sD = this.vmS.StockAdjustmentDetail.indexOf(stockDetail);
          this.vmS.StockAdjustmentDetail[sD].NewQuantity = e.NewQuantity;
          this.vmS.StockAdjustmentDetail[sD].NewAmount = e.NewAmount;
        }
      }
    }
    this.CheckStockAvailability(e);
  }
  CheckStockAvailability(e){
    debugger
    if (e.NewQuantity < e.OldQuantity) {
      let qtydif = e.OldQuantity - e.NewQuantity;
      if (qtydif > e.Quantity) {
        e.IsDisable = true;
        this.IsDisable = true;
      }else{
        e.IsDisable = false;
        this.IsDisable = false;
      }
    }
    this.vmS.StockAdjustmentDetail.forEach(x=> {
      if (x.NewQuantity < x.OldQuantity) {
        let qtydif = x.OldQuantity - x.NewQuantity;
        if (qtydif > x.Quantity) {
          x.IsDisable = true;
          this.IsDisable = true;
        }else{
          x.IsDisable = false;
          this.IsDisable = false;
        }
      }
    });
    let disableObject = this.vmS.StockAdjustmentDetail.find(x=> x.IsDisable == true);
    if (disableObject != undefined) {
      this.IsDisable = true;
    }else{
      this.IsDisable = false;
    }
  }
  TotalStockAmount(){
    this.vmS.Amount = 0;
        this.vmS.LastAmount = 0;
    this.vmS.StockAdjustmentDetail.forEach(e => {
        this.vmS.Amount += e.NewAmount;
        this.vmS.LastAmount += e.TotalAmount;
    });
  }
  // lockItemRecord(x){
  //   debugger;
  //   x.IsLock =!x.IsLock;
  //   if(x.IsLock){
  //     this.StockChange(x);
      
  //   }
  //   else{
  //     if(x.NewQuantity != x.OldQuantity){
  //       let diff = 0;
  //       let NewAmount = x.CostofGoodSold * x.NewQuantity;
  //       if(NewAmount > x.NewAmount){
  //         diff = NewAmount - x.NewAmount;
  //         this.vmS.Amount += diff;
  //       }else{
  //         diff = x.NewAmount - NewAmount;
  //         this.vmS.Amount -= diff;
  //       }
  //       x.NewAmount = x.CostofGoodSold * x.NewQuantity;
  //       this.vmS.Amount -= x.NewAmount;
  //       this.vmS.LastAmount -= x.TotalAmount;
  //     }
  //     else{
  //       let diff = 0;
  //         let NewAmount = x.CostofGoodSold * x.NewQuantity;
  //         if(NewAmount > x.NewAmount){
  //           diff = NewAmount - x.NewAmount;
  //           this.vmS.Amount += diff;
  //         }else{
  //           diff = x.NewAmount - NewAmount;
  //           this.vmS.Amount -= diff;
  //         }
  //         x.NewAmount = x.CostofGoodSold * x.NewQuantity;
  //         this.vmS.Amount -= x.NewAmount;
  //         this.vmS.LastAmount -= x.TotalAmount;
  //     }
      
  //   }
  // }
  removeFromList(e,index){
    this.selectedSpareParts = [];
    let selectedSaprePartsList: any = [];
    let i = this.vmS.StockAdjustmentDetail.find(
      (x) => x.FK_G_Item_Id.Id === e.FK_G_Item_Id.Id
    );
    if (i !== undefined) {
      this.vmS.StockAdjustmentDetail.splice(this.vmS.StockAdjustmentDetail.indexOf(i), 1);
    }
    this.vmS.StockAdjustmentDetail.forEach((x) => {
      let stockadjustmentDetail = new StockAdjustmentDetailViewModel();
      stockadjustmentDetail.Id = 0;
      stockadjustmentDetail.FK_G_Item_Id = new ItemViewModel(e.FK_G_Item_Id.Id,e.FK_G_Item_Id.Name);
      stockadjustmentDetail.Name =e.FK_G_Item_Id.Name;
      stockadjustmentDetail.NewQuantity = e.NewQuantity;
      stockadjustmentDetail.Quantity = e.Quantity;
      stockadjustmentDetail.OldQuantity = e.OldQuantity;
      stockadjustmentDetail.CostofGoodSold = e.CostofGoodSold;
      stockadjustmentDetail.NewAmount = e.NewAmount;
      stockadjustmentDetail.TotalAmount = e.TotalAmount;
      selectedSaprePartsList.push(stockadjustmentDetail);
      console.log('selected list',selectedSaprePartsList);
    });
    this.selectedSpareParts = selectedSaprePartsList;
    // this.vmS.StockAdjustmentDetail.splice(i,1);
    // this.selectedSpareParts.splice(i,1);
    this.TotalStockAmount();
  }
}
