import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { GPackagesViewModel } from 'src/app/viewmodels/g-packages/GPackagesViewModel';
import { G_ComplainsSuggestionsViewModel } from 'src/app/viewmodels/g-settings/G_ComplainsSuggestionsViewModel';
import { DefineAccountviewModel } from 'src/app/viewmodels/g-customer/DefineAccountviewModel';
import { BsModalRef, BsModalService, AlertComponent } from 'ngx-bootstrap';
import { GVechileViewModel } from 'src/app/viewmodels/g-vehicle/GVechileViewModel';
import { GItemDefiningViewModel } from 'src/app/viewmodels/g-item/GItemDefiningViewModel';
import { G_SaleMasterViewModel } from 'src/app/viewmodels/g-sale/G_SaleMasterViewModel';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ActivatedRoute, Router } from '@angular/router';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { G_TagsViewModel } from 'src/app/viewmodels/g-settings/G_TagsViewModel';
import { ItemViewModel } from 'src/app/viewmodels/ItemViewModel';
import { SaleDetailViewModel } from 'src/app/viewmodels/g-sale/SaleDetailViewModel';
import { GPackageItemViewModel } from 'src/app/viewmodels/g-packages/GPackageItemViewModel';
import { ThrowStmt } from '@angular/compiler';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import Swal from 'sweetalert2';
import { Raw } from 'src/app/viewmodels/Raw';
import { E_G_EPSStatusType } from 'src/app/viewmodels/g-purchase/E_G_EPSStatusType';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-prepare-invoice',
  templateUrl: './prepare-invoice.component.html',
  styleUrls: ['./prepare-invoice.component.scss']
})
export class PrepareInvoiceComponent implements OnInit {
  public modalRef: BsModalRef;
  public bsmRef: BsModalRef;
  disabled = true;
  @Input() public vmC: G_ComplainsSuggestionsViewModel;
  @Input() public vmD: DefineAccountviewModel;
  @Input() public vmP: GPackagesViewModel;
  @Input() public modal_title: any;
  @Output() onSubmitted = new EventEmitter<any>();  public component_Title = 'Sale';
  public vehicleDetail: any = [];
  public complaintsType: any = [];
  public ccomplaintsType: 'complaintsType';
  public packageTypes: any = [];
  public cvehicleDetail = 'vehicleDetail';
  public selectedvehicleDetail: any = [];
  public itemVm: GVechileViewModel = new GVechileViewModel();
  public sparePartVm: GItemDefiningViewModel = new GItemDefiningViewModel();
  public vm: G_SaleMasterViewModel = new G_SaleMasterViewModel();
  public saleDetailVM: any = [];
  public services: any = [];
  public selectedServices: any = [];
  public cpackageTypes = 'packageTypes';
  public items: any = [];
  public brands: any = [];
  public selectedBrands: any = [];
  public models: any = [];
  public selectedModels: any = [];
  public citems: any = 'items';
  public cbrands: any = 'brands';
  public cmodels: any = 'models';
  public spareParts: any = [];
  public selectedSpareParts: any = [];
  public selectedPacakgeTypes: any = [];
  public tags: any = [];
  public selectedTagList: any;
  public selectedsaleDetail: any[];
  public saleDetail: any[];
  public csaleDetail: any[];
  public DisableSave: boolean = false ;
  public brandsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'Id',
    textField: 'Name',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 4,
    allowSearchFilter: true
  };
  public modelsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'Id',
    textField: 'Name',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 4,
    allowSearchFilter: true
  };
  public itemsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'Id',
    textField: 'Name',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };

  public itemTypes = [];
  public ssItemVm = new GItemDefiningViewModel();
  public defaultDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'Id',
    textField: 'RegistraionNumber',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };

  submitted: boolean = false;
  public calingUrl: string;
  public urlId: number;
  constructor(
    private route:ActivatedRoute,
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    private _router: Router,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    let date = new Date();
    document.getElementById("date").innerHTML = date.toLocaleDateString();
    this.vm = new G_SaleMasterViewModel();
    this.saleDetailVM = new SaleDetailViewModel();
    this.vm.DriverName = "";
    this.vm.DriverPhone = "";
    this.vm.PaymentType = 1;
    this.vm.Fk_G_Customer_Id = new ItemViewModel(0,"");
    this.vm.Fk_G_StatusType_Id = new ItemViewModel(5,"");
    this.vm.Fk_G_Vechile_Id = new ItemViewModel(0,"");
    this.vm.FK_Brand = new ItemViewModel(0,"");
    this.vm.FK_Model = new ItemViewModel(0,"");
    //console.log(this.vm);
    this.calingUrl = this._router.url;
    this.route.queryParams.subscribe(params => {
    this.urlId = params['id'];
    });
    // this._jq.showSpinner(true);
    this.spinner.show();
    this._uow.saleService.GetById(this.urlId).subscribe((res: any) =>{
      console.log(res);
      this.vm = res;
      console.log(res);
      // this._jq.showSpinner();
      setTimeout(() => {
        /** spinner ends after 5 seconds */
        this.spinner.hide();
      }, 3000);
    },(err)=>{
      console.log(err);
      // this._jq.showSpinner();
      setTimeout(() => {
        /** spinner ends after 5 seconds */
        this.spinner.hide();
      }, 3000);
    })
    if(this.vm.SalesTagsList !== undefined && this.vm.SalesTagsList.length > 0){
      this.vm.SalesTagsList.forEach(x => {
        this.selectedTagList.push(new ItemViewModel(x.Id,x.Name));
      });
    }
    if(this.vm.SaleDetails !== undefined && this.vm.SaleDetails.length > 0){
      this.vm.SaleDetails.forEach(x => {
        this.saleDetail.push(new ItemViewModel(x.FK_G_Item_Id.Id, x.FK_G_Item_Id.Name));
      });
    }

    // this.itemVm = new GVechileViewModel();
    // let id = this.route.snapshot.params['id'];
    // this._uow.vehicleService.GetById(id).subscribe((res: any)=>{
    //   this.itemVm = res;
    //   this.itemVm.FK_G_Model = new ItemViewModel(res.FK_G_Model.Id,res.FK_G_Model.Name);
    //   this.itemVm.Fk_G_Customer_Id = new ItemViewModel(res.Fk_G_Customer_Id.Id,res.Fk_G_Customer_Id.Name);
    //   this.itemVm.FK_TagId = new ItemViewModel(res.FK_TagId.Id, res.FK_TagId.Name);
    //  // this.vm.FK_ClientId = new ItemViewModel(res.FK_ClientId.Id, res.FK_ClientId.Name);
    //   console.log(res);
    // },(err)=>{
    //   console.log(err);
    // })
    // if (this.itemVm.FK_Tag_List !== undefined && this.itemVm.FK_Tag_List.length > 0) {
    //   this.itemVm.FK_Tag_List.forEach(x => {
    //     this.selectedTagList.push(new ItemViewModel(x.Id, x.Name));
    //   });
    // }
    // if (this.vm == undefined) {
    //   this.vm = new G_SaleMasterViewModel();
    //   this.saleDetail = [];
    //   this.selectedsaleDetail = [];
    // } else {

    // }
    //this.getTags();
      this.DisableSave = true;
  }

  getTags() {
    this._uow.tagsService.GetList().subscribe((res: any) => { this.tags = res; }, (err) => {
      console.log(err);
      this._jq.showSpinner();
    });
  }

  onButtonClick() {
    this._jq.showSpinner(true);
    this._router.navigate(['/repair-invoice', {Id: this.vm.Id}]);
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this._uow.modalServiceEx.show(template);
    this.modalRef.setClass('modal-lg');
  }


  saleready(){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to revert this !',
      showCancelButton: true,
      confirmButtonText: 'Yes !',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value)
      {
        let obj = this.itemVm;
        console.log();

        let raw = new Raw();
        raw.Id = this.vm.Id;
        //this.vm.Fk_G_StatusType_Id = new ItemViewModel(7,"SaleReady")
        this.spinner.show();
        this._uow.saleService
        .PostReady(raw)
        .toPromise().then((res: any) =>
        {
          console.log(res);
        // this._jq.showSpinner();
        this.spinner.hide();
          this.vm.Fk_G_StatusType_Id = res.Fk_G_StatusType_Id;
          this.submitted = true;
          this.onSubmitted.emit(this.submitted);
          Swal.fire(
            'Update!',
            'Status Updated!',
            'success'
          )
          //this.bsmRef.hide();
        })
        .catch((error) =>
        {
          Swal.fire(
            'Error!',
            'Status Not Updated!',
            'warning'
          )
          this._uow.logger.log(`Promise rejected with ${JSON.stringify(error)}`);
          this.onSubmitted.emit(false);
        // this._jq.showSpinner();
          this.spinner.hide();
        })
    }
    else if (result.dismiss === Swal.DismissReason.cancel)
    {
      Swal.fire(
        'Cancelled',
        'You pressed the Cancel button',
        'error'
      );
      this._jq.showSpinner();
    }

  })
}



  onItemSubmitted(e) {
    if (e == true) {
      this.vm = new G_SaleMasterViewModel();
      this.ngOnInit();
      this._jq.showSpinner(false);
    }
  }

  getSpareParts(e){
    this._uow.logger.log(e);

    let item = this.saleDetail.find(x => x.Id === e.id);
    if (item !== undefined) {
      // let a =  new GPackageItemViewModel();
      // a.Quantity = 1;
      // a.Fk_G_SSItemDefining_Id = e;
      // a.DescountPercentage = 0;
      // a.SalePrice = item.SalePrice;
      // a.Amount = 0;
      // a.Amount = item.SalePrice;
      item.SaleDetails.forEach(x => {

        if (x.FK_G_Item_Type_Id.Id === 18) {
          let selectedparts = this.spareParts.find(y => y.Id === x.Fk_G_SSItemDefining_Id.Id);

          if (selectedparts !== undefined) {
            let i = new SaleDetailViewModel();
            i.Quantity = 1;
            i.FK_G_Item_Id = new ItemViewModel(selectedparts.Id, selectedparts.Name);
            i.FK_G_Item_Type_Id = new ItemViewModel(selectedparts.Id, selectedparts.Name);
            // i.AvalibelQuantity = item.CurrentStock;
            // i.Quantity = item.CurrentStock;
            i.Item_Code = selectedparts.Code;
            i.Item_Name = item.Name;
            i.PurchasePrice = x.SalePrice;
            i.SalePrice = x.SalePrice;
            i.Item_Type = selectedparts.FK_G_ItemType_Id.Name;
            i.VatAmount = 0;
            i.VAT_Value = 0;
            //this.vm.SaleDetails.push(i);
            let a =  new GPackageItemViewModel();
            a.Quantity = 1;
            a.Fk_G_SSItemDefining_Id = e;
            a.DescountPercentage = x.DescountPercentage;
            a.SalePrice = x.SalePrice;
            a.Amount = 0;
            a.Amount = x.SalePrice;
            a.DiscountAmount =  0;
            //this.vmP.SpareParts.push(a);
          }

        }

        if (x.FK_G_Item_Type_Id.Id === 19) {
          let selectedservices = this.services.find(y => y.Id === x.Fk_G_SSItemDefining_Id.Id);
          if (selectedservices !== undefined) {
            let i = new SaleDetailViewModel();

            i.FK_G_Item_Id = new ItemViewModel(item.Id, item.Name);
            i.FK_G_Item_Type_Id = new ItemViewModel(selectedservices.Id, selectedservices.Name);
            //i.AvalibelQuantity = item.CurrentStock;
            i.Quantity = 1;
            i.Item_Code = selectedservices.Code;
            i.Item_Name = item.Name;
            i.PurchasePrice = x.SalePrice;
            i.SalePrice = x.SalePrice;
            i.Item_Type = selectedservices.FK_G_ItemType_Id.Name;
            i.VatAmount = 0;
            i.VAT_Value = 0;
            //this.vm.SaleDetails.push(i);
            let a =  new GPackageItemViewModel();
            a.Quantity = 1;
            a.Fk_G_SSItemDefining_Id = e;
            a.DescountPercentage = x.DescountPercentage;
            a.SalePrice = x.SalePrice;
            a.Amount = 0;
            a.Amount = x.SalePrice;
            //this.vmP.Services.push(a);
          }

        }
      });
       //this.vmP.SpareParts.push(a);
      }
  }


}
