import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { DefineAccountviewModel } from 'src/app/viewmodels/g-customer/DefineAccountviewModel';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { ItemViewModel } from 'src/app/viewmodels/ItemViewModel';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-supplier-form',
  templateUrl: './supplier-form.component.html',
  styleUrls: ['./supplier-form.component.scss']
})
export class SupplierFormComponent implements OnInit {
  @Input() public vm: DefineAccountviewModel;
  @Input() public modal_title: any;
  @Input() public bsmRef: BsModalRef;
  @Output() onSubmitted = new EventEmitter<any>();
  private submitted = false;
  modalRefLv1: BsModalRef;
  public PhoneCodes: any = [];
  public tags: any = [];
  constructor(
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    if (this.vm == undefined) {
      this.vm = new DefineAccountviewModel();
      this._uow.accountsService.GetCode().subscribe((res: any) => {
        this.vm.Acc_Code = res.code;
      }, (err) => console.log(err));
    }
    if (this.modal_title == undefined) {
      this.modal_title = 'Supplier';
    }
    this.getTags();
    this.getPhoneCode();
  }
  openModal(template: TemplateRef<any>, lvl: number = 1) {
    if (lvl == 1) {
      this.modalRefLv1 = this._modalService.show(template);
    }
  }
  getTags() {
    this._uow.tagsService.GetList().subscribe((res: any) => { this.tags = res; }, (err) => console.log(err));
  }
  getPhoneCode(){
    this._uow.phoneCodeService.GetList().subscribe((res: any) => { this.PhoneCodes = res; }, (err) => console.log(err));
  }
  saveOrUpdate() {
    // this._jq.showSpinner(true);
    this.spinner.show();
    this.vm.Fk_PreHead = new ItemViewModel(0, 'Liabilities');
    this.vm.Fk_Head1 = new ItemViewModel(0, 'Short term liabilities');
    this.vm.Fk_Head2 = new ItemViewModel(0, 'Suppliers');
    this.vm.Fk_Natuer = new ItemViewModel(0, 'Supplier');
    this.vm.G_SC = 'Supplier';
    this.vm.CP_Cell = this.vm.Country_Code +"-"+  this.vm.CP_Cell;
    this._uow.supplierService
      .Post(this.vm)
      .toPromise().then((res: any) => {
        console.log(res);
      // this._jq.showSpinner();
        this.submitted = true;
        this.bsmRef.hide();
        this.onSubmitted.emit(this.submitted);
        this.spinner.hide();
        Swal.fire(
          'Created!',
          'Supplier Created/Updated!',
          'success'
        )
      })
      .catch((error) => {
        Swal.fire(
          'Failure!',
          'Supplier Not Updated!',
          'error'
        )
        console.log(`Promise rejected with ${JSON.stringify(error)}`);
        this.onSubmitted.emit(this.submitted);
      // this._jq.showSpinner();
      this.spinner.hide();
      });
  }

  onItemSubmitted(e) {
    if (e == true) {
      this.getTags();
    }
  }

  // random unwanted fucks
  public toggleState = false;
  toggleVisbility() {
    this.toggleState = !this.toggleState;
  }
}
