import { Injectable } from '@angular/core';
import { EndpointConfig } from '../../common/EndpointConfig';
import { HttpClient } from '@angular/common/http';
import { G_PurchaseMasterViewModel } from 'src/app/viewmodels/g-purchase/G_PurchaseMasterViewModel';
import { E_G_EPSStatusType } from 'src/app/viewmodels/g-purchase/E_G_EPSStatusType';
import { Paginator } from 'src/app/viewmodels/paginator';
import { DefaultService } from '../../common/default.service';
import { ItemViewModel } from 'src/app/viewmodels/ItemViewModel';
import { IHttpService } from '../../common/IHttpService';

@Injectable({
  providedIn: 'root'
})
export class PurchaseOrderService  implements IHttpService<G_PurchaseMasterViewModel> {
  private _ep: EndpointConfig;
  constructor(private _client: HttpClient, private _defaultService: DefaultService) {
    this._ep = new EndpointConfig('GPurchase');
  }
  ValidatePurchsaeRefId() {
    return this._client.post(this._ep.getEndpointMethod('ValidatePurchsaeRefId'), { Id: null });
  }
  GetList() {
    let pg = new Paginator(0, 50, null, 0);
    pg.AccId = E_G_EPSStatusType.Purchase_Order;
    return this._client.post(this._ep.getEndpointMethod('GetG_PurchaseList'), pg);
  }
  GetPagedResult(start = 0, pagesize = 20, flag = '', sortIndex, SortBy) {
    let pg = new Paginator(start, pagesize, flag, sortIndex, SortBy);
    pg.Search = null;
    pg.SortBy = SortBy;
    pg.AccId = E_G_EPSStatusType.Purchase_Order;
    return this._client.post(this._ep.getEndpointMethod('GetG_PurchaseList'), pg);
  }
  GetPagedBySearch(searchTerm) {
    let pager = new Paginator(0, 50, null, 0);
    pager.AccId = E_G_EPSStatusType.Purchase_Order;
    pager.Search = searchTerm;
    return this._client.post(this._ep.getEndpointMethod('GetG_PurchaseList'), pager);
  }
  GetById(id: any) {
    return this._client.post(this._ep.getEndpointMethod('GetG_PurchaseById'), { Id: id });
  }
  Post(obj: G_PurchaseMasterViewModel) {
    this._defaultService.dateTransFuckDatesInObject(obj);
    obj.Fk_G_StatusType_Id = new ItemViewModel(E_G_EPSStatusType.Purchase_Order);
    let fd = new FormData();
    fd.append('Obj',JSON.stringify(obj));
    return this._client.post(this._ep.getEndpointMethod('SaveG_Purchase'), fd);
  }

  Put(obj: G_PurchaseMasterViewModel) {
    this._defaultService.dateTransFuckDatesInObject(obj);
    obj.Fk_G_StatusType_Id = new ItemViewModel(E_G_EPSStatusType.Purchase_Order);
    return this._client.post(this._ep.getEndpointMethod('SaveG_Purchase'), obj);
  }

  Delete(obj: G_PurchaseMasterViewModel) {
    obj.Fk_G_StatusType_Id = new ItemViewModel(E_G_EPSStatusType.Purchase_Order);
    return this._client.post(this._ep.getEndpointMethod('DeleteG_Purchase'), obj);
  }
}
