import { Component, OnInit, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { GPackagesViewModel } from 'src/app/viewmodels/g-packages/GPackagesViewModel';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { GPackagesModelViewModel } from 'src/app/viewmodels/g-packages/GPackagesModelViewModel';
import { ItemViewModel } from 'src/app/viewmodels/ItemViewModel';
import { GPackageItemViewModel } from 'src/app/viewmodels/g-packages/GPackageItemViewModel';
import { GPackageSSItemViewModel } from 'src/app/viewmodels/g-packages/GPackageSSItemViewModel';
import { GItemDefiningViewModel } from 'src/app/viewmodels/g-item/GItemDefiningViewModel';
import { of } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-package-form',
  templateUrl: './package-form.component.html',
  styleUrls: ['./package-form.component.scss']
})
export class PackageFormComponent implements OnInit {

  @Input() public vm: GPackagesViewModel;
  @Input() public modal_title: any;
  @Input() public bsmRef: BsModalRef;
  @Output() onSubmitted = new EventEmitter<any>();
  private submitted = false;
  public IsDublicate: boolean = false;
  modalRef: BsModalRef;

  public cpackageTypes = 'packageTypes';
  public packageTypes: any = [];

  public cbrands: any = 'brands';
  public brands: any = [];

  public cmodels: any = 'models';
  public models: any = [];

  public citems: any = 'items';
  public items: any = [];

  public spareParts: any = [];
  public selectedSpareParts: any = [];

  public services: any = [];
  public selectedServices: any = [];

  public selectedModels: any = [];
  public selectedBrands: any = [];

  public brandsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'Id',
    textField: 'Name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 4,
    allowSearchFilter: true
  };
  public modelsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'Id',
    textField: 'Name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 4,
    allowSearchFilter: true
  };
  public itemsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'Id',
    textField: 'Name',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
  name = 'GenericModel';
  id = 'Id';
  checked = false;
  disabled = false;
  label = 'Toggle On/Off';
  labelledby = 'Some Other Text';
  constructor(
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.vm.IsGenericModel=false;
    if (this.vm == undefined) {
      this.vm = new GPackagesViewModel();
      this.vm.Services = [];
      this.vm.SpareParts = [];
    } else {

      if (this.vm.Services !== undefined && this.vm.Services.length > 0) {
        this.vm.Services.forEach(x => {
          this.selectedServices.push(new ItemViewModel(x.Fk_G_SSItemDefining_Id.Id, x.Fk_G_SSItemDefining_Id.Name));
        });
      }
      if (this.vm.SpareParts !== undefined && this.vm.SpareParts.length > 0) {
        this.vm.SpareParts.forEach(x => {
          this.selectedSpareParts.push(new ItemViewModel(x.Fk_G_SSItemDefining_Id.Id, x.Fk_G_SSItemDefining_Id.Name));
        });
      }
      if (this.vm.GPackagesModel !== undefined && this.vm.GPackagesModel.length > 0) {
        this.vm.GPackagesModel.forEach(x => {
          if (x.Fk_G_Brands !== undefined) {
            this.selectedBrands.push(new ItemViewModel(x.Fk_G_Brands.Id, x.Fk_G_Brands.Name));
          }
          if (x.Fk_G_Models !== undefined) {
            this.selectedModels.push(new ItemViewModel(x.Fk_G_Models.Id, x.Fk_G_Models.Name));
          }
          this.getModelsForBrands(null);
        });
      }
    }
    if (this.modal_title == undefined) {
      this.modal_title = 'Package';
    }
    this.spinner.show();
    this.getPackageTypes();
    this.getBrands();
    this.getItems();
    this.getSparePartsAndServices();
    this.getItemtypes();
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 2000);
  }

  onChange(e) {
    debugger
    if(e==true){
      this.vm.IsGenericModel = true;
    }
    if(e==false){
      this.vm.IsGenericModel = false;
    }
}

  getPackageTypes() {
    this._uow.packageTypeService.GetList().subscribe((res: any) => {
      this.packageTypes = res;
      this._uow.logger.log(res);

    }, (error) => {
      this._uow.logger.log(error);
      this.packageTypes = [];
    });
  }

  getBrands() {
    this._uow.brandService.GetList().subscribe((res: any) => {
      this.brands = res;
    }, (err) => {
      this.brands = [];
      this._uow.logger.log(err);
    });
  }

  public itemTypes = [];
  public ssItemVm = new GItemDefiningViewModel();
  getItemtypes() {
    this._uow.itemTypeService.GetList().subscribe((res: any) => { this.itemTypes = res; }, (error) => { this._uow.logger.log(error); });
  }
  getItems() {
    this._uow.itemService.GetList().subscribe((res: any) => {
      this.items = res.DtlList;
      this._uow.logger.log(res.DtlList);

    }, (err) => {
      this._uow.logger.log(err);
      this.items = [];
    });
  }

  getSparePartsAndServices() {
    //'Spare-Parts'
    this._uow.itemService.GetSSItemDefiningByItemType('Spare-Parts').subscribe((res: any) => {
      // this._uow.logger.log(res);
      this.spareParts = res;
      // this._uow.logger.log(`spare parts ${JSON.stringify(res)}`);

    }, (err) => {
      this._uow.logger.log(err);
      this.spareParts = [];
    });
    // 'Services'
    this._uow.itemService.GetSSItemDefiningByItemType('Services').subscribe((res: any) => {
      this.services = res;
      // this._uow.logger.log(`services ${res}`);
    }, (err) => {
      this._uow.logger.log(err);
      this.services = [];
    });
  }

  getModelsForBrands(e) {
    var ids = [];
    this.selectedBrands.forEach(b => {
      ids.push(b.Id);
    });
    this._uow.modelService.GetModelsListByBrands(ids).subscribe((res: any) => {
      this.models = res;
      if (this.selectedModels.length > 0) {
        this.selectedModels.forEach(m => {
          let f = this.models.find(x => x.Id == m.Id);
          this._uow.logger.log(m);
          if (f == undefined) {
            this.onModelSelected(m);
          }
        });
      }
      this._uow.logger.log(this.models);
    }, (error) => {
      this._uow.logger.log(error);
    });
  }
  onModelSelected(e) {
    this._uow.logger.log(e.Id);
    // let model = this.models.find(x => x.Id == e.Id);
    // if (model !== undefined) {
    //   this.vm.GItemModels.push(model);
    // }
  }

  saveOrUpdate() {
    // this._jq.showSpinner(true);
    this.vm.GPackagesItem = [];
    this.vm.GPackagesModel = [];
    this._uow.logger.log(this.models);
    this._uow.logger.log(this.items);
    if (this.selectedModels !== undefined && this.selectedModels.length > 0) {
      this.selectedModels.forEach(m => {
        let model = this.models.find(x => x.Id == m.Id);
        this._uow.logger.log(model);
        if (model !== undefined) {
          let mi = new GPackagesModelViewModel();
          mi.Id = model.Id;
          mi.Fk_G_Brands = new ItemViewModel(model.Fk_G_Brand_Id.Id, model.Fk_G_Brand_Id.Name);
          mi.Fk_G_Models = new ItemViewModel(model.Id, model.Name);
          this.vm.GPackagesModel.push(mi);
        }
      });
    }

    if (this.vm.SpareParts !== undefined && this.vm.SpareParts.length > 0) {
      this.vm.SpareParts.forEach(x => {
        this.vm.GPackagesItem.push(x);
      });
    }
    if (this.vm.Services !== undefined && this.vm.Services.length > 0) {
      this.vm.Services.forEach(x => {
        this.vm.GPackagesItem.push(x);
      });
    }


    // if (this.selectedSpareParts !== undefined && this.selectedSpareParts.length > 0) {
    //   this.selectedSpareParts.forEach(m => {
    //     let model = this.items.find(x => x.Id == m.Id);
    //     this._uow.logger.log(model);
    //     if (model !== undefined) {
    //       let mi = new GPackageItemViewModel();
    //       mi.Id = model.Id;
    //       // mi.FK_G_ItemType_Id = new ItemViewModel(model.FK_G_ItemType_Id.Id, model.FK_G_ItemType_Id.Name);
    //       // mi.Fk_G_SSItemDefining_Id = new ItemViewModel(model.Fk_G_SSCategory_Id.Id, model.Fk_G_SSCategory_Id.Name);
    //       this.vm.GPackagesItem.push(mi);
    //     }
    //   });
    // }
    this.spinner.show();
    this._uow.packageService
      .Post(this.vm)
      .toPromise().then((res: any) => {
        this._uow.logger.log(res);
        this.submitted = true;
        this.bsmRef.hide();
        this.onSubmitted.emit(this.submitted);
        this.spinner.hide();
        Swal.fire(
          'Created!',
          'Package Created/Updated!',
          'success'
        )
      })
      .catch((error) => {
        Swal.fire(
          'Failure!',
          'Package Not Updated!',
          'error'
        )
        this._uow.logger.log(`Promise rejected with ${JSON.stringify(error)}`);
        this.onSubmitted.emit(this.submitted);
      // this._jq.showSpinner();
      this.spinner.hide();
      });
  }
  compareFn(optionOne, optionTwo): boolean {
    if (optionOne !== undefined && optionTwo !== undefined) {
      return optionOne.Id === optionTwo.Id;
    }
  }


  quantityChange(e, i?) {
    e.Amount = e.Quantity * e.Amount;
    // if (e.DescountPercentage > 0) {
    //   e.Amount = (e.Amount - ((e.DescountPercentage / 100) * e.Amount));
    // }
    this.calculateTotals();
  }
  discountChange(e, i?) {
    debugger
    let discountAMmount = ((e.DescountPercentage / 100) * e.SalePrice);
    e.Amount = (e.SalePrice - discountAMmount);
    this.calculateTotals();
    // if (e.DescountPercentage > 0) {
    // }
  }

  amountChange(e, i?) {
    this.discountChange(e)
    //this.calculateTotals();
  }
  // spare parts
  onSelectSparePart(e) {
    this._uow.logger.log(e);
    let item = this.spareParts.find(x => x.Id === e.Id);
    if (item !== undefined) {
      let i = new GPackageItemViewModel();
      i.SalePrice = item.SalePrice;
      i.Quantity = 1;
      i.Fk_G_SSItemDefining_Id = e;
      i.DescountPercentage = 0;
      i.Amount = item.SalePrice;
      this.vm.SpareParts.push(i);
    }
    this.calculateTotals();
  }

  onDeSelectSparePart(e) {
    let i = this.vm.SpareParts.find(x => x.Fk_G_SSItemDefining_Id.Id === e.Id);
    if (i !== undefined) {
      this.vm.SpareParts.splice(this.vm.SpareParts.indexOf(i), 1);
    }
    this.calculateTotals();
  }

  onSelectService(e) {
    this._uow.logger.log(e);
    let item = this.services.find(x => x.Id == e.Id);
    if (item !== undefined) {
      let i = new GPackageItemViewModel();
      i.SalePrice = item.SalePrice;
      i.Quantity = 1;
      i.DescountPercentage = 0;
      // i.Amount = i.SalePrice;
      i.Amount = 0;
      i.Amount = item.SalePrice;
      i.Fk_G_SSItemDefining_Id = e;
      this.vm.Services.push(i);
    }
    this.calculateTotals();
  }
  onDeSelectService(e) {
    let i = this.vm.Services.find(x => x.Fk_G_SSItemDefining_Id.Id === e.Id);
    if (i !== undefined) {
      this.vm.Services.splice(this.vm.Services.indexOf(i), 1);
    }
    this.calculateTotals();
  }

  calculateTotals() {
    this.vm.DiscountAmount = 0;
    this.vm.ServiceAmount = 0;
    this.vm.PackageAmount = 0;
    this.vm.Services.forEach(x => {
      this.vm.ServiceAmount += x.Amount;
      if (x.DescountPercentage > 0) {
        this.vm.DiscountAmount += (x.SalePrice * (x.DescountPercentage / 100));
      }
    });

    this.vm.PartsAmount = 0;
    this.vm.SpareParts.forEach(x => {
      this.vm.PartsAmount += x.Amount;
      if (x.DescountPercentage > 0) {
        this.vm.DiscountAmount += (x.SalePrice * (x.DescountPercentage / 100));
      }
    });

    debugger

    this.vm.PackageAmount = this.vm.PartsAmount + this.vm.ServiceAmount;
  }

  onBrandSelected(e) {
    this.getModelsForBrands(e);
  }
  onBrandDeSelected(e) {
    this.getModelsForBrands(e);
  }
  // exteral coponents
  openModal(template: TemplateRef<any>, lvl: number = 1) {
    if (lvl == 1) {
      this.modalRef = this._uow.modalServiceEx.show(template);
    }
    // if (lvl == 2) {
    //   this.modalRefLv2 = this._modalService.show(template);
    // }
  }
  openModalLg(template: TemplateRef<any>, itemtype?) {
    this.ssItemVm = new GItemDefiningViewModel();
    if (itemtype == 1) {
      let i = this.itemTypes.find(x => x.Name == 'Spare-Parts');
      console.log(i)
      this.ssItemVm.FK_G_ItemType_Id = new ItemViewModel(i.Id, i.Name);
    }
    if (itemtype == 2) {
      let i = this.itemTypes.find(x => x.Name == 'Services');
      this.ssItemVm.FK_G_ItemType_Id = new ItemViewModel(i.Id, i.Name);
    }
    this.openModal(template);
    this.modalRef.setClass('modal-lg');
  }

  // extrnal components
  onItemSubmitted(e, mf) {
    this._uow.logger.log(e);
    if (e === true) {
      if (mf == this.cbrands) {
        this.getBrands();
      }
      if (mf == this.citems) {
        this.getItems();
      }
      if (mf == this.cpackageTypes) {
        this.getPackageTypes();
      }
      if (mf == this.cmodels) {
        this.getModelsForBrands(e);
      }
    }
  }
  checkTagDublication(tagName){
    debugger
    this._jq.showSpinner(true);
    this._uow.packageService.checkDublication(tagName).toPromise().then((res : any)=>{
      if (res === true) {
        this.IsDublicate = false;
      }else{
        this.IsDublicate = true;
      }
      this._jq.showSpinner(false);
    }).catch((error) => {
      console.log(`Promise rejected with ${JSON.stringify(error)}`);
      this.onSubmitted.emit(this.submitted);
      this._jq.showSpinner(false);
    });
  }

}
