import { ItemViewModel } from '../ItemViewModel';
import { G_Installment_View_Model } from './G_Installment_View_Model';

export class G_Bulk_Installment_View_Model {

  public TransactionDate : Date
  public FK_G_Customer_Id : ItemViewModel;
  public FK_G_CashAndBank_Id : ItemViewModel;
  public Remarks : string;
  public TotalInvoiceAmount : number;
  public PdfInvoiceList: string;
  public TotalPaidAmount : number;
  public TotalBalance : number;
  public TotalAmount : number;
  public FK_Client_Id : ItemViewModel;
  public BulkInstallments : G_Installment_View_Model[];
}
