import { ItemViewModel } from '../ItemViewModel';
import { PurchaseDetailViewModel } from './PurchaseDetailViewModel';

export class G_PurchaseMasterViewModel {
    public Id: number;
    public RefId: string;
    public Inv_Number: string;
    public Validity: number;
    public FK_Dabit_Acc: ItemViewModel;
    public FK_Sup_Cradit_Acc: ItemViewModel;
    public FK_Sup_Cradit_Acc_Email: ItemViewModel;
    public Fk_G_StatusType_Id: ItemViewModel;
    public Fk_Tag_Id: ItemViewModel;
    public Fk_Supplier: ItemViewModel;
    public FK_WareHouse_Id: ItemViewModel;
    public Remarks: string;
    public Staus: string;
    public Inv_Amount: number;
    public PaidAmount: number;
    public Vat_Value: number;
    public Vat_Amount: number;
    public IsPaymentDone: boolean;
    public OldPk: number;
    public PaymentType: number;
    public FK_ClientId: ItemViewModel;
    public IsEmailRequired: boolean;
    public TransactionDate: Date;
    public RequiredDate: Date;
    public CreatedBy: string;
    public Attachment: string;
    public PurchaseDetail: PurchaseDetailViewModel[];
    public AttachmentList: any[];
    public IsLinked: boolean = false;
}
