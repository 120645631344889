import { ItemViewModel } from 'src/app/viewmodels/ItemViewModel';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { WareHouseItemViewModel } from 'src/app/viewmodels/g-item/GItemDefiningViewModel';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-warehouse-items-form',
  templateUrl: './warehouse-items-form.component.html',
  styleUrls: ['./warehouse-items-form.component.scss']
})
export class WarehouseItemsFormComponent implements OnInit {
  @Input() public vmlist: Array<WareHouseItemViewModel> ;
  @Input() public FK_WareHouse_Id:ItemViewModel;
  @Input() public modal_title: any;
  @Input() public bsmRef: BsModalRef;
  // @Output() onSubmitted = new EventEmitter<any>();
  private submitted = false;
  public IsDublicate: boolean = false;
  modalRef: BsModalRef;
  public isAdd:boolean = false;
  public isRemain:boolean = false;
  public addedItems:Array<any>=[];
  public selectedIndex:number;
  constructor(
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    private spinner :NgxSpinnerService
  ) { }

  ngOnInit() {
    console.log(this.FK_WareHouse_Id);
    // if (this.vm == undefined) {
    //   this.vm = Array<WareHouseItemViewModel>
    // }
    if (this.modal_title == undefined) {
      this.modal_title = "Warehouse";
    }
  }
  saveList() {
    console.log(this.vmlist);
    // this._jq.showSpinner(true);
    this.spinner.show();
    this._uow.wareHouseItemService
      .PostWareHouseItems(this.addedItems)
      .toPromise().then((res: any) => {
        console.log(res);
        this.submitted = true;
        this.bsmRef.hide();
        // this.onSubmitted.emit(this.submitted);
        this.spinner.hide();
        Swal.fire(
          'Created!',
          'Ware house Items Created/Updated!',
          'success'
        )
      })
      .catch((error) => {
        this.spinner.hide();
        Swal.fire(
          'Failure!',
          'WareHouse items Not Updated!',
          'error'
        )
        console.log(`Promise rejected with ${JSON.stringify(error)}`);
        // this.onSubmitted.emit(this.submitted);
      });
  }
  addRowToList(item,index){
    item.IsLock =!item.IsLock;
    console.log(item);
    if(item.IsLock){
      this.addedItems.push(item);
    }
    else{
      this.addedItems.splice(index,1);
    }
    
  }
  onChanges(item){
    
    // this.isRemain =true
  }
}
