import { Injectable } from '@angular/core';
import * as $ from './../../assets/js/jquery.min.js';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class JqHelper {

  public initDataTable() {
    $('.datatable').DataTable({
      'retrieve': true,
      info: false,
      searching: false,
      language: {
        zeroRecords: " "
      }
    });
    // $('#datatable').DataTable();
  }
  public reinitDataTable(mdata) {
    // $('.datatable').rows.add(mdata).draw();
    $('#brandTable').DataTable({
      'retrieve': true,

    }).rows.add(mdata).draw();
    // columns: [{
    //   title: 'Name',
    //   data: 'Name'
    // }, {
    //   title: 'Actions'
    // }],
    // data: mdata
  }
  public initializeDataTable(){
    
      
      $(".datatable").DataTable({
        paging: false,
        info: false,
        searching: false,
        retrieve: true,
        language: {
          zeroRecords: " "
        },
        // //   processing:true,
        // columnDefs: [
        //   {
        //     targets: [3, 5],
        //     orderable: false
        //   }
        // ]
      });
    
  }
  public clearDataTable() {
    $('.datatable').DataTable().clear(true);
  }
  public destoryDataTable() {
    $('.datatable').DataTable().destroy(true);
  }
  public refreshDataTable() {
    this.destoryDataTable();
    this.initDataTable();
  }

  public hideSplashScreen() {
    setTimeout(() => {
      $('#status').fadeOut();
      $('#preloader').delay(350).fadeOut('slow');
      $('body').delay(350).css({
        'overflow': 'visible'
      });
    }, 300);
  }

  public showSpinner(opt = false) {
    if (opt == true) {
      $('.spinner').show();
    } else {
      $('.spinner').hide();
    }
  }

  public loginLoading(opt = false) {
    if (opt == true) {
      $('#loginBtn').delay(250).hide('slow');
      $('.spinner').delay(350).fadeIn('slow');

    } else {
      $('.spinner').delay(350).fadeOut('slow');
      $('#loginBtn').delay(1000).show('slow');
    }
  }
}
