export class Paginator {

    constructor(skip?: number, pageSize?: number, flag?: string, sortindex?: any, search?: string) {
        this.Skip = skip;
        this.PageSize = pageSize;
        this.Flag = flag;
        this.SortIndex = sortindex;
        this.Search = search;
    }

    public Skip?: number;
    public PageSize?: number;
    public Query?: string;
    public SPType?: number;
    public AccId?: number;
    public EmpId?: number;
    public ClientId?: number;
    public ExpHead?: string;
    public FromDate?: Date;
    public ToDate?: Date;
    public SortIndex?: number;
    public Search?: string;
    public SortByFieldName?: string;
    public SortBy?: string;
    public Flag?: string;
    public Flag2?: string;
}
