import { ItemViewModel } from '../ItemViewModel';

export class GItemSupplierViewModel {
    public Id: number;
    public Fk_Supplier_Id: ItemViewModel;
    public Fk_G_SSItemDefining_Id: ItemViewModel;
    public FK_ClientId: ItemViewModel;
    public IsService: boolean;
    public IsActive: boolean;
}
