import { Injectable } from '@angular/core';
import { IHttpService } from '../../common/IHttpService';
import { G_BrandsViewModel } from 'src/app/viewmodels/g-settings/G_BrandsViewModel';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { EndpointConfig } from '../../common/EndpointConfig';
import { AuthenticationService } from '../../auth/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class BrandService implements IHttpService<G_BrandsViewModel> {
  headers: HttpHeaders = new HttpHeaders();
  private _ep: EndpointConfig;
  constructor(private _client: HttpClient, private _authenticationService: AuthenticationService) {
    this._ep = new EndpointConfig('GSettings');
  }

  GetList() {
    return this._client.get(this._ep.getEndpointMethod('GetG_BrandList'));
  }

  checkDublication(code) {
    return this._client.post(this._ep.getEndpointMethod('CheckG_BrandDublication'), { Code: code });
  }

  GetById(id: any) {
    return this._client.post(this._ep.getEndpointMethod('GetG_BrandById'), { Id: id });
  }
  Post(obj: G_BrandsViewModel) {
    return this._client.post(this._ep.getEndpointMethod('SaveG_Brand'), obj);
  }
  Put(obj: G_BrandsViewModel) {
    return this._client.post(this._ep.getEndpointMethod('SaveG_Brand'), obj);
  }
  Delete(obj: G_BrandsViewModel) {
    return this._client.post(this._ep.getEndpointMethod('DeleteG_Brand'), obj);
  }

}
