import { Component, OnInit } from '@angular/core';
import * as $ from './../../../../assets/js/jquery.min.js';
import { JqHelper } from 'src/app/common/jq-helper.js';
declare var $: any;

@Component({
  selector: 'app-dash-layout',
  templateUrl: './dash-layout.component.html',
  styleUrls: ['./dash-layout.component.scss']
})
export class DashLayoutComponent implements OnInit {

  constructor(private _jq: JqHelper) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this._jq.hideSplashScreen();
  }

}
