import { Component, OnInit, TemplateRef } from '@angular/core';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { G_ModelsViewModel } from 'src/app/viewmodels/g-settings/G_ModelsViewModel';
import { G_FuelTypeViewModel } from 'src/app/viewmodels/g-settings/G_FuelTypeViewModel';
import { G_BrandsViewModel } from 'src/app/viewmodels/g-settings/G_BrandsViewModel';
import { ItemViewModel } from 'src/app/viewmodels/ItemViewModel';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-model',
  templateUrl: './model.component.html',
  styleUrls: ['./model.component.scss']
})
export class ModelComponent implements OnInit {
  modalRefLv1: BsModalRef;
  modalRefLv2: BsModalRef;
  public itemVm: G_ModelsViewModel = new G_ModelsViewModel();
  public vmListItems: any = [];

  public component_Title = "Vehicle Model";

  constructor(
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.getList();
  }


  openModal(template: TemplateRef<any>, lvl: number) {
    if (lvl == 1) {
      this.modalRefLv1 = this._modalService.show(template);
    }
    if (lvl == 2) {
      this.modalRefLv2 = this._modalService.show(template);
    }
  }

  ngAfterViewInit() {
    // this._jq.showSpinner();
  }

  getList() {
    // this._jq.showSpinner(true);
    this.spinner.show();
    this._uow.modelService.GetList().subscribe((res: any) => {
      this._jq.reinitDataTable(res);
      this.vmListItems = res;
      console.log(res);
      this.spinner.hide();
      // this._jq.showSpinner();
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    });
  }

  openSaveOrUpdateModel(template: TemplateRef<any>, model?) {
    if (model !== undefined) {
      this.itemVm = model;
      // this.itemVm.FK_G_FuelType_Id = new ItemViewModel(model.FK_G_FuelType_Id.Id, model.FK_G_FuelType_Id.Name);
      // this.itemVm.Fk_G_Brand_Id = new ItemViewModel(model.Fk_G_Brand_Id.Id, model.Fk_G_Brand_Id.Name);

    } else {
      this.itemVm = new G_ModelsViewModel();
    }
    this.openModal(template, 1);
  }



  // saveOrUpdate() {
  //   this._jq.showSpinner(true);
  //   this._uow.modelService.Post(this.itemVm).subscribe((res: any) => {
  //     this.getList();
  //     this.modalRefLv1.hide();
  //   }, (err) => {
  //     this._jq.showSpinner();
  //   });
  // }

  openDeleteModel(template: TemplateRef<any>, model) {
    this.openModal(template, 1);
    if (model !== undefined) {
      this.itemVm = model;
    }
  }

  conformDelete() {
    this._uow.modelService.Delete(this.itemVm).subscribe((res: any) => {
      this.getList();
      this.modalRefLv1.hide();
    }, (err) => {
      this._jq.showSpinner();
    });
  }

  // extrnal components
  onItemSubmitted(e) {
    console.log(e);
    if (e === true) {
      this.itemVm = new G_ModelsViewModel();
      this.getList();
    }
  }
}
