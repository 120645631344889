import { Injectable } from '@angular/core';
import { EndpointConfig } from '../../common/EndpointConfig';
import { HttpClient } from '@angular/common/http';
import { WareHouseItemViewModel } from 'src/app/viewmodels/g-item/GItemDefiningViewModel';

@Injectable({
  providedIn: 'root'
})
export class WarehouseItemsService {
  private _ep: EndpointConfig;
  constructor(private _client: HttpClient) {
    this._ep = new EndpointConfig('GSSItem');
    // this._wp= new EndpointConfig('GSSItem');
  }
  PostWareHouseItems(list:Array<WareHouseItemViewModel>) {
    return this._client.post(this._ep.getEndpointMethod('SaveG_WareHouseItemList'), list);
  }
  GetListItem(id) {
    return this._client.post(this._ep.getEndpointMethod('GetG_SparePartsListforWareHouse'),{Id:id});
  }
  GetListItemByWare(id) {
    return this._client.post(this._ep.getEndpointMethod('GetG_SparePartsItemListByWareHouse'),{Id:id});
  }
  GetG_WareHouseItemList(id) {
 
    return this._client.post(this._ep.getEndpointMethod('GetG_WareHouseItemList'),{Id:id});
  }
  SaveG_WareHouseItem(model) {
    return this._client.post(this._ep.getEndpointMethod('SaveG_WareHouseItem'),model);
  }
  SaveG_WareHouseItemByExcel(obj:any) {
    const formData = new FormData();
    formData.append("file", obj.file);
    formData.append("Obj", JSON.stringify(obj));
    return this._client.post(this._ep.getEndpointMethod('SaveG_WareHouseItemByExcel'),formData);
  }
  GetG_SparePartsListforWareHouseCreateExcel(id) {
 
    return this._client.post(this._ep.getEndpointMethod('GetG_SparePartsListforWareHouseCreateExcel'),{Id:id});
  }
}
