import { Injectable } from '@angular/core';
import { GVechileViewModel } from 'src/app/viewmodels/g-vehicle/GVechileViewModel';
import { AuthenticationService } from '../../auth/authentication.service';
import { HttpClient } from '@angular/common/http';
import { EndpointConfig } from '../../common/EndpointConfig';
import { IHttpService } from '../../common/IHttpService';
import { Paginator } from 'src/app/viewmodels/paginator';
import { ExportExcel } from 'src/app/viewmodels/ExportExcel';
import { VmhReportModel } from 'src/app/viewmodels/VMHReportModel';

@Injectable({
  providedIn: 'root'
})
export class VehicleService implements IHttpService<GVechileViewModel> {
  private _ep: EndpointConfig;
  constructor(private _client: HttpClient, private _authenticationService: AuthenticationService) {
    this._ep = new EndpointConfig('GCustomerVehicele');
  }

  GetList() {
    return this._client.post(this._ep.getEndpointMethod('G_VehiceleList') ,new Paginator(0, 50, null, 0));
  }
  GetPagedResult(start: number = 0, pageSize: number = 50, sortIndex: number = 0) {
    return this._client.post(this._ep.getEndpointMethod('GetG_VehiceleList'), new Paginator(start, pageSize, null, sortIndex));
  }
  GetPagedResultBySort(start = 0, pagesize = 20, flag = '', sortIndex, SortBy) {
    debugger
    let pager = new Paginator();
    pager.PageSize = pagesize;
    pager.Skip = start;
    pager.Flag = flag;
    pager.SortIndex = sortIndex;
    pager.SortBy = SortBy;
    return this._client.post(this._ep.getEndpointMethod('GetG_VehiceleList'), pager);
  }
  GetPagedBySearch(searchTerm) {
    let pager = new Paginator(0, 50, null, 0);
    pager.Search = searchTerm;
    return this._client.post(this._ep.getEndpointMethod('GetG_VehiceleList'), pager);
  }
  GetById(id: any) {
    return this._client.post(this._ep.getEndpointMethod('GetG_VehiceleById'), { Id: id });
  }
  Post(obj: GVechileViewModel) {
    return this._client.post(this._ep.getEndpointMethod('SaveG_Vehicele'), obj);
  }
  checkVehicleDublications(code, filter) {
    return this._client.post(this._ep.getEndpointMethod('CheckVehicleDublications'), { Code: code , Cid : filter});
  }
  checkChasiDublications(code) {
    return this._client.post(this._ep.getEndpointMethod('CheckChasiDublications'), { Code: code });
  }
  checkEngineDublications(code) {
    return this._client.post(this._ep.getEndpointMethod('CheckEngineDublications'), { Code: code });
  }
  Put(obj: GVechileViewModel) {
    return this._client.post(this._ep.getEndpointMethod('SaveG_Vehicele'), obj);
  }
  Delete(obj: GVechileViewModel) {
    return this._client.post(this._ep.getEndpointMethod('DeleteG_Vehicele'), obj);
  }

  ExportVehicle(obj: ExportExcel) {
    let fd = new FormData();
    fd.append('Obj',JSON.stringify(obj));
    return this._client.post(this._ep.getEndpointMethod('Vehicele_Export'), fd, {responseType: 'blob'});
  }

  GetVmhReport(obj: VmhReportModel) {
    debugger;
    return this._client.post(this._ep.getEndpointMethod('Get_VMHReport'), obj);
  }

}
