import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AuthenticationService } from '../services/auth/authentication.service';
import { CookieService } from '../services/common/cookie.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private _cookieService: CookieService, private _authSvc: AuthenticationService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let user = this._authSvc.getUser();
    if (user !== undefined && user !== null) {

      return true;
      // return this._authSvc.isUserStampValid(user).pipe(
      //   map((e: any) => {
      //     // console.log(e);
      //     if (e.IsStampValid == true) {
      //       return true;
      //     } else {
      //       this._authSvc.logout();
      //       return false;
      //     }
      //   }),
      //   catchError((err) => {
      //     this.router.navigate(['/login']);
      //     return of(false);
      //   })
      // );
    } else {
      // not logged in so redirect to login page with the return url
      let url = state.url;
      if (state.url == '/dashboard') {
        this.router.navigate(['/login']);
      } else {
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      }
      return false;
    }

  }
  // constructor(private router: Router, private _authSvc: AuthenticationService) { }

  // canActivate(
  //   next: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

  //     let user = this._authSvc.getUser();
  //     if (user !== undefined && user !== null) {

  //       return this._authSvc.isUserStampValid(user).pipe(
  //         map((e: any) => {
  //           console.log(e);

  //           if (e.IsStampValid == true) {
  //             return true;
  //           } else {
  //             this._authSvc.logout();
  //             return false;
  //           }
  //         }),
  //         catchError((err) => {
  //           this.router.navigate(['/account/login']);
  //           return of(false);
  //         })
  //       );
  //     } else {
  //       // not logged in so redirect to login page with the return url
  //       let url = state.url;
  //       if (state.url == '/dashboard') {
  //         this.router.navigate(['/account/login']);
  //       } else {
  //         this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
  //       }
  //       return false;
  //     }
  //   return true;
  // }

}
