import { StockListComponent } from './components/g-inventory/stock-list/stock-list.component';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DashLayoutComponent } from './components/_layout/dash-layout/dash-layout.component';
import { LoginComponent } from './components/login/login.component';
import { GarageSettingsComponent } from './components/g-settings/garage-settings/garage-settings.component';
import { FuleTypeComponent } from './components/g-settings/fule-type/fule-type.component';
import { BrandComponent } from './components/g-settings/brand/brand.component';
import { ModelComponent } from './components/g-settings/model/model.component';
import { AuthGuard } from './guards/auth.guard';
import { RouteConsts } from './common/route-consts';
import { ItemTypeComponent } from './components/g-settings/item-type/item-type.component';
import { ManufacturerComponent } from './components/g-settings/manufacturer/manufacturer.component';
import { StatusTypeComponent } from './components/g-settings/status-type/status-type.component';
import { SsCategoryComponent } from './components/g-settings/ss-category/ss-category.component';
import { TagsComponent } from './components/g-settings/tags/tags.component';
import { TagsTypeComponent } from './components/g-settings/tags-type/tags-type.component';
import { CustomerComponent } from './components/g-customer/customer/customer.component';
import { SupplierComponent } from './components/g-supplier/supplier/supplier.component';
import { VehicleComponent } from './components/g-vehicle/vehicle/vehicle.component';
import { ItemComponent } from './components/g-settings/item/item.component';
import { PackageTypeComponent } from './components/g-settings/package-type/package-type.component';
import { PackageComponent } from './components/g-packages/package/package.component';
import { PurchaseOrderComponent } from './components/g-purchase/purchase-order/purchase-order.component';
import { PurchaseRequsitionComponent } from './components/g-purchase/purchase-requsition/purchase-requsition.component';
import { PurchaseComponent } from './components/g-purchase/purchase/purchase.component';
import { UnitComponent } from './components/g-settings/unit/unit.component';
import { CountryService } from './services/api/g-settings/country.service';
import { ColorsComponent } from './components/g-settings/colors/colors.component';
import { CountryComponent } from './components/g-settings/country/country.component';
import { ComplainsAndSuggestionsComponent } from './components/g-settings/complains-and-suggestions/complains-and-suggestions.component';
import { SaleCompComponent } from './components/g-sale/sale-comp/sale-comp.component';
import { RepairOrderComponent } from './components/g-sale/repair-order/repair-order.component';
import { PrepareInvoiceComponent } from './components/g-sale/prepare-invoice/prepare-invoice.component';
import { RepairOrderListComponent } from './components/g-sale/repair-order/repair-order-list/repair-order-list.component';
import { RepairInvoiceComponent } from './components/g-sale/repair-invoice/repair-invoice.component';
import { RepairOrderPdfComponent } from './components/g-sale/repair-order/repair-order-pdf/repair-order-pdf.component';
import { WorkInProgessFormComponent } from './components/g-sale/repair-order/work-in-progess-form/work-in-progess-form.component';
import { from } from 'rxjs';
import { DirectSaleComponent } from './components/g-sale/direct-sale/direct-sale.component';
import { WipRepairOrdersComponent } from './components/g-sale/repair-order/wip-repair-orders/wip-repair-orders.component';
import { UpdatedInvoiceComponent } from './components/g-sale/repair-invoice/updated-invoice/updated-invoice.component';
import { PaymentStatusComponent } from './components/g-sale/payment-status/payment-status.component';
import { ReadyListComponent } from './components/g-sale/repair-order/ready-list/ready-list.component';
import { DirectSaleInvoiceComponent } from './components/g-sale/direct-sale/direct-sale-invoice/direct-sale-invoice.component';
import { DirectSaleListComponent } from './components/g-sale/direct-sale/direct-sale-list/direct-sale-list.component';
import { PurchasePdfInvoiceComponent } from './components/g-purchase/purchase/purchase-pdf-invoice/purchase-pdf-invoice.component';
import { EditRepairOrderComponent } from './components/g-sale/repair-order/edit-repair-order/edit-repair-order.component';
import { EditDirectSaleComponent } from './components/g-sale/direct-sale/edit-direct-sale/edit-direct-sale.component';
import { ItemstockreportComponent } from './components/Reporting/stockReport/itemstockreport/itemstockreport.component';
import { PurchaseDtlViewComponent } from './components/g-purchase/purchase/purchase-dtl-view/purchase-dtl-view.component';
import { DirectSaleDetailViewComponent } from './components/g-sale/direct-sale/direct-sale-detail-view/direct-sale-detail-view.component';
import { PurchaseInstallmentComponent } from './components/g-purchase/purchase/purchase-installment/purchase-installment.component';
import { PaymentStatusDetailViewComponent } from './components/g-sale/payment-status/payment-status-detail-view/payment-status-detail-view.component';
import { StockAdjustmentComponent } from './components/Reporting/stockReport/stock-adjustment/stock-adjustment.component';
import { StockAdjustmentListComponent } from './components/Reporting/stockReport/stock-adjustment-list/stock-adjustment-list.component';
import { BulkPaymentsComponent } from './components/g-sale/bulk-payments/bulk-payments.component';
import { BulkPaymentsListComponent } from './components/Reporting/bulk-payments-list/bulk-payments-list.component';
import { AggingReportComponent } from './components/Reporting/agging-report/agging-report.component';
import { SaleCompletedComponent } from './components/g-sale/sale-completed/sale-completed.component';
import { AllSalesListComponent } from './components/g-sale/all-sales-list/all-sales-list.component';
import { CustomerSalesInvoicesReportComponent } from './components/g-sale/customer-sales-invoices-report/customer-sales-invoices-report.component';
import { WarehouseComponent } from './components/g-settings/warehouse/warehouse.component';
import { WarehousestockreportComponent } from './compnents/Reporting/stockReport/warehousestockreport/warehousestockreport.component';
import { InventoryFlowReportComponent } from './components/Reporting/inventory-flow-report/inventory-flow-report.component';
import { SalesReportsByDateComponent } from './components/Reporting/sales-reports-by-date/sales-reports-by-date.component';
import { VmhReportComponent } from './components/Reporting/vmh-report/vmh-report.component';
const _routeConsts: RouteConsts = new RouteConsts();
const routes: Routes = [

  // dash board routes
  {
    path: ``,
    component: DashLayoutComponent,
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Dashboard' },
    children: [
      { path: ``, component: DashboardComponent, canActivate: [AuthGuard], pathMatch: 'full' },
      { path: `dashboard`, component: DashboardComponent, canActivate: [AuthGuard]},
      { path: `customer`, component: CustomerComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Customer' } },
      { path: `supplier`, component: SupplierComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Supplier' } },
      { path: `vehicle`, component: VehicleComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Vehicle' } },
      { path: `item`, component: ItemComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Item' } },
      { path: `purchase-requsition`, component: PurchaseRequsitionComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Purchase Requition' } },
      { path: `purchase-order`, component: PurchaseOrderComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Purchase Order' } },
      { path: `purchase`, component: PurchaseComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Purchase' } },
      { path: `purchase-dtl-view`, component: PurchaseDtlViewComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Purchase DTL View' } },
      { path: `purchase-installment`, component: PurchaseInstallmentComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Purchase Installment' }},
      { path: 'purchase-pdf-invoice', component: PurchasePdfInvoiceComponent, canActivate:[AuthGuard], data: { breadcrumb: 'Purchase Pdf Invoice' }},
      { path: 'sale', component: SaleCompComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Sale' }},
      { path: 'repair-order', component: RepairOrderComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Repair Order' }},
      { path: 'edit-repair-order', component: EditRepairOrderComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Edit Repair Order' }},
      { path: 'repair-order-list', component: RepairOrderListComponent, canActivate:[AuthGuard], data: { breadcrumb: 'Repair Order List' }},
      { path: 'ready-list', component: ReadyListComponent, canActivate:[AuthGuard], data: { breadcrumb: 'Ready List' }},
      { path: 'all-sales-list', component: AllSalesListComponent, canActivate:[AuthGuard], data: { breadcrumb: 'All Sales List' }},
      { path: 'prepare-invoice', component: PrepareInvoiceComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Prepare Invoice' }},
      { path: 'repair-invoice', component: RepairInvoiceComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Repair Invoice' }},
      { path: 'updated-invoice', component: UpdatedInvoiceComponent, canActivate:[AuthGuard], data: { breadcrumb: 'Update Invoice' }},
      { path: 'repair-order-pdf', component: RepairOrderPdfComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Repair Order Pdf' }},
      { path: 'work-in-progress', component: WorkInProgessFormComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Work in Progress' }},
      { path: 'wip-repair-orders', component:WipRepairOrdersComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Wip Repair Orders' }},
      { path: 'direct-sale', component: DirectSaleComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Direct Sale' }},
      { path: 'edit-direct-sale', component: EditDirectSaleComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Edit Direct Sale' }},
      { path: 'direct-sale-list', component: DirectSaleListComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Direct Sale List' }},
      { path: 'direct-sale-dtl-view', component: DirectSaleDetailViewComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Direct Sale DLT View' }},
      { path: 'direct-sale-invoice', component: DirectSaleInvoiceComponent, canActivate:[AuthGuard], data: { breadcrumb: 'Direct Sale Invoice' }},
      { path: 'payment-status', component: PaymentStatusComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Payment Status' }},
      { path: 'payment-status-dtl-view', component: PaymentStatusDetailViewComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Payment Status DLT View' }},
      { path: 'bulk-payments', component: BulkPaymentsComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Bulk Payments' }},
      { path: 'bulk-payments-list', component: BulkPaymentsListComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Bulk Payments List' }},
      { path: 'sale-completed', component: SaleCompletedComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Sale Complete' }},
      { path: 'all-invoices', component: CustomerSalesInvoicesReportComponent, canActivate: [AuthGuard], data: { breadcrumb: 'All Invoices' }},
      { path: 'stock-list', component: StockListComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Stock List' }},
      {path:'inventory-flow-report', component:InventoryFlowReportComponent, canActivate:[AuthGuard], data: { breadcrumb: 'Inventory Flow Report' }},

    ]
  },
  // garage-settings
  {
    path: `garage-settings`,
    component: DashLayoutComponent,
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Garage Settings' },
    children: [
      { path: ``, component: GarageSettingsComponent, canActivate: [AuthGuard], pathMatch: 'full' },
      {
        path: `garage-settings`,
        component: GarageSettingsComponent, canActivate: [AuthGuard], pathMatch: 'full'
      },
      { path: `fule-type`, component: FuleTypeComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Fule Type' } },
      { path: `brand`, component: BrandComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Brand' } },
      { path: `item-type`, component: ItemTypeComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Item Type' } },
      { path: `stock-report`, component: ItemstockreportComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Stock Report' } },
      { path: `agging-report`, component: AggingReportComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Agging Report' } },
      { path: `sales-report`, component: SalesReportsByDateComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Sales Report' } },
      { path: `stock-adjustment`, component: StockAdjustmentComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Stock Adjustment' } },
      { path: `stock-adjustment-list`, component: StockAdjustmentListComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Stock Adjustment List' } },
      { path: `manufacturer`, component: ManufacturerComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Manufacturer' } },
      { path: `model`, component: ModelComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Model' } },
      { path: `ss-category`, component: SsCategoryComponent, canActivate: [AuthGuard], data: { breadcrumb: 'SS Category' } },
      { path: `status-type`, component: StatusTypeComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Status Type' } },
      { path: `tags`, component: TagsComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Tags' } },
      { path: `tags-type`, component: TagsTypeComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Tags Type' } },
      { path: `package-type`, component: PackageTypeComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Package Type' } },
      { path: `package`, component: PackageComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Package' } },
      { path: `colors`, component: ColorsComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Colors' } },
      { path: `countries`, component: CountryComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Countries' } },
      { path: `units`, component: UnitComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Units' } },
      { path: `complains-and-suggestions`, component: ComplainsAndSuggestionsComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Complains and Suggestions' } },
      { path: 'warehouse', component: WarehouseComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Warehouse' }},
      { path: 'warehouse-stock-report', component: WarehousestockreportComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Warehouse Stock Report' }},
      { path: 'vmh-report', component: VmhReportComponent, canActivate: [AuthGuard], data: { breadcrumb: 'VMH Report' }}
    ]
  },

  // other layouts
  //   //Site routes goes here
  //   {
  //     path: '',
  //     component: SiteLayoutComponent,
  //     children: [
  //       { path: '', component: HomeComponent, pathMatch: 'full'},
  //       { path: 'about', component: AboutComponent },
  //       { path: 'test/:id', component: AboutComponent }
  //     ]
  // },

  // // App routes goes here here
  // {
  //     path: '',
  //     component: AppLayoutComponent,
  //     children: [
  //       { path: 'dashboard', component: DashboardComponent },
  //       { path: 'profile', component: ProfileComponent }
  //     ]
  // },

  // //no layout routes
  { path: 'login', component: LoginComponent },
  // { path: 'register', component: RegisterComponent },

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
