import { ItemViewModel } from '../ItemViewModel';

export class GPackageItemViewModel {
    public Id: number;
    public Fk_G_SSItemDefining_Id: ItemViewModel;
    public Amount: number;
    public TotalAmount?:number;
    public DescountPercentage: number;
    public DescountAmmount: number;
    public DiscountAmount: number;
    public Quantity: number;
    public SalePrice: number;
    public Code:string;
    public IsInventry:boolean = true;
    public VatAmount?:number;
    // public Fk_Package_Id: ItemViewModel;
    // public FK_G_ItemType_Id: ItemViewModel;
    // public FK_ClientId: ItemViewModel;
    // public IsService: boolean;
    // public IsActive: boolean;

}
