import { Injectable } from '@angular/core';
import { FuleTypeService } from '../g-settings/fule-type.service';
import { ItemTypeService } from '../g-settings/item-type.service';
import { BrandService } from '../g-settings/brand.service';
import { ManufacturerService } from '../g-settings/manufacturer.service';
import { ModelService } from '../g-settings/model.service';
import { SsCategoryService } from '../g-settings/ss-category.service';
import { StatusTypeService } from '../g-settings/status-type.service';
import { TagsTypeService } from '../g-settings/tags-type.service';
import { TagsService } from '../g-settings/tags.service';
import { AuthenticationService } from '../../auth/authentication.service';
import { LocalStorageService } from '../../common/local-storage.service';
import { CookieService } from '../../common/cookie.service';
import { CustomerService } from '../g-customer/customer.service';
import { VehicleService } from '../g-vehicle/vehicle.service';
import { AccountsService } from '../fleet-desk/accounts.service';
import { SupplierService } from '../g-supplier/supplier.service';
import { ItemService } from '../g-settings/item.service';
import { PackageService } from '../g-packages/package.service';
import { PackageTypeService } from '../g-packages/package-type.service';
import { LoggerSrvice } from '../../common/logger.service';
import { PurchaseOrderService } from '../g-purchase/purchase-order.service';
import { PurchaseRequsitionService } from '../g-purchase/purchase-requsition.service';
import { DefaultService } from '../../common/default.service';
import { NgxBootstrapModalExt } from '../../common/ngx-bsmodal';
import { UnitsService } from '../g-settings/units.service';
import { ColoursService } from '../g-settings/colours.service';
import { CountryService } from '../g-settings/country.service';
import { PurchaseService } from '../g-purchase/purchase.service';
import { ComplainAndSuggestionsService } from '../g-settings/complain-and-suggestions.service';
import { SaleService } from '../g-sale/sale.service';
import { PhoneCodeServiceService } from '../g-settings/phone-code-service.service';
import { SettingService } from '../g-settings/setting.service';
import { ReportingServiceService } from '../reporting/reporting-service.service';
import { WareHouseService } from '../g-settings/warehouse.service';
import { WarehouseItemsService } from '../g-settings/warehouse-items.service';
import { DataServiceService } from '../../common/data-service.service';
import { SalesReportServiceService } from '../reporting/sales-report-service.service';

@Injectable({
  providedIn: 'root'
})
export class UnitOfWorkService {

  constructor(
    // fleet-desk services
    public accountsService: AccountsService,

    // G customer
    public customerService: CustomerService,

    // G Supplier
    public supplierService: SupplierService,

    // G Vehicle
    public vehicleService: VehicleService,

    // package
    public packageService: PackageService,
    public packageTypeService: PackageTypeService,

    // purchase
    public purchaseService: PurchaseService,
    public purchaseOrderService: PurchaseOrderService,
    public purchaseRequisitionService: PurchaseRequsitionService,

    // Sales
    public saleService: SaleService,

    // Gsettings
    public brandService: BrandService,
    public fuleTypeService: FuleTypeService,
    public itemService: ItemService,
    public itemTypeService: ItemTypeService,
    public manufacturerService: ManufacturerService,
    public modelService: ModelService,
    public ssCategoryService: SsCategoryService,
    public statusTypeService: StatusTypeService,
    public tagsTypeService: TagsTypeService,
    public tagsService: TagsService,
    public unitService: UnitsService,
    public colourService: ColoursService,
    public countryService: CountryService,
    public complainsAndSuggestionsService: ComplainAndSuggestionsService,
    public phoneCodeService: PhoneCodeServiceService,
    public settingservice : SettingService,
    public reportingservice : ReportingServiceService,
    public salesreportservice : SalesReportServiceService,
    public warehouseService : WareHouseService,
    public wareHouseItemService: WarehouseItemsService,

    // Auth
    public authenticationService: AuthenticationService,

    // local storage and cookies
    public localService: LocalStorageService,
    public cookieService: CookieService,
    public defaultService: DefaultService,

    // logger
    public logger: LoggerSrvice,

    // bs modal service
    public modalServiceEx: NgxBootstrapModalExt,
    ////Data Service/////////////////////
    public dataService: DataServiceService
  ) { }
}
