import { Injectable } from '@angular/core';
import { IHttpService } from '../../common/IHttpService';
import { EndpointConfig } from '../../common/EndpointConfig';
import { HttpClient } from '@angular/common/http';
import { GPackagesViewModel } from 'src/app/viewmodels/g-packages/GPackagesViewModel';
import { Paginator } from 'src/app/viewmodels/paginator';

@Injectable({
  providedIn: 'root'
})
export class PackageService implements IHttpService<GPackagesViewModel> {
  private _ep: EndpointConfig;
  constructor(private _client: HttpClient) {
    this._ep = new EndpointConfig('GSSItem');
  }

  GetList() {
    return this._client.post(this._ep.getEndpointMethod('GetG_PackagesList'), new Paginator(0, 50, null, 0));
  }

  GetPKGListByModel(modelId: any) {
    return this._client.post(this._ep.getEndpointMethod('GetG_PackagesListByModel'), { Id: modelId });
  }

  GetPagedResult(start = 0, pagesize = 20, flag = '', sortIndex = 0) {
    return this._client.post(this._ep.getEndpointMethod('GetG_Packages'), new Paginator(start, pagesize, flag, sortIndex));
  }
  GetPagedBySearch(searchTerm) {
    let pager = new Paginator(0, 50, null, 0);
    pager.Search = searchTerm;
    return this._client.post(this._ep.getEndpointMethod('GetG_Packages'), pager);
  }
  GetById(id: any) {
    return this._client.post(this._ep.getEndpointMethod('GetG_PackagesById'), { Id: id });
  }
  Post(obj: GPackagesViewModel) {
    return this._client.post(this._ep.getEndpointMethod('SaveG_Packages'), obj);
  }
  checkDublication(code) {
    return this._client.post(this._ep.getEndpointMethod('CheckPackageDuplication'), { Code: code });
  }
  Put(obj: GPackagesViewModel) {
    return this._client.post(this._ep.getEndpointMethod('SaveG_Packages'), obj);
  }

  Delete(obj: GPackagesViewModel) {
    return this._client.post(this._ep.getEndpointMethod('DeleteG_Packages'), obj);
  }

}
