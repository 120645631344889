import { Component, OnInit, Input } from "@angular/core";
import { WareHouseItemViewModel } from "src/app/viewmodels/g-item/GItemDefiningViewModel";
import { BsModalRef } from "ngx-bootstrap/modal";
import { UnitOfWorkService } from "src/app/services/api/uow/unit-of-work.service";
import Swal from "sweetalert2";
import { JqHelper } from "src/app/common/jq-helper";
import { NgxSpinnerService } from "ngx-spinner";
import { ItemViewModel } from 'src/app/viewmodels/ItemViewModel';
@Component({
  selector: "app-warehouse-item",
  templateUrl: "./warehouse-item.component.html",
  styleUrls: ["./warehouse-item.component.scss"],
})
export class WarehouseItemComponent implements OnInit {
  @Input() public vm: WareHouseItemViewModel;
  @Input() public list: Array<WareHouseItemViewModel>;
  @Input() public modal_title: any;
  @Input() public bsmRef: BsModalRef;
  public itemId:number;
  public wareHouesList: Array<any> = [];
  public readOnlyFlag: boolean = false;
  public isDuplicate: boolean = false;
  constructor(
    private _uow: UnitOfWorkService,
    private _jq: JqHelper,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.itemId= this.vm.FK_Item_Id.Id;
    this.getList();
    
    console.log(this.vm.FK_Item_Id.Id);
    this.vm.MinStock= null;
    this.vm.MaxStock = null
  }
  getList() {
    this._uow.warehouseService.GetListByUserId().subscribe(
      (res: any) => {
        this.wareHouesList = res;
        console.log(res);
        // this.spinner.hide();
        // this._jq.showSpinner();
      },
      (err) => {
        console.log(err);
        // this.spinner.hide();
      }
    );
  }
  public duplicateFlag: boolean = false;
  findDuplication() {
    this.duplicateFlag = false;

    for (let i of this.list) {
      if (i.FK_WareHouse_Id.Id == this.vm.FK_WareHouse_Id.Id) {
        console.log("fid duplicate ");
        this.duplicateFlag = true;
      }
    }
    if (this.duplicateFlag) {
      this.isDuplicate = true;
    } else {
      this.isDuplicate = false;
    }
  }
  isDuplicateOrder(flag) {
    return flag;
  }
  saveOrUpdate() {
    this.spinner.show();
    console.log(this.vm);
    this._uow.wareHouseItemService
      .SaveG_WareHouseItem(this.vm)
      .toPromise()
      .then((res: any) => {
        console.log(res);
        this.spinner.hide();
        // this.vm = new WareHouseItemViewModel();
        this.getwareHouseItemList(this.itemId);
        // this.bsmRef.hide();
        Swal.fire("Created!", "Ware House Item Created/Updated!", "success");
      })
      .catch((error) => {
        this.spinner.hide();
        Swal.fire("Failure!", "Ware House Item Not Created/Updated!", "error");
        console.log(`Promise rejected with ${JSON.stringify(error)}`);
      });
  }
  getwareHouseItemList(id) {
    this._uow.wareHouseItemService
      .GetG_WareHouseItemList(id)
      .subscribe((res: any) => {
        console.log("list ware house item", res);
        this.list = res;
      });
  }
  onUpdateButtonClicked(item) {
    this.isDuplicate = false;
    let selectedWarehouse:any=[];
    this.vm = item;
    // this.itemId = this.vm.FK_Item_Id.Id;
    this.vm.FK_WareHouse_Id =new ItemViewModel(item.FK_WareHouse_Id.Id, item.FK_WareHouse_Id.Name);
    if(item.FK_WareHouse_Id != null && item.FK_WareHouse_Id != undefined){
      // let wh = new ItemViewModel();
      // wh.Id=;
      console.log(item);
      // wh.Name =item.FK_WareHouse_Id.Name;
      selectedWarehouse.push({FK_WareHouse_Id:this.vm.FK_WareHouse_Id});
       this.wareHouesList = selectedWarehouse;
       console.log(this.wareHouesList);
    }
    this.readOnlyFlag = true;
  }
}
