import { Injectable } from '@angular/core';
import { DefineAccountviewModel } from 'src/app/viewmodels/g-customer/DefineAccountviewModel';
import { IHttpService } from '../../common/IHttpService';
import { EndpointConfig } from '../../common/EndpointConfig';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../../auth/authentication.service';
import { Paginator } from 'src/app/viewmodels/paginator';
import { AppConsts } from 'src/app/common/app-consts';

@Injectable({
  providedIn: 'root'
})
export class CustomerService implements IHttpService<DefineAccountviewModel> {
  private _ep: EndpointConfig;
  constructor(private _client: HttpClient, private _authenticationService: AuthenticationService) {
    this._ep = new EndpointConfig('GCustomerVehicele');
  }

  GetList() {
    return this._client.post(this._ep.getEndpointMethod('GetG_CustomerList'), { start: 0, pagesize: 50, flag: AppConsts.CUSTOMER, sortIndex: 0 });
  }
  GetPagedResult(start = 0, pagesize = 20, flag = '', sortIndex, SortBy) {
    debugger
    let pager = new Paginator();
    pager.PageSize = pagesize;
    pager.Skip = start;
    pager.Flag = flag;
    pager.SortIndex = sortIndex;
    pager.SortBy = SortBy;
    return this._client.post(this._ep.getEndpointMethod('GetG_CustomerList'), pager);
  }
  GetPagedBySearch(searchTerm) {
    let pager = new Paginator(0, 50, AppConsts.CUSTOMER, 0);
    pager.Search = searchTerm;
    return this._client.post(this._ep.getEndpointMethod('GetG_CustomerList'), pager);
  }
  GetById(id: any) {
    return this._client.post(this._ep.getEndpointMethod('GetG_CustomerById'), { Id: id });
  }
  Post(obj: DefineAccountviewModel) {
    return this._client.post(this._ep.getEndpointMethod('SaveG_CustomerAccount'), obj);
  }
  Put(obj: DefineAccountviewModel) {
    return this._client.post(this._ep.getEndpointMethod('SaveG_CustomerAccount'), obj);
  }
  Delete(obj: DefineAccountviewModel) {
    return this._client.post(this._ep.getEndpointMethod('DeleteG_Customer'), { Id: obj.Id });
  }

  Block(obj: DefineAccountviewModel) {
    return this._client.post(this._ep.getEndpointMethod('BlockG_Customer'), { Id: obj.Id });
  }
  GetListSuplier(i) {
    // return this._client.post(this._ep.getEndpointMethod('GetG_CustomerList'), { start: 0, pagesize: 50, flag: AppConsts.SUPPLIER, sortIndex: 0 });
    return this._client.post(this._ep.getEndpointMethod('GetG_CustomerList'), new Paginator(0, 50, i, 0));
  }
}
