import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { G_PackagesTypeViewModel } from 'src/app/viewmodels/g-packages/G_PackagesTypeViewModel';
import { BsModalRef } from 'ngx-bootstrap/modal/public_api';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-package-type-form',
  templateUrl: './package-type-form.component.html',
  styleUrls: ['./package-type-form.component.scss']
})
export class PackageTypeFormComponent implements OnInit {

  @Input() public vm: G_PackagesTypeViewModel;
  @Input() public modal_title: any;
  @Input() public bsmRef: BsModalRef;
  @Output() onSubmitted = new EventEmitter<any>();
  private submitted = false;
  public IsDublicate: boolean = false;
  constructor(
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
  ) { }

  ngOnInit() {
    if (this.vm == undefined) {
      this.vm = new G_PackagesTypeViewModel();
    }
    if (this.modal_title == undefined) {
      this.modal_title = 'Package Type';
    }
  }

  saveOrUpdate() {
    this._jq.showSpinner(true);
    this._uow.packageTypeService
      .Post(this.vm)
      .toPromise().then((res: any) => {
        console.log(res);
        this.submitted = true;
        this.bsmRef.hide();
        this.onSubmitted.emit(this.submitted);
        Swal.fire(
          'Created!',
          'Package-Type Created/Updated!',
          'success'
        )
      })
      .catch((error) => {
        Swal.fire(
          'Failure!',
          'Package-Type Not Updated!',
          'error'
        )
        console.log(`Promise rejected with ${JSON.stringify(error)}`);
        this.onSubmitted.emit(this.submitted);
      });
  }

  checkDuplication(tagName){
    debugger
    this._jq.showSpinner(true);
    this._uow.packageTypeService.checkDublication(tagName).toPromise().then((res : any)=>{
      if (res === true) {
        this.IsDublicate = false;
      }else{
        this.IsDublicate = true;
      }
      this._jq.showSpinner(false);
    }).catch((error) => {
      console.log(`Promise rejected with ${JSON.stringify(error)}`);
      this.onSubmitted.emit(this.submitted);
      this._jq.showSpinner(false);
    });
  }

}

