import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoggerSrvice {

    public log(p) {
        console.log(p);
    }

    public alert(p) {
        window.alert(p);
    }
}
