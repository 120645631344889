import { Component, OnInit, TemplateRef } from '@angular/core';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { JqHelper } from 'src/app/common/jq-helper';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { G_SSCategoryViewModel } from 'src/app/viewmodels/g-settings/G_SSCategoryViewModel';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-ss-category',
  templateUrl: './ss-category.component.html',
  styleUrls: ['./ss-category.component.scss']
})
export class SsCategoryComponent implements OnInit {
  modalRef: BsModalRef;
  modalRefLv2: BsModalRef;
  public itemVm: G_SSCategoryViewModel = new G_SSCategoryViewModel();
  public vmListItems: any = [];
  public component_Title = 'Spare parts & Service Categories';

  constructor(
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.spinner.show();
    this.getList();
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 500);
  }

  openModal(template: TemplateRef<any>, lvl = 1) {
    if (lvl == 1) {
      this.modalRef = this._modalService.show(template);
    }
    if (lvl == 2) {
      this.modalRefLv2 = this._modalService.show(template);
    }
  }

  ngAfterViewInit() {
    // this._jq.showSpinner();
  }

  getList() {
    this._jq.showSpinner(true);
    this._uow.ssCategoryService.GetList().subscribe((res: any) => {
      this._jq.reinitDataTable(res);
      this.vmListItems = res;
      console.log(res);

      this._jq.showSpinner();
    }, (err) => {
      console.log(err);
    });
  }

  openSaveOrUpdateModel(template: TemplateRef<any>, model?) {
    this.openModal(template, 1);
    if (model !== undefined) {
      this.itemVm = model;
    } else {
      this.itemVm = new G_SSCategoryViewModel();
    }
  }

  saveOrUpdate() {
    this._jq.showSpinner(true);
    this._uow.ssCategoryService.Post(this.itemVm).subscribe((res: any) => {
      this.getList();
      this.modalRef.hide();
    }, (err) => {
      this._jq.showSpinner();
    });
  }

  openDeleteModel(template: TemplateRef<any>, model) {
    this.openModal(template);
    if (model !== undefined) {
      this.itemVm = model;
    }
  }

  conformDelete() {
    this._uow.ssCategoryService.Delete(this.itemVm).subscribe((res: any) => {
      this.getList();
      this.modalRef.hide();
    }, (err) => {
      this._jq.showSpinner();
    });
  }

  // external components

  onItemSubmitted(e) {
    if (e == true) {
      this.getList();
    }
  }

}
