import { Component, OnInit, TemplateRef } from '@angular/core';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { G_TagsTypeViewModel } from 'src/app/viewmodels/g-settings/G_TagsTypeViewModel';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-tags-type',
  templateUrl: './tags-type.component.html',
  styleUrls: ['./tags-type.component.scss']
})
export class TagsTypeComponent implements OnInit {

  modalRef: BsModalRef;
  public itemVm: G_TagsTypeViewModel = new G_TagsTypeViewModel();
  public vmListItems: any = [];
  public component_Title = "Tag Type";

  constructor(
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.spinner.show();
    this.getList();
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 1000);
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  ngAfterViewInit() {
    // this._jq.showSpinner();
  }

  getList() {
    // this._jq.showSpinner(true);
    this._uow.tagsTypeService.GetList().subscribe((res: any) => {
      this._jq.reinitDataTable(res);
      this.vmListItems = res;
      console.log(res);

      this._jq.showSpinner();
    }, (err) => {
      console.log(err);
    });
  }

  openSaveOrUpdateModel(template: TemplateRef<any>, model?) {
    this.openModal(template);
    if (model !== undefined) {
      this.itemVm = model;
    } else {
      this.itemVm = new G_TagsTypeViewModel();
    }
  }

  // saveOrUpdate() {
  //   this._jq.showSpinner(true);
  //   this._uow.tagsTypeService.Post(this.itemVm).subscribe((res: any) => {
  //     this.getList();
  //     this.modalRef.hide();
  //   }, (err) => {
  //     this._jq.showSpinner();
  //   });
  // }

  // extrnal components
  onItemSubmitted(e) {
    console.log(e);
    if (e === true) {
      this.itemVm = new G_TagsTypeViewModel();
      this.getList();
    }
  }
  openDeleteModel(template: TemplateRef<any>, model?) {
    this.openModal(template);
    if (model !== undefined) {
      this.itemVm = model;
    }
  }

  conformDelete() {
    this._uow.tagsTypeService.Delete(this.itemVm).subscribe((res: any) => {
      this.getList();
      this.modalRef.hide();
    }, (err) => {
      this._jq.showSpinner();
    });
  }

}
