import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-stock-list',
  templateUrl: './stock-list.component.html',
  styleUrls: ['./stock-list.component.scss']
})
export class StockListComponent implements OnInit {
  public SortBy:string = "asc";
  public modalRef: BsModalRef;
  p: number = 1;
  dataTable: any;
  @Input('dataTable') table;
  dtOption: any = {};
  public component_Title = 'Inventory Stock';
  TransferInventoryList:Array<any>=[];
  constructor(
    private route: ActivatedRoute,
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    private http: HttpClient,
    public _router: Router,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.GetG_TransferInventoryList();
  }
  GetG_TransferInventoryList(){
    this.spinner.show();
    this._uow.itemService.GetG_TransferInventoryList().subscribe((res:any) =>{
      console.log('res',res);
      this.TransferInventoryList = res;
      this.spinner.hide();
    },error=>{
      console.log(error);
      this.spinner.hide();
    });
  }
  openSaveOrUpdateModel(template: TemplateRef<any>, model?) {
    this.openModal(template);
    // if (model !== undefined) {
    //   this.vm = model;
    //   this.vm.TransactionDate = this._uow.defaultService.CutTimeZone(this.vm.TransactionDate);
    // } else {
    //   this.vm = undefined;
    // }
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this._uow.modalServiceEx.show(template);
    this.modalRef.setClass('modal-xl');
  }
}
