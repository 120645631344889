import { Component, OnInit, Input, TemplateRef, Output, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { G_PurchaseMasterViewModel } from 'src/app/viewmodels/g-purchase/G_PurchaseMasterViewModel';
import { ActivatedRoute, Router } from '@angular/router';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { HttpClient } from '@angular/common/http';
import { DefineAccountviewModel } from 'src/app/viewmodels/g-customer/DefineAccountviewModel';
import { ItemViewModel } from 'src/app/viewmodels/ItemViewModel';
import Swal from 'sweetalert2';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { G_Installment_View_Model } from 'src/app/viewmodels/g-purchase/G_Installment_View_Model';
import { G_SaleMasterViewModel } from 'src/app/viewmodels/g-sale/G_SaleMasterViewModel';
import { G_Bulk_Installment_View_Model } from 'src/app/viewmodels/g-purchase/G_Bulk_Installment_View_Model';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-customer-sales-invoices-report',
  templateUrl: './customer-sales-invoices-report.component.html',
  styleUrls: ['./customer-sales-invoices-report.component.scss']
})
export class CustomerSalesInvoicesReportComponent implements OnInit {
  @Input() public bsmRef: BsModalRef;
  @Output() onSubmitted = new EventEmitter<any>();
  @Input() public modal_title: any;
  public SortBy:string = "asc";
  public modalRef: BsModalRef;
  public vmS: G_SaleMasterViewModel = new G_SaleMasterViewModel();
  public vmListItems: any = [];
  public vmListItem: any = [];
  public vmD: DefineAccountviewModel = new DefineAccountviewModel();
  public vmI : G_Installment_View_Model =  new G_Installment_View_Model();
  public IsDisable:boolean = false;
  p: number = 1;
  dataTable: any;
  public customerDetail: any = [];
  @Input('dataTable') table;
  dtOption: any = {};
  public component_Title = 'Bulk Payments';
  public isEdit= false;
  public isDisabled = false;
  public refid: number;
  public total: number;
  public paidamount: number;
  public balance: number;
  public calingUrl: string;
  public urlId: number;
  public excelUrl: string;
  public isPreview: boolean = false;
  private submitted = false;
  public suppliers: any = [];
  public selectedCustomer: any = [];
  public accountSelected : any = [];
  public accountList : ItemViewModel[] = [];

  public accountDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'Id',
    textField: 'Name',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 4,
    allowSearchFilter: true,
    limitSelection: 1
  };

  public CustomerDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'Id',
    textField: 'Acc_Titel',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 4,
    allowSearchFilter: true,
    limitSelection: 1
  };
  public fromDate: Date;
  public toDate: Date;

  constructor(
    private route: ActivatedRoute,
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    private http: HttpClient,
    public _router: Router,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.getCustomers();
    if (this.vmS == undefined) {
      this.vmS = new G_SaleMasterViewModel();
      this.vmS.TransactionDate = new Date();
      this.vmS.PaymentType = 1;
      this.vmS.SaleDetails = [];
      this.vmS.FK_Customer_Acc = new ItemViewModel();
      this.vmS.FK_Customer_Acc.Id = 0;
      this.vmS.FK_Customer_Acc.Name ="";
      this.vmS.PaidAmount = 0;
      this.vmS.Inv_Amount = 0;
    }

  }

  getCustomerInvoice(){
    debugger
    this.spinner.show();
    var td = null;
    var fd = null;
    if (this.toDate != null) {
      td = this.toDate.toDateString();
    }
    if (this.fromDate != null) {
      fd = this.fromDate.toDateString();
    }
    let selectedC = this.customerData;
      this._uow.saleService.GetAllSaleInvoices(selectedC.Id,td,fd).subscribe((res: any) =>{
        this.vmI = res;
        console.log(res);
        this.spinner.hide();
      }, (error) => {
        console.log(error);
      });
  }

getCustomerName() {
  this._jq.showSpinner(true);
  debugger
  this._uow.customerService.GetList().subscribe((res: any) => {

    this.customerDetail = res.DtlList;
    // this.itemVm.RegistraionNumber = res;
    console.log(res);
    this._jq.showSpinner();
    // this.itemVm.RegistraionNumber = res.code;
  }, (error) => {
    this._uow.logger.log(error);
    this._jq.showSpinner();
  });
}

getCustomers() {
  debugger
  let Customer = "Customer";
  this._uow.customerService.GetListSuplier(Customer).subscribe((res: any) => {
    // console.log(res);
    this.customerDetail = res.DtlList;
    console.log(this.customerDetail);
  }, (error) => {
    this._uow.logger.log(error);
    this.customerDetail = [];
  });
}

createExcel(){
  this.spinner.show();
      this.excelUrl = "";
      this._uow.saleService.CreateAllSaleInvoicesExcel(this.vmI).subscribe((res: any) =>{
        let ab = JSON.parse(res);
        this.excelUrl = ab.Name;
        window.open(this.excelUrl,"_blank");
        console.log(this.excelUrl);
        this.spinner.hide();
      }, (error) => {
        console.log(error);
        this.spinner.hide();
      });
}

  openModal(template: TemplateRef<any>) {
    this.modalRef = this._uow.modalServiceEx.show(template);
  }

  openModalLg(template: TemplateRef<any>) {
    this.modalRef = this._uow.modalServiceEx.show(template);
    this.modalRef.setClass('modal-xl');
  }

 public customerData:ItemViewModel;
 onSelectCustomer(e) {
  debugger
   let item = this.customerDetail.find(x => x.Id === e.Id);
   //  let i = new G_Installment_View_Model();
   //  i.G_Fk_DabitAccount_Id = new ItemViewModel();
   //  i.G_Fk_DabitAccount_Id.Id = item.Id;
   //  i.G_Fk_DabitAccount_Id.Name = item.Name;
   this.customerData = new ItemViewModel();
   this.customerData.Id = item.Id;
   this.customerData.Name = item.Acc_Titel;
}


  openSaveOrUpdateModel(template: TemplateRef<any>, model?) {
    this.openModal(template);

  }

  // extrnal components
  onItemSubmitted(e) {
    console.log(e);
    if (e === true) {
      this.vmI = new G_Installment_View_Model();
    }
  }

}
