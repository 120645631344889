import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from "@angular/core";
import { GItemDefiningViewModel } from "src/app/viewmodels/g-item/GItemDefiningViewModel";
import { JqHelper } from "src/app/common/jq-helper";
import { UnitOfWorkService } from "src/app/services/api/uow/unit-of-work.service";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { ItemViewModel } from "src/app/viewmodels/ItemViewModel";
import { GPurchaseRequisitionViewModel } from "src/app/viewmodels/g-purchase/GPurchaseRequisitionViewModel";
import { G_PurchaseMasterViewModel } from "src/app/viewmodels/g-purchase/G_PurchaseMasterViewModel";
import { DefineAccountviewModel } from "src/app/viewmodels/g-customer/DefineAccountviewModel";
import { PurchaseDetailViewModel } from "src/app/viewmodels/g-purchase/PurchaseDetailViewModel";
import Swal from "sweetalert2";
import { NgxSpinnerService } from "ngx-spinner";
import { G_WareHouseViewModel } from 'src/app/viewmodels/g-settings/G_Warehouse_Viewmodel';

@Component({
  selector: "app-purchase-order-form",
  templateUrl: "./purchase-order-form.component.html",
  styleUrls: ["./purchase-order-form.component.scss"],
})
export class PurchaseOrderFormComponent implements OnInit {
  constructor(
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    private spinner: NgxSpinnerService
  ) {}

  @Input() public vm: G_PurchaseMasterViewModel;
  @Input() public modal_title: any;
  @Input() public bsmRef: BsModalRef;
  @Output() onSubmitted = new EventEmitter<any>();
  public vmD: DefineAccountviewModel = new DefineAccountviewModel();
  public component_Title = "Purchase Order";
  modalRef: BsModalRef;
  private submitted = false;
  public isPreview: boolean = false;
  public thumbnail: any;
  public ComName: any;
  public Address: any;
  public contect: any;

  public defaultDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: "Id",
    textField: "RefId",
    enableCheckAll: false,
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };
  public warehouseDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: "Id",
    textField: "Name",
    enableCheckAll: false,
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };

  public accountDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: "Id",
    textField: "Acc_Titel",
    enableCheckAll: false,
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 4,
    allowSearchFilter: true,
  };
  public itemsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: "Id",
    textField: "Name",
    enableCheckAll: false,
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };

  public sparePartVm: GItemDefiningViewModel = new GItemDefiningViewModel();
  public citemtype = "itemtype";
  public itemTypes: any = [];

  public suppliers: any = [];
  public csuppliers = "suppliers";
  public selectedSuppliers: any = [];
  
  public spareParts: any = [];
  public selectedSpareParts: any = [];
  public selectspp: any;
  public purchaseRequisitions: any = [];
  public warehouseList: any = [];
  public warehouseLists:Array<G_WareHouseViewModel>=[];
  public cpurchaseRequisitions = "purchaseRequisitions";
  public selectedPurchaseRequisitions: any = [];
  public selectedWarehouse: any = [];
  name = "CP_Email";
  id = "Id";
  checked = true;
  disabled = false;
  label = "Toggle On/Off";
  labelledby = "Some Other Text";

  public TotalPayable = 0;
  public TotalVat = 0;
  public TotalPayableAfterVat = 0;

  public itemType = new ItemViewModel();
  ngOnInit() {
    this.getimage();
    if (this.vm == undefined) {
      this.vm = new G_PurchaseMasterViewModel();
      this.vm.TransactionDate = new Date();
      this.vm.PurchaseDetail = [];
      this.selectedSpareParts = [];
      this.selectedPurchaseRequisitions = [];
      // this.selectedWarehouse = [];
      this.vm.FK_Sup_Cradit_Acc_Email = new ItemViewModel(0, "");
      this.getRefid();
    } else {
      let selectedSuplier: any = [];
      let selectedSaprePartsList: any = [];
      if (
        this.vm.FK_Sup_Cradit_Acc != undefined &&
        this.vm.FK_Sup_Cradit_Acc != null
      ) {
        let sCA = new DefineAccountviewModel();
        sCA.Id = this.vm.FK_Sup_Cradit_Acc.Id;
        sCA.Acc_Titel = this.vm.FK_Sup_Cradit_Acc.Name;
        this.vmD.Acc_Titel = this.vm.FK_Dabit_Acc.Name;
        selectedSuplier.push(sCA);
        this.selectedSuppliers = selectedSuplier;
        this.vm.FK_Sup_Cradit_Acc = new ItemViewModel(
          this.vm.FK_Sup_Cradit_Acc.Id,
          this.vm.FK_Sup_Cradit_Acc.Name
        );
      }
      this.vm.PurchaseDetail.forEach((x) => {
        let partssSelected = new GItemDefiningViewModel();
        partssSelected.Id = x.FK_G_Item_Id.Id;
        partssSelected.Name = x.FK_G_Item_Id.Name;
        selectedSaprePartsList.push(partssSelected);
      });
      this.selectedSpareParts = selectedSaprePartsList;
      this.updateTotals();
    }
    if (this.modal_title == undefined) {
      this.modal_title = "Purchase Order";
    }
    this.sparePartVm = new GItemDefiningViewModel();
    this.sparePartVm.FK_G_ItemType_Id = new ItemViewModel(0, " ");

    this.getItemTypes();
    this.getSuppliers();
    this.getRequisitions();
    this.getSpareParts();
    this.getWarehouses();
  }

  onChange(e) {
    debugger;
    let item = this.selectspp;
    if (e == true) {
      if (this.selectedSuppliers.length > 0 && this.suppliers != undefined) {
        let findSelected = this.suppliers.find(
          (x) => x.Id == this.selectedSuppliers[0].Id
        );
        let i = new G_PurchaseMasterViewModel();
        this.vm.FK_Sup_Cradit_Acc_Email = new ItemViewModel(
          findSelected.Id,
          findSelected.CP_Email
        );
      }
      this.vm.IsEmailRequired = true;
    } else {
      this.vm.FK_Sup_Cradit_Acc_Email = null;
      this.vm.IsEmailRequired = false;
    }
  }

  getRefid() {
    this._uow.purchaseOrderService.ValidatePurchsaeRefId().subscribe(
      (res: any) => {
        console.log(res);
        this.vm.RefId = res.code;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  // getwareHouse(){
  //   this._uow.warehouseService.GetListByUserId().subscribe((res:any)=>{
  //     console.log
  //   })
  // }

  getSpareParts() {
    //'Spare-Parts'
    this._uow.itemService.GetSSItemDefiningByItemType("Spare-Parts").subscribe(
      (res: any) => {
        this.spareParts = res;
        console.log(res);
      },
      (err) => {
        this._uow.logger.log(err);
        this.spareParts = [];
      }
    );
  }

  getItemTypes() {
    this._uow.itemTypeService.GetList().subscribe(
      (res: any) => {
        this.itemTypes = res;
      },
      (err) => this._uow.logger.log(err)
    );
  }

  getSuppliers() {
    this._uow.supplierService.GetList().subscribe(
      (res: any) => {
        this.suppliers = res.DtlList;
        this._uow.logger.log(this.suppliers);
      },
      (err) => this._uow.logger.log(err)
    );
  }

  getRequisitions() {
    this._uow.purchaseRequisitionService.GetList().subscribe(
      (res: any) => {
        console.log(res.DtlList);
        this.purchaseRequisitions = res.DtlList;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getWarehouses() {
    this._uow.warehouseService.GetListByUserId().subscribe(
      (res:any) => {
        this.selectedWarehouse = res;
        this.warehouseList = res;
        // res.map(element =>{
        //   this.selectedWarehouse.push({Id:element.FK_WareHouse_Id.Id, Name:element.FK_WareHouse_Id.Name})
        // })
        // // this.warehouseList = res;
        console.log(this.selectedWarehouse);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  // spare parts
  onSelectSparePart(e) {
    console.log(e);
    if (
      this.vm.PurchaseDetail == undefined ||
      this.vm.PurchaseDetail.length == 0
    ) {
      this.vm.PurchaseDetail = [];
    }
    let item = this.spareParts.find((x) => x.Id === e.Id);
    console.log(item);
    if (item !== undefined) {
      let i = new PurchaseDetailViewModel();
      i.Quantity = 1;
      i.FK_G_Item_Id = new ItemViewModel(item.Id, item.Name);
      i.MaxQuantity = item.MaxStock;
      i.AvalibelQuantity = item.CurrentStock;
      i.Quantity = item.MaxStock - item.CurrentStock;
      if (item.CurrentStock > item.MaxStock) {
        i.Quantity = 0;
      }
      i.Item_Code = item.Code;
      i.Item_Name = item.Name;
      i.PurchasePrice = item.PurchasePrice;
      i.SalePrice = item.SalePrice;
      i.Item_Type = item.FK_G_ItemType_Id.Name;

      this.quantityOrPriceChanged(i);
      this.vm.PurchaseDetail.push(i);
      this.updateTotals();
    }
  }

  onDeSelectSparePart(e) {
    let i = this.vm.PurchaseDetail.find((x) => x.FK_G_Item_Id.Id === e.Id);
    if (i !== undefined) {
      this.vm.PurchaseDetail.splice(this.vm.PurchaseDetail.indexOf(i), 1);
    }
  }

  onSelectSupplier(e) {
    let item = this.suppliers.find((x) => x.Id === e.Id);
    console.log(item);
    if (item !== undefined) {
      let i = new G_PurchaseMasterViewModel();
      this.vm.FK_Sup_Cradit_Acc = new ItemViewModel(item.Id, item.Acc_Titel);
      this.selectspp = item;
      //this.vm.FK_Sup_Cradit_Acc.Id = item.Id;
      //this.vm.FK_Sup_Cradit_Acc.Name = item.Acc_Titel;
    }
  }

  // input changes
  quantityOrPriceChanged(m) {
    m.TotalAmount = m.Quantity * m.PurchasePrice;
    this.updateTotals();
  }
  updateTotals() {
    this.TotalPayableAfterVat = 0;
    this.TotalVat = 0;
    this.TotalPayable = 0;
    if (
      this.vm.PurchaseDetail !== undefined &&
      this.vm.PurchaseDetail.length > 0
    ) {
      this.vm.PurchaseDetail.forEach((x) => {
        this.TotalPayable += x.TotalAmount;
        this.TotalPayableAfterVat += x.TotalAmount;
        this.vm.Inv_Amount = this.TotalPayableAfterVat;
      });
    }
  }

  // purchase requisition change
  onSelectPurchaseRequisition(e) {
    let selectedSaprePartsList: any = [];
    let selectedWareHouseList: any = [];
    let selectedSuplier: any = [];
    let selectemail: any;
    let f = this.purchaseRequisitions.find((x) => x.Id == e.Id);
    if (f !== undefined) {
      console.log("purchase", f);
      let s = f;
      //this.vm = new G_PurchaseMasterViewModel();
      this.vm = s;
      this.vm.FK_WareHouse_Id = new ItemViewModel(s.Fk_WareHouse_Id.Id, s.Fk_WareHouse_Id.Name) ;
      this.vm.IsLinked = true;
      this.vm.Inv_Amount = this.TotalPayableAfterVat;
      this.vm.TransactionDate = this._uow.defaultService.CutTimeZone(
        f.TransactionDate
      );


      if (s.Fk_WareHouse_Id != null && s.Fk_WareHouse_Id != undefined) {
        let sWH = new G_WareHouseViewModel();
        this.vm.FK_WareHouse_Id = new ItemViewModel(s.Fk_WareHouse_Id.Id ,s.Fk_WareHouse_Id.Name );
        console.log(sWH);
        selectedWareHouseList.push({FK_WareHouse_Id:this.vm.FK_WareHouse_Id});
        
        this.selectedWarehouse = selectedWareHouseList;
        console.log(this.selectedWarehouse);
      }

      if (f.FK_Sup_Cradit_Acc != undefined && f.FK_Sup_Cradit_Acc != null) {
        let sCA = new DefineAccountviewModel();
        sCA.Id = f.FK_Sup_Cradit_Acc.Id;
        sCA.Acc_Titel = f.FK_Sup_Cradit_Acc.Name;
        selectedSuplier.push(sCA);
        this.selectedSuppliers = selectedSuplier;
        this.vm.FK_Sup_Cradit_Acc = new ItemViewModel(
          f.FK_Sup_Cradit_Acc.Id,
          f.FK_Sup_Cradit_Acc.Name
        );
      }
     
      if (
        f.FK_Sup_Cradit_Acc_Email != undefined &&
        f.FK_Sup_Cradit_Acc_Email != null
      ) {
        let sCA = new DefineAccountviewModel();
        sCA.Id = f.FK_Sup_Cradit_Acc_Email.Id;
        sCA.CP_Email = f.FK_Sup_Cradit_Acc_Email.Name;
        selectemail.push(sCA);
        this.selectspp = selectemail;
        this.vm.FK_Sup_Cradit_Acc_Email = new ItemViewModel(
          f.FK_Sup_Cradit_Acc_Email.Id,
          f.FK_Sup_Cradit_Acc_Email.Name
        );
      }
      if (
        this.vm.PurchaseDetail !== undefined &&
        this.vm.PurchaseDetail.length > 0
      ) {
        this.vm.PurchaseDetail.forEach((x) => {
          x.TotalAmount = x.Quantity * x.PurchasePrice;
          let partssSelected = new GItemDefiningViewModel();
          partssSelected.Id = x.FK_G_Item_Id.Id;
          partssSelected.Name = x.FK_G_Item_Id.Name;
          selectedSaprePartsList.push(partssSelected);
        });
        this.selectedSpareParts = selectedSaprePartsList;
        this.updateTotals();
      }
    }
  }

  onDeSelectPurchaseRequisition(e) {
    this.selectedSpareParts = [];
    this.selectedSuppliers = [];
    this.selectedWarehouse =this.warehouseList;
    // let f = this.purchaseRequisitions.find(x => x.Id == e.Id);
    // if (f !== undefined) {
    //   this.vm = new G_PurchaseMasterViewModel();
    // }
    if (this.selectedPurchaseRequisitions.length == 0) {
      this.vm = new G_PurchaseMasterViewModel();
      this.vm.TransactionDate = new Date();
      this.getRefid();
      this.updateTotals();
    }
  }

  onRemoveFromDetailList(e) {
    this.selectedSpareParts = [];
    console.log(e);
    let i = this.vm.PurchaseDetail.find(
      (x) => x.FK_G_Item_Id.Id === e.FK_G_Item_Id.Id
    );
    if (i !== undefined) {
      this.vm.PurchaseDetail.splice(this.vm.PurchaseDetail.indexOf(i), 1);
      this.vm.PurchaseDetail.forEach((x) => {
        this.selectedSpareParts.push(
          new ItemViewModel(x.FK_G_Item_Id.Id, x.FK_G_Item_Id.Name)
        );
      });
      if (this.vm.PurchaseDetail.length == 0) {
        this.selectedPurchaseRequisitions = [];
        this.getRefid();
      }
    }

    this.getRequisitions();
  }

  getimage() {
    this._uow.settingservice.GetByImage().subscribe(
      (baseImage: any) => {
        console.log(baseImage);
        if (baseImage != undefined && baseImage !== null) {
          if (
            baseImage.Attachment != undefined &&
            baseImage.Attachment !== null
          ) {
            this.thumbnail =
              "https://garagedesks.s3.eu-central-1.amazonaws.com/" +
              baseImage.Attachment;
            this.ComName = baseImage.ComName;
            this.Address = baseImage.Address;
            this.contect = baseImage.contect;
          } else {
            this.thumbnail = baseImage.ComName;
            this.ComName = baseImage.ComName;
            this.Address = baseImage.Address;
            this.contect = baseImage.contect;
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // exteral coponents
  openModal(template: TemplateRef<any>, lvl: number = 1) {
    if (lvl == 1) {
      this.modalRef = this._modalService.show(template);
    }
    // if (lvl == 2) {
    //   this.modalRefLv2 = this._modalService.show(template);
    // }
  }
  compareFn(optionOne, optionTwo): boolean {
    if (optionOne !== undefined && optionTwo !== undefined) {
      return optionOne.Id === optionTwo.Id;
    }
  }
  openSaveOrUpdateModel(template: TemplateRef<any>, model?) {
    if (model !== undefined) {
      this.sparePartVm = model;
    } else {
      this.sparePartVm = undefined;
    }
    this.openModal(template, 1);
    this.modalRef.setClass("modal-xl");
  }
  openSaveOrUpdateModelForItemType(
    template: TemplateRef<any>,
    model?,
    itemType?
  ) {
    this.itemType = this.itemTypes[itemType];
    this.sparePartVm.FK_G_ItemType_Id = new ItemViewModel(
      this.itemType.Id,
      this.itemType.Name
    );
    // this.sparePartVm.FK_G_ItemType_Id = new ItemViewModel(this.itemType.Id, this.itemType.Name);
    console.log(this.itemTypes);
    this.openSaveOrUpdateModel(template, model);
  }

  saveOrUpdate() {
    debugger;
    // this._jq.showSpinner(true);
    this.spinner.show();
    this.onChange(true);
    this._uow.purchaseOrderService
      .Post(this.vm)
      .toPromise()
      .then((res: any) => {
        console.log(res);
        this.submitted = true;
        this.vm.IsEmailRequired = true;
        //this.vm = res;
        this.bsmRef.hide();
        this.modalRef.hide();
        this.onSubmitted.emit(this.submitted);
        this.spinner.hide();
        Swal.fire("Created!", "Purchase Order Created/Updated!", "success");
        // this._jq.showSpinner();
      })
      .catch((error) => {
        Swal.fire("Failure!", "Purchase Order Not Updated!", "error");
        console.log(`Promise rejected with ${JSON.stringify(error)}`);
        this.onSubmitted.emit(this.submitted);
        // this._jq.showSpinner();
        this.spinner.hide();
      });
  }

  navigateClick() {
    this.isPreview = false;
    this.modalRef.hide();
  }

  // external components
  onItemSubmitted(e, mf) {
    this._uow.logger.log(e);
    if (e === true) {
    }
  }

  openPreview(content) {
    this.modalRef = this._uow.modalServiceEx.show(content);
    this.modalRef.setClass("modal-lg");
    this.isPreview = true;
  }
}
