import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataServiceService {
public saveTrigger = new BehaviorSubject<boolean>(false);
public saveTriggerObservable =this.saveTrigger.asObservable();
  constructor() { }
  public setValue(value){
    this.saveTrigger.next(value);

  }
}
