import { ItemViewModel } from '../ItemViewModel';
import { GItemSupplierViewModel } from './GItemSupplierViewModel';
import { GItemModelViewModel } from './GItemModelViewModel';
import { GItemQRViewModel } from './GItemQRViewModel';

export class GItemDefiningViewModel {
    public Id: number;
    public Code: string;
    public Name: string;
    public Description: string;
    public RegistrationNo: string;
    public SalePrice: number;
    public PurchasePrice: number;
    public Fk_G_SSCategory_Id: ItemViewModel;
    public FK_G_ItemType_Id: ItemViewModel;
    public IsGeneric: boolean;
    public FK_G_Manufacture_Id: ItemViewModel;
    public FK_Unit_Id: ItemViewModel;
    public CurrentStock: number;
    public MinStock: number;
    public MaxStock: number;
    public FK_Colour_Id: ItemViewModel;
   
    public GItemSupplier: GItemSupplierViewModel[];
    public GItemModels: GItemModelViewModel[];
    public GItemQRCode: GItemQRViewModel[];
    public RackId: number;
    public file? :any;
}
export class WareHouseItemViewModel{
    public Id: number;
    public FK_Item_Id: ItemViewModel;
    public FK_WareHouse_Id: ItemViewModel;
    public SalePrice: number;
    public PurchasePrice: number;
    public CostofGoodSold: number;
    public CurrentStock: number;
    public AssignStock: number;
    public MinStock: number;
    public MaxStock: number;
    public RackId:number;
    public FK_ClientId: ItemViewModel;
    public IsLock: boolean;
    public file?:any;
}

export class GItemDefinintionAttachments{
    public Id?: number;
    public FK_ClientId?: number;
    public FK_GItemDefining_Id?: number;
    public Type?: number;
    public ItemFiles?: any = [];
    public CategoryFile?: any = [];
}